import React, { useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const LulcDrawer = (props) => {
  const [pieChartOptions, setPieChartOptions] = useState({
    chart: {
      plotBackgroundColor: "#212121",
      backgroundColor: "#212121",
      plotBorderWidth: 0,
      plotShadow: false,
      type: "pie",
      height: 180,
      width: 260,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y}%</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
          format: "<b>{point.name}</b>: {point.y}%",
          style: {
            color: "white",
          },
          distance: -30,
        },
      },
    },
    legend: {
      enabled: true,
      itemStyle: {
        color: "white",
      },
    },
    series: [
      {
        name: "Area Distribution",
        colorByPoint: true,
        borderWidth: null,
        data: [], // Initially empty, to be populated based on props
      },
    ],
  });

  // Use useEffect to update the pie chart data whenever lulcAreaList changes
  useEffect(() => {
    if (props?.lulcAreaList?.length) {
      // Calculate total area
      const totalArea = props.lulcAreaList.reduce(
        (sum, item) => sum + item.area,
        0
      );

      const updatedData = props.lulcAreaList.map((item) => ({
        name: item.name,
        y: Math.floor((item.area / totalArea) * 100), // Assuming area is percentage
        color: getColorForLandUse(item.name), // Use a function to assign a color if needed
      }));

      setPieChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            ...prevOptions.series[0],
            data: updatedData,
          },
        ],
      }));
    }
  }, [props.lulcAreaList]);

  // Helper function to assign colors based on land use type
  const getColorForLandUse = (landUse) => {
    switch (landUse) {
      case "Crop Land Area":
        return "#8FCE00";
      case "Waste Land Area":
        return "#FFF2CC";
      case "Forest Area":
        return "#B7E1CD";
      case "Plantation Area":
        return "#38761D";
      case "Built up Area":
        return "#F44336";
      case "Water Bodies Area":
        return "#006ACC";
      case "Other area":
        return "#E5C9D7";
      default:
        return "#999999"; // Default color
    }
  };

  return (
    <>
      <div style={{ marginTop: "2rem", color: "white", width: "auto" }}>
        <h3
          style={{ color: "white", textAlign: "center", marginBottom: "1rem" }}
        >
          Land Use and Area Distribution
        </h3>
        <div
          style={{
            overflow: "auto",
            resize: "both",
            maxWidth: "300px",
            maxHeight: "300px",
            border: "1px solid #555",
            padding: "10px",
          }}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "#424242", color: "white" }}>
                <th
                  style={{
                    padding: "5px",
                    border: "1px solid #555",
                    fontSize: "12px",
                  }}
                >
                  Land Use Type
                </th>
                <th
                  style={{
                    padding: "5px",
                    border: "1px solid #555",
                    fontSize: "12px",
                  }}
                >
                  Area (Ha)
                </th>
              </tr>
            </thead>
            <tbody>
              {props?.lulcAreaList?.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#333" : "#212121",
                    color: "white",
                  }}
                >
                  <td
                    style={{
                      padding: "5px",
                      border: "1px solid #555",
                      fontSize: "12px",
                    }}
                  >
                    {item?.name}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      border: "1px solid #555",
                      fontSize: "12px",
                    }}
                  >
                    {item?.area?.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
      </div>
    </>
  );
};

export default LulcDrawer;
