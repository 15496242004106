import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { Button, Pagination, Select, Tooltip } from "antd";
import { apiurls } from "../../resources/APIurls";
import { appconfig } from "../../resources/config";
import axios from "axios";
import { ToolTipContent } from "../../resources/tooltip";
import { SoilDropDownData } from "../../resources/SoilDrawerContent/SoilDropDownData";
const { Option } = Select;

const LocationHeader = ({
  locationFiltersList,
  setLocationFiltersList,
  locationFilters,
  setLocationFilters,
  featureType,
  setFeatureType,
  farmName,
  setCurrentPage,
  farmMobileNum,
  searchDisable,
  setFetchingFarms,
  farmsPaginataion,
  setFarmsPaginataion,
  allFarms,
  setAllFarms,
  setFarmCount,
  setFarmsCount,
  setMapLoader,
  selectedSeason,
  setSelectedSeason,
  selectedSoilNutrient,
  setSelectedSoilNutrient,
  seasonsList,
  dropdownLoader,
  setDropdownLoader,
  handleSearch,
  resetFilters,
  getCropHistoryYieldAreaData,
  clearSoilDataLegend
}) => {
  const getAllFarms = async (page, farmName, mobileNum) => {
    setMapLoader(true);
    let apikey = apiurls.find((item) => item.apikey === "geotaggedfarms");
    setFetchingFarms(true);
    const res = await axios({
      method: "GET",
      url: `${appconfig.domain[appconfig.environment].geotaggedfarms}${
        apikey.paths.geotaggedfarms
      }`,
      headers: {
        "Client-Name": "IOS_REACT_NATIVE_APP",
        "Client-Token": localStorage.getItem("token"),
        "X-localization": "en",
      },
      params: {
        customer_type: "web",
        offset: page,
        limit: 20,
        search: farmName,
        mobile: mobileNum,
        sort: "created_at",
        sort_order: "DESC",
      },
    });

    if (farmName === "" && mobileNum === "") {
      setAllFarms(res.data.data);
      setFarmsPaginataion(Math.ceil(res.data.recordsTotal / 20));
    } else if (farmName !== "") {
      setAllFarms(res.data.data);
      setFarmsPaginataion(Math.ceil(res.data.recordsTotal / 20));
    } else {
      setAllFarms(res.data.data);
    }

    setFarmCount(res.data);
    setFetchingFarms(false);
    setMapLoader(false);
  };

  const geLocationtList = async (params, stateSetter, loaderSetter) => {
    loaderSetter(true);

    if (stateSetter === "state") {
      setLocationFiltersList({
        state: [],
        city: [],
        taluka: [],
        village: [],
        khasra: [],
      });
    } else if (stateSetter === "city") {
      let obj = {
        ...locationFiltersList,
        city: [],
        taluka: [],
        village: [],
        khasra: [],
      };
      setLocationFiltersList(obj);
    } else if (stateSetter === "taluka") {
      let obj = {
        ...locationFiltersList,
        taluka: [],
        village: [],
        khasra: [],
      };
      setLocationFiltersList(obj);
    } else if (stateSetter === "village") {
      let obj = {
        ...locationFiltersList,
        village: [],
        khasra: [],
      };
      setLocationFiltersList(obj);
    } else {
      let obj = {
        ...locationFiltersList,

        khasra: [],
      };
      setLocationFiltersList(obj);
    }

    const res = await axios({
      method: "GET",
      url:
        appconfig.domain[appconfig.environment].agribhumi +
        "admin/webp" +
        params,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    let obj = { ...locationFiltersList, [stateSetter]: res.data.data };
    setLocationFiltersList(obj);
    loaderSetter(false);
  };

  const manageUpperCaseString = (str) => {
    let lower = str.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  };

  const resetFiltersBtn = () => {
    resetFilters();
  };

  const handleSearchBtn = () => {
    handleSearch();
  };

  useEffect(() => {
    geLocationtList("/pre_country.php", "country", setDropdownLoader);
  }, []);

  return (
    <>
      <Select
        className="dropdown"
        placeholder="Select Feature Type"
        value={featureType}
        onChange={(val) => {
          setFeatureType(val);
        }}
      >
        <Option value="crop-data">
          <Tooltip title={ToolTipContent["crop-data"]} placement="right">
            Crop Data
          </Tooltip>
        </Option>
        <Option value="soil-data">
          <Tooltip title={ToolTipContent["soil-data"]} placement="right">
            Soil Data
          </Tooltip>
        </Option>

        {locationFilters.country != null &&
          locationFilters.country.code != "2" && (
            <>
              <Option
                value="cadastral-data"
                disabled={
                  locationFilters.village !== null &&
                  locationFilters.khasra === null
                    ? false
                    : true
                }
              >
                {locationFilters.village !== null &&
                locationFilters.khasra === null ? (
                  <Tooltip
                    title="Farm boundaries with ownership and khasra details of land parcel."
                    placement="right"
                  >
                    Cadastral Data
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Cadastral data can only be accessed at Village level. Please select a village."
                    placement="right"
                    color={"#303030"}
                  >
                    Cadastral Data
                  </Tooltip>
                )}
              </Option>

              <Option
                value="crop-history"
                disabled={
                  locationFilters.village !== null ||
                  locationFilters.khasra !== null
                    ? false
                    : true
                }
              >
                {locationFilters.village !== null ||
                locationFilters.khasra !== null ? (
                  <Tooltip
                    title="A record of crops grown on the farm during previous seasons."
                    placement="right"
                  >
                    {" "}
                    Crop History{" "}
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Crop History can only be accessed at Village & Khasra level. Please select a village or khasra."
                    placement="right"
                    color={"#303030"}
                  >
                    Crop History
                  </Tooltip>
                )}
              </Option>
              <Option
                value="farm-report"
                disabled={locationFilters.khasra !== null ? false : true}
              >
                {locationFilters.khasra !== null ? (
                  <Tooltip
                    title="Farm record with details of ownership, available nutrients in the soil, and crop history from the previous year."
                    placement="right"
                  >
                    Farm Report
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Farm Report can only be generated at Khasra level. Please select a khasra."
                    placement="right"
                    color={"#303030"}
                  >
                    Farm Report
                  </Tooltip>
                )}
              </Option>
            </>
          )}
        <Option value="lulc">
          {" "}
          <Tooltip title={ToolTipContent["lulc"]} placement="right">
            LULC
          </Tooltip>
        </Option>
        <Option value="analytics">Analytics</Option>
        <Option value="farmer-advisory">
          <Tooltip title={ToolTipContent["farmer-advisory"]} placement="right">
            Farmer Advisory
          </Tooltip>
        </Option>
        {locationFilters.country != null &&
          locationFilters.country.code != "2" && (
            <>
              <Option value="farms">
                <Tooltip title="Geotagged farm" placement="right">
                  Farms
                </Tooltip>
              </Option>
              <Option
                value="farmer-scorecard"
                disabled={locationFilters.khasra !== null ? false : true}
              >
                {locationFilters.khasra !== null ? (
                  <Tooltip
                    title="A score provided based on the performance and profitability of previous years records."
                    placement="right"
                  >
                    Farmer Scorecard
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Farmer Scorecard can only be generated at Khasra level. Please select a khasra."
                    placement="right"
                    color={"#303030"}
                  >
                    Farmer Scorecard
                  </Tooltip>
                )}
              </Option>
            </>
          )}
      </Select>
      {featureType !== "farms" ? (
        <>
          {featureType === "crop-data" && seasonsList.data?.length > 0 && (
            <Select
              className="dropdown"
              placeholder="Select Season"
              value={selectedSeason.season_id}
              onSelect={(val, option) => {
                setSelectedSeason({
                  season_name: option.children,
                  season_id: val,
                });
                // getCropHistoryYieldAreaData(option.children);
              }}
              allowClear
            >
              {seasonsList.data.map(
                (item, index) =>
                  item.season_name !== null && (
                    <Option key={index} value={item.season_id}>
                      {item.season_name}
                    </Option>
                  )
              )}
            </Select>
          )}
          {featureType === "soil-data" && seasonsList.data?.length > 0 && (
            <Select
              className="dropdown"
              placeholder="Select Nutrient"
              value={selectedSoilNutrient}
              onSelect={(val) => {
                setSelectedSoilNutrient(val);
                clearSoilDataLegend()
              }}
            >
              {SoilDropDownData?.map((item) => (
                <option key={item.key} value={item.value}>
                  {item.name}
                </option>
              ))}
            </Select>
          )}
          <Select
            showSearch
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            placeholder="Select Country*"
            className="dropdown"
            loading={dropdownLoader && !locationFiltersList.country?.length}
            value={locationFilters.country?.code}
            onChange={(val) => {
              if (val) {
                geLocationtList(
                  `/pre_states.php?action=state&country_id=${val}`,
                  "state",
                  setDropdownLoader
                );
              } else {
                setLocationFilters({
                  ...locationFilters,
                  country: null,
                  state: null,
                  city: null,
                  taluka: null,
                  village: null,
                  khasra: null,
                });

                setLocationFiltersList({
                  ...locationFiltersList,
                  state: [],
                  city: [],
                  taluka: [],
                  village: [],
                  khasra: [],
                });
              }
            }}
            onSelect={(val, option) => {
              setLocationFilters({
                ...locationFilters,
                country: { label: option.children, code: val },
                state: null,
                city: null,
                taluka: null,
                village: null,
                khasra: null,
              });
            }}
          >
            {locationFiltersList.country?.length &&
              locationFiltersList.country.map((item, index) => (
                <Option key={index} value={item.country_id}>
                  {item.country_name}
                </Option>
              ))}
          </Select>
          <Select
            showSearch
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            placeholder="Select State*"
            className="dropdown"
            loading={dropdownLoader && !locationFiltersList.state.length}
            value={locationFilters.state?.code}
            onChange={(val) => {
              if (val) {
                geLocationtList(
                  `/pre_district.php?action=district&state_id=${val}`,
                  "city",
                  setDropdownLoader
                );
              } else {
                setLocationFilters({
                  ...locationFilters,
                  state: null,
                  city: null,
                  taluka: null,
                  village: null,
                  khasra: null,
                });
                setLocationFiltersList({
                  ...locationFiltersList,
                  city: [],
                  taluka: [],
                  village: [],
                  khasra: [],
                });
              }
            }}
            onSelect={(val, option) => {
              setLocationFilters({
                ...locationFilters,
                state: { label: option.children, code: val },
                city: null,
                taluka: null,
                village: null,
                khasra: null,
              });
            }}
          >
            {locationFiltersList.state.length &&
              locationFiltersList.state.map((item, index) => (
                <Option key={index} value={item.state_code}>
                  {item.state_name}
                </Option>
              ))}
          </Select>
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            placeholder={
              locationFilters.country?.code == "2"
                ? "Select LGA*"
                : "Select City*"
            }
            allowClear
            className="dropdown"
            loading={dropdownLoader && !locationFiltersList.city.length}
            value={locationFilters.city?.code}
            onChange={(val) => {
              if (val) {
                geLocationtList(
                  `/pre_taluk.php?action=taluk&district_id=${val}`,
                  "taluka",
                  setDropdownLoader
                );
              } else {
                setLocationFilters({
                  ...locationFilters,
                  city: null,
                  taluka: null,
                  village: null,
                  khasra: null,
                });
                setLocationFiltersList({
                  ...locationFiltersList,
                  taluka: [],
                  village: [],
                  khasra: [],
                });
              }
            }}
            onSelect={(val, option) => {
              setLocationFilters({
                ...locationFilters,
                city: {
                  label: option.children,
                  code: val,
                },
                taluka: null,
                village: null,
                khasra: null,
              });
            }}
          >
            {locationFiltersList.city.length &&
              locationFiltersList.city.map((item, index) => (
                <Option key={index} value={item.district_code}>
                  {item.district_name}
                </Option>
              ))}
          </Select>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            loading={dropdownLoader && !locationFiltersList.taluka?.length}
            placeholder={
              locationFilters.country?.code == "2"
                ? "Select Ward"
                : "Select Taluka"
            }
            className="dropdown"
            value={locationFilters.taluka?.code}
            onChange={(val) => {
              if (val) {
                geLocationtList(
                  `/pre_village.php?action=village&taluk_id=${val}`,
                  "village",
                  setDropdownLoader
                );
              } else {
                setLocationFilters({
                  ...locationFilters,
                  taluka: null,
                  village: null,
                  khasra: null,
                });
                setLocationFiltersList({
                  ...locationFiltersList,
                  village: [],
                  khasra: [],
                });
              }
            }}
            onSelect={(val, option) => {
              setLocationFilters({
                ...locationFilters,
                taluka: { label: option.children, code: val },

                village: null,
                khasra: null,
              });
            }}
          >
            {locationFiltersList.taluka.length &&
              locationFiltersList.taluka.map((item, index) => (
                <Option key={index} value={item.taluk_code}>
                  {item.taluk_name}
                </Option>
              ))}
          </Select>
          {locationFilters.country != null &&
            locationFilters.country.code != "2" && (
              <>
                <Select
                  allowClear
                  showSearch
                  loading={
                    dropdownLoader && !locationFiltersList.village?.length
                  }
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  className="dropdown"
                  placeholder="Select Village"
                  value={locationFilters.village?.code}
                  onChange={(val) => {
                    if (val) {
                      geLocationtList(
                        `/new_khasra_list.php?village_code=${val}`,
                        "khasra",
                        setDropdownLoader
                      );
                    } else {
                      setLocationFilters({
                        ...locationFilters,
                        village: null,
                        khasra: null,
                      });
                      setLocationFiltersList({
                        ...locationFiltersList,
                        khasra: [],
                      });
                    }
                  }}
                  onSelect={(val, option) => {
                    setLocationFilters({
                      ...locationFilters,
                      village: { label: option.children, code: val },
                      khasra: null,
                    });
                  }}
                >
                  {locationFiltersList.village.length &&
                    locationFiltersList.village.map((item, index) => (
                      <Option key={index} value={item.agbr_village_code}>
                        {manageUpperCaseString(item.village_name)}
                      </Option>
                    ))}
                </Select>

                <Select
                  allowClear
                  showSearch
                  loading={
                    dropdownLoader && !locationFiltersList.khasra?.length
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .includes(input.toString().toLowerCase())
                  }
                  className="dropdown"
                  placeholder="Select Khasra"
                  value={locationFilters.khasra?.label}
                  onChange={(val) => {
                    if (!val) {
                      setLocationFilters({
                        ...locationFilters,
                        khasra: null,
                      });
                    }
                  }}
                  onSelect={(val, option) => {
                    if (val) {
                      setLocationFilters({
                        ...locationFilters,
                        khasra: { label: option.children, code: val },
                      });
                    } else {
                      setLocationFilters({
                        ...locationFilters,
                        khasra: null,
                      });
                    }
                  }}
                >
                  {locationFiltersList.khasra.length &&
                    locationFiltersList.khasra.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.khasra}
                      </Option>
                    ))}
                </Select>
              </>
            )}
          <Button
            shape="circle"
            icon={<SearchOutlined />}
            className="resetBtn"
            onClick={handleSearchBtn}
            style={{ marginLeft: "2%" }}
            disabled={searchDisable}
          />
          <Button
            style={{ marginLeft: "0px", marginRight: "24px" }}
            shape="circle"
            icon={<ReloadOutlined />}
            className="resetBtn"
            onClick={resetFiltersBtn}
            disabled={searchDisable}
          />
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-around",
            marginLeft: "2%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {allFarms.length > 0 && (
                <div>
                  <Pagination
                    className="ant-pagination-item ant-pagination-item"
                    size="small"
                    showQuickJumper
                    defaultCurrent={1}
                    total={farmsPaginataion * 10}
                    style={{ color: "#ffffff" }}
                    onChange={(page, pageSize, event) => {
                      setFarmsCount(true);
                      getAllFarms(page * 20 - 20, farmName, farmMobileNum);
                      setCurrentPage(page * 20 - 20);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LocationHeader;
