import {
  BarChartOutlined,
  CloseOutlined,
  DoubleLeftOutlined,
  LeftCircleOutlined,
  MenuOutlined,
  RightCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  FloatButton,
  Layout,
  Modal,
  Select,
  Tooltip,
  Typography,
  message,
  notification,
} from "antd";
import axios from "axios";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as L from "leaflet";
import "leaflet-draw";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Drawer from "../../components/Drawer/Drawer";
import FarmerScoreCardv3 from "../../components/FarmerScoreCard/FarmerScorecardv3";
import AdvisoryModal from "../../components/Modals/Advisory/AdvisoryModal";
import CropHistoryModal from "../../components/Modals/CropHistory/CropHistoryModal";
import { getWeatherDetails } from "../../components/Navbar/action";
import { apiurls } from "../../resources/APIurls";
import { appconfig } from "../../resources/config";
import { legendColor } from "../../resources/constants";
import {
  getData,
  initWMSHeader,
  postData,
  wmsHeaderExtend,
} from "../../resources/utils";
import { agribazaarWorkspaces } from "../../resources/workspaces";
import "./Discover.css";
import FarmerReportDialog from "./FarmerReport";
import LocationHeader from "../../components/LocationHeader/LocationHeader";
import { DropDownDataAnyaltics } from "../../resources/anayltics/DropDownDataAnyaltics";

const { RangePicker } = DatePicker;

const { Content } = Layout;
const { Option } = Select;

const Discover = ({ siderFlag, siderFlagController }) => {
  let apikey_s3 = apiurls.find((item) => item.apikey === "aws3");
  const aws_s3_url = `${appconfig.domain[appconfig.environment].aws3}${
    apikey_s3.paths.images
  }`;
  const dispatch = useDispatch();

  //STATE VARIABLES DECLARATION
  const healthIndexDataRef = useRef(null);
  const [healthIndexData, setHealthIndexData] = useState(
    healthIndexDataRef.current
  );
  useEffect(() => {
    if (healthIndexData?.length) {
      healthIndexDataRef.current = healthIndexData;
    }
  }, [healthIndexData]);
  const [leftOutlinedButton,setLeftOutlinedButton] = useState(false)
  const [allFarms, setAllFarms] = useState([]);
  const [farmsPaginataion, setFarmsPaginataion] = useState([]);
  const [farmsCount, setFarmsCount] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [CropTransModal, setCropTransModal] = useState(false);
  const [farmAdvisory, setFarmAdvisory] = useState("crop-selection");
  const [seasons, setSeasons] = useState("");
  const [modalMenu, setModalMenu] = useState(false);
  const [isCropModelOpen, setIsCropModelOpen] = useState(false);
  const [nitrogen, setNitrogen] = useState("");
  const [phosphorus, setPhosphorus] = useState("");
  const [potassium, setPotassium] = useState("");
  const [silt, setSilt] = useState("");
  const [sand, setSand] = useState("");
  const [clay, setClay] = useState("");
  const [selectedCrop, setSelectedCrop] = useState({});
  const [fertilizerRecomn, setFertilizerRecomn] = useState([]);
  const [soilTexture, setSoilTexture] = useState([]);
  const [fertiliedrecommenedCrop, setFertiliedrecommenedCrop] = useState([]);
  const [fertilizedValues, setFertilizedValues] = useState({});
  const [soilLegend, setSoilLegend] = useState([]);
  const [isScoreCardOpen, setIsScoreCardOpen] = useState(false);
  const [farmersScore, setFarmersScore] = useState([]);
  const [farmLandScore, setFarmLandScore] = useState({});
  const [profitLossScore, setProfitLossScore] = useState({});
  const [riskProfileScore, setRiskProfileScore] = useState({});
  const [weatherTrend, setWeatherTrend] = useState({});
  const [scorecardLoader, setScorecardLoader] = useState({
    basicDetail: false,
    farmLandDetail: false,
    riskProfileDetail: false,
  });
  const [searchState, setSearchState] = useState(false);
  const [advisoryModal, setAdvisoryModal] = useState(false);
  const [farmerId, setFarmerId] = useState("");
  const [farmerLoaderIcon, setFarmerLoaderIcon] = useState(false);
  const [mapLoader, setMapLoader] = useState(false);
  const [farmerRecordsDialog, setFarmerRecordsDialog] = useState(false);
  const [overlayLayer, setOverlayLayer] = useState({});
  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [seasonsList, setSeasonsList] = useState([]);
  const [owsLayerData, setOwsLayerData] = useState({});
  const [pricipalLoader, setPricipalLoader] = useState(false);
  const [rasterLayer, setRasterLayer] = useState();
  const [markerLayer, setMarkerLayer] = useState([]);
  const [featureType, setFeatureType] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedSoilNutrient, setSelectedSoilNutrient] =
    useState("bulkdensity");
  const [collapsed, setCollapsed] = useState(true);
  const [selectedCommodity, setSelectedCommodity] = useState([]);
  const [selectedCommoditiesList, setSelectedCommoditiesList] = useState([]);
  const [areaDataParams, setAreaDataParams] = useState({});
  const [deletedCommodity, setDeletedCommodity] = useState(null);
  const [areaDetail, setAreaDetail] = useState({
    totalArea: 0,
    goodArea: 0,
    moderateArea: 0,
    poorArea: 0,
  });
  const [acerageVal, setAcerageVal] = useState([]);
  const [acerageValLoader, setAcerageValLoader] = useState(false);
  const [acerageValDetail, setAcerageValDetail] = useState({
    totalArea: 0,
  });
  const [farmerStatistics, setFarmerStatistics] = useState({});
  const [cropHistory, setCropHistory] = useState({});
  const [cropHistoryDialog, setCropHistoryDialog] = useState(false);
  const [cropHistoryLoader, setCropHistoryLoader] = useState(false);
  const [farmerReportDialog, setFarmerReportDialog] = useState(false);
  const [farmerReportData, setFarmerReportData] = useState({});
  const [selectedVI, setSelectedVI] = useState("Crop Classification");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [viLatlongData, setViLatlongData] = useState([]);
  // const viLatlongDataRef = useRef();
  // viLatlongDataRef.current = viLatlongData;

  const [farmerStatistics2, setFarmerStatistics2] = useState({});
  const [cropHealthLoader, setCropHealthLoader] = useState(false);
  const [showViGraph, setShowViGraph] = useState(false);
  const [farmReportCropHistory, setFarmReportCropHistory] = useState({});
  const [yeildInfo, setYeildInfo] = useState([]);
  const [farmReportRecomndCrop, setFarmReportRecomndCrop] = useState([]);
  const [farmReportsoilText, setFarmReportsoilText] = useState([]);
  const [farmCropHealth, setFarmCropHealth] = useState({});
  const [farmNpk, setFarmNpk] = useState({});
  const [searchDisable, setSearchDisable] = useState(false);
  const [recomndLoader, setRecomndLoader] = useState(false);
  const [farmName, setFarmName] = useState("");
  const [farmMobileNum, setFarmMobileNum] = useState("");
  const [selectedViDate, setSelectedViDate] = useState("");
  const [farmerRecords, setFarmerRecords] = useState({
    recordsEng: [],
    recordsHindi: [],
  });
  const [locationFiltersList, setLocationFiltersList] = useState({
    state: [],
    city: [],
    taluka: [],
    village: [],
    khasra: [],
  });
  const [locationFilters, setLocationFilters] = useState({
    country: null,
    state: null,
    city: null,
    taluka: null,
    village: null,
    khasra: null,
  });
  const [wmsLayerProps, setWmsLayerProps] = useState({
    service: "WMS",
    request: "GetMap",
    format: "image/png",
    transparent: true,
    version: "1.3.0",
    width: 256,
    height: 256,
    maxZoom: 40,
    maxNativeZoom: 40,
    weight: 3,
  });
  const [pieChartOptions, setPieChartOptions] = useState({
    chart: {
      plotBackgroundColor: "#212121",
      backgroundColor: "#212121",
      plotBorderWidth: 0,
      plotShadow: false,
      type: "pie",
      height: 180,
      width: 260,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y}%</b>",
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
          format: "<b>{point.name}</b> {point.y} %",
        },
      },
    },
    series: [
      {
        name: "",
        colorByPoint: true,
        borderWidth: null,
        data: [
          {
            name: "Good Area",
            y: 61.41,
            color: "#9FC088",
          },
          {
            name: "Moderate Area",
            y: 11.84,
            color: "#E8C07D",
          },
          {
            name: "Poor Area",
            y: 10.85,
            color: "#F55353",
          },
        ],
      },
    ],
  });
  const [viChartOptions2, setViChartOptions2] = useState({
    chart: {
      plotBackgroundColor: "#ffffff",
      backgroundColor: "#ffffff",
      plotBorderWidth: 0,
      plotShadow: false,
      type: "spline",
      height: 180,
      width: 300,
    },
    title: {
      text: "",
    },
    rangeSelector: {
      selected: 1,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "<b>{point.y}</b>",
    },
    xAxis: {
      visible: true,
      gridLineColor: "transparent",
      type: "datetime",
      labels: {
        format: "{value:%e.%b}",
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      visible: true,
      gridLineColor: "transparent",
      title: {
        text: "VI (%)",
      },
      labels: {
        formatter: null,
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        marker: {
          fillColor: "transparent",
          lineColor: Highcharts.getOptions().colors[0],
        },
        data: [
          7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,
        ],
      },
      {
        marker: {
          fillColor: "transparent",
        },
        data: [
          -0.2, 0.8, 5.7, 11.3, 17.0, 22.0, 24.8, 24.1, 20.1, 14.1, 8.6, 2.5,
        ],
        dashStyle: "dot",
      },
    ],
  });
  const [viChartOptions, setViChartOptions] = useState({
    chart: {
      plotBackgroundColor: "#212121",
      backgroundColor: "#212121",
      plotBorderWidth: 0,
      plotShadow: false,
      type: "spline",
      height: 180,
      width: 260,
    },
    title: {
      text: "",
    },
    rangeSelector: {
      selected: 1,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "<b>{point.y}</b>",
    },
    xAxis: {
      visible: true,
      gridLineColor: "transparent",
      type: "datetime",
      labels: {
        format: "{value:%e.%b}",
      },
    },
    yAxis: {
      visible: true,
      gridLineColor: "transparent",
      title: {
        text: "VI",
      },
    },

    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "1 jan",
        colorByPoint: true,
        borderWidth: null,
        data: [23, 45, 12, 67, 34, 45, 76, 32, 34, 12, 43, 42, 37],
      },
    ],
  });
  const [statChartOptions, setStatChartOptions] = useState({
    chart: {
      type: "column",
      inverted: false,
      height: 180,
      plotBackgroundColor: "#212121",
      backgroundColor: "#212121",
      plotBorderWidth: 0,
      plotShadow: false,
    },
    title: {
      text: "",
    },

    xAxis: {
      categories: [
        "Kisan Credit Card",
        "PM Kisan Yojana",
        "Agrimachinery",
        "Soil Health Card",
        "PM Fasal Bima Yojana",
      ],
      labels: {
        style: {
          color: "#ffffff",
          fontSize: "10px",
        },
        autoRotationLimit: 40,
      },
      title: {
        text: null,
      },
    },
    yAxis: {
      gridLineColor: "transparent",
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      valueSuffix: "",
    },
    plotOptions: {
      series: {
        borderRadius: 0,
        borderWidth: 0,
        borderColor: "#3B3939",
        pointWidth: 25,
        dataLabels: {
          enabled: true,
          color: "#ffffff",
          fontSize: "12px",
        },
        colors: ["#5F7464", "#5F7464", "#5F7464", "#5F7464", "#ffffff"],
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Yes",
        data: [
          { name: "", y: 107, color: "#325CBB" },
          { name: "", y: 31, color: "#4AAB28" },
          { name: "", y: 135, color: "#FFBB00" },
          { name: "", y: 203, color: "#D17918" },
          { name: "", y: 72, color: "#FF8B00" },
        ],
      },
    ],
  });
  const [drawControl, setDrawControl] = useState({
    toggle: null,
    polygonDraw: null,
    drawnItems: null,
    area: null,
  });

  const [lulcAreaList,setLulcAreaList]=useState([])

  //------------------------***************------------------------
  //REF VARIABLE DECLARATIONS
  const farmAdvisoryRef = useRef(null);
  const mapRef = useRef(null);
  const layerControlRef = useRef(null);
  const workspaceLegendRef = useRef(null);
  const cropLegendRef = useRef(null);
  const lulcLegendRef = useRef(null);
  const analyticsLegndRef = useRef(null);
  const soilLegendRef = useRef(null);
  const owsLayerDataRef = useRef(null);
  const startdateRef = useRef();
  const enddateRef = useRef();
  const selectedviRef = useRef();
  const advisoryModalRef = useRef();
  advisoryModalRef.current = advisoryModal;
  startdateRef.current = startDate;
  enddateRef.current = endDate;
  selectedviRef.current = selectedVI;
  owsLayerDataRef.current = owsLayerData;
  farmAdvisoryRef.current = farmAdvisory;

  //------------------------***************------------------------

  //Set up snapshotter
  const snapshotOptions = {
    hideElementsWithSelectors: [
      ".leaflet-control-container",
      ".leaflet-wmsPane-pane",
      "#snapshot-button",
    ],
    hidden: true,
  };

  const [sampleScreenshot, setSampleScreenshot] = useState("");
  const [screenshotBounds, setScreenshotBounds] = useState("");

  useEffect(() => {
    if (
      (featureType === "farm-report" && locationFilters.khasra === null) ||
      locationFilters.city === null
    ) {
      setSearchDisable(true);
    } else {
      setSearchDisable(false);
    }
  }, [locationFilters, featureType]);

  const mapAccessToken =
    "pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw";
  const tileLayerUrl = {
    mapbox:
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" +
      mapAccessToken,
    googlemap: "http://{s}.google.com/vt/",
  };
  const mapLayers = {
    googleStreets: L.tileLayer(
      tileLayerUrl.googlemap + "lyrs=m&x={x}&y={y}&z={z}",
      {
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }
    ),
    googleHybrid: L.tileLayer(
      tileLayerUrl.googlemap + "lyrs=s,h&x={x}&y={y}&z={z}",
      {
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
        maxNativeZoom: 40,
        maxZoom: 40,
      }
    ),
    googleTerrain: L.tileLayer(
      tileLayerUrl.googlemap + "lyrs=p&x={x}&y={y}&z={z}",
      {
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }
    ),
    mapboxOSM: L.tileLayer(tileLayerUrl.mapbox, {
      id: "mapbox/light-v9",
      tileSize: 512,
      zoomOffset: -1,
    }),
    mapboxSAT: L.tileLayer(tileLayerUrl.mapbox, {
      id: "mapbox/satellite-streets-v9",
      tileSize: 512,
      zoomOffset: -1,
    }),
    btn: "this is it",
  };
  const baseLayer = {
    "Google Hybrid": mapLayers.googleHybrid,
    "Google Street": mapLayers.googleStreets,
    "Google Terrain": mapLayers.googleTerrain,
    "Mapbox Street": mapLayers.mapboxOSM,
    "Mapbox Satellite": mapLayers.mapboxSAT,
  };

  const [messageApi, contextHolder] = message.useMessage();
  const [api, notifContextHolder] = notification.useNotification();
  const [showFloatButton, setShowFloatButton] = useState(true);

  // popup a alert message when API got failed
  const warningMessage = (message) => {
    messageApi.open({
      type: "warning",
      content: message,
      duration: 2,
    });
  };

  // initMap load the base map on screen always.

  const initMap = () => {
    mapRef.current = L.map("map", {
      center: [21.0, 78.0],
      zoom: 4,
      maxNativeZoom: 40,
      maxZoom: 40,
      zoomControl: false,
      layers: [mapLayers.googleHybrid],
      attributionControl: false,
      interactive: true,
    });

    mapRef.current.addControl(L.control.zoom({ position: "topleft" }));

    layerControlRef.current = L.control
      .layers(baseLayer, overlayLayer, { position: "topright" })
      .addTo(mapRef.current);
  };

  const getWorkspaceLayer = () => {
    var greenIcon = new L.Icon({
      iconUrl: `${aws_s3_url}/map-marker.png`,
      shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
      iconSize: [20, 20],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [20, 20],
    });

    let markerArr = new Array();
    setMarkerLayer(markerArr);

    for (var i = 0; i < agribazaarWorkspaces.length; i++) {
      var marker = new L.marker(
        [agribazaarWorkspaces[i].latitude, agribazaarWorkspaces[i].Longitude],
        { icon: greenIcon }
      ).bindPopup(
        `<strong>Warehouse Name:</strong> ${agribazaarWorkspaces[i]["W_H_NAME"]}<br /><br /><strong>Warehouse Address:</strong>${agribazaarWorkspaces[i]["w_H_ADDRES"]}<br /><br /><strong>Staragri branch:</strong>${agribazaarWorkspaces[i]["BRANCH"]}<br /><br /><strong>State:</strong>${agribazaarWorkspaces[i].State}`
      );
      marker.on("mouseover", function (e) {
        this.openPopup();
      });
      marker.on("mouseout", function (e) {
        this.closePopup();
      });
      markerArr.push(marker);
      mapRef.current.addLayer(markerArr[i]);
    }

    workspaceLegendRef.current = L.control({ position: "bottomleft" });

    workspaceLegendRef.current.onAdd = function (map) {
      let div = L.DomUtil.create("div", "legend");

      div.innerHTML +=
        `<div ><img width="20px" src="/map-marker.png"/><span style="margin-left:4px;">We are here</span>` +
        "</div>";

      return div;
    };
    workspaceLegendRef.current.addTo(mapRef.current);
  };

  useEffect(() => {
    initMap();
    initWMSHeader();
    getWorkspaceLayer();
    // geLocationtList("/pre_country.php", "country", setDropdownLoader);
  }, []);

  const geLocationtList = async (params, stateSetter, loaderSetter) => {
    loaderSetter(true);

    if (stateSetter === "state") {
      setLocationFiltersList({
        state: [],
        city: [],
        taluka: [],
        village: [],
        khasra: [],
      });
    } else if (stateSetter === "city") {
      let obj = {
        ...locationFiltersList,
        city: [],
        taluka: [],
        village: [],
        khasra: [],
      };
      setLocationFiltersList(obj);
    } else if (stateSetter === "taluka") {
      let obj = {
        ...locationFiltersList,
        taluka: [],
        village: [],
        khasra: [],
      };
      setLocationFiltersList(obj);
    } else if (stateSetter === "village") {
      let obj = {
        ...locationFiltersList,
        village: [],
        khasra: [],
      };
      setLocationFiltersList(obj);
    } else {
      let obj = {
        ...locationFiltersList,

        khasra: [],
      };
      setLocationFiltersList(obj);
    }

    const res = await axios({
      method: "GET",
      url:
        appconfig.domain[appconfig.environment].agribhumi +
        "admin/webp" +
        params,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    let obj = { ...locationFiltersList, [stateSetter]: res.data.data };
    setLocationFiltersList(obj);
    loaderSetter(false);
  };

  // getFarms call the farmer Statictics api for count total numbers of farmers and total number of farmland.
  const getFarms = async (id) => {
    let ids = {};

    if (locationFilters.khasra !== null) {
      ids = {
        khasra_id: locationFilters.khasra.code,
      };
    } else if (locationFilters.village !== null) {
      ids = {
        village_id: locationFilters.village.code,
      };
    } else if (locationFilters.taluka !== null) {
      ids = {
        taluk_id: locationFilters.taluka.code,
      };
    } else {
      ids = {
        district_code: locationFilters.city.code,
      };
    }

    let data = {
      ...ids,
    };
    let res = await postData("agribhumi2", "getfarms", data, null);

    setFarmerStatistics2(res.data);
  };

  const clearMap = () => {
    if (mapRef.current.hasLayer(rasterLayer))
      mapRef.current.removeLayer(rasterLayer);

    mapRef.current.eachLayer(function (layer) {
      if (layer instanceof L.GeoJSON) mapRef.current.removeLayer(layer);
    });
    if (workspaceLegendRef.current instanceof L.Control) {
      mapRef.current.removeControl(workspaceLegendRef.current);
    }
    removeLegends();
  };

  const resetFilters = () => {
    clearMap();
    setSelectedViDate("");
    mapRef.current.setView([21.0, 78.0], 5);
    layerControlRef.current.remove(mapRef.current);
    layerControlRef.current = L.control
      .layers(baseLayer, overlayLayer, { position: "topright" })
      .addTo(mapRef.current);

    setLocationFilters({
      state: null,
      city: null,
      taluka: null,
      village: null,
      khasra: null,
    });
    setFeatureType(null);
    setSeasonsList([]);
    getWorkspaceLayer();

    if (featureType !== "farms") {
      setSelectedCommoditiesList([]);
      setSelectedCommodity([]);
      setFarmerStatistics2([]);
      setAreaDetail({
        totalArea: 0,
        goodArea: 0,
        moderateArea: 0,
        poorArea: 0,
      });
      setAcerageVal([]);
    } else {
      setAllFarms([]);
    }
  };

  useEffect(() => {
    if (layerControlRef.current !== null) {
      mapRef.current.removeControl(layerControlRef.current);
    }
    layerControlRef.current = L.control
      .layers(baseLayer, overlayLayer, {
        position: "topright",
      })
      .addTo(mapRef.current);
  }, [overlayLayer]);

  const getCadastralData = async () => {
    let params = {
      service: "WFS",
      version: "2.0",
      request: "GetFeature",
      outputFormat: "application/json",
      format_options: "callback:getJson",
      SrsName: "EPSG:4326",
    };

    params["typeName"] =
      "crop_distribution:india_" +
      locationFilters.state.label.split(" ").join("").toLowerCase() +
      "_" +
      locationFilters.city.label.split(" ").join("").toLowerCase() +
      "_" +
      locationFilters.taluka.label.split(" ").join("").toLowerCase() +
      "_cadestral";
    params["CQL_FILTER"] = `Agbr_co IN ('${locationFilters.village.code}')`;
    params["selectValue"] = "0" + locationFilters.village.code;

    const res = await getData("geohost", "ows", params, null);

    let vectorLayer = loadFeatureLayer(res.data);
    vectorLayer.addTo(mapRef.current);
  };

  const [owsState, setOwsState] = useState(false);

  const getOwsLayerData = async () => {
    setOwsState(true);
    setMapLoader(true);
    let params = {
      service: "WFS",
      version: "2.0",
      request: "GetFeature",
      outputFormat: "application/json",
      format_options: "callback:getJson",
      SrsName: "EPSG:4326",
    };

    if (locationFilters.city === null) {
      params["typeName"] = "crop_distribution:India_State_Boundary2019";
      params["CQL_FILTER"] = `State_LGD IN (${locationFilters.state.code})`;
    } else if (locationFilters.taluka === null) {
      if (locationFilters.country.code == "2") {
        params["typeName"] =
          "crop_distribution:nigeria_" +
          locationFilters.state.label.split(" ").join("").toLowerCase() +
          "_lga_boundary";
        params["CQL_FILTER"] = `Subdt_LGD IN (${locationFilters.city.code})`;
      } else {
        params["typeName"] = "crop_distribution:India_District_Boundary2019";
        params["CQL_FILTER"] = `Dist_LGD IN (${locationFilters.city.code})`;
      }

      params["selectValue"] = locationFilters.city.code;
    } else if (
      locationFilters.village === null &&
      locationFilters.taluka !== null
    ) {
      if (locationFilters.country.code == "2") {
        params["typeName"] =
          "crop_distribution:nigeria_" +
          locationFilters.state.label.split(" ").join("").toLowerCase() +
          "_lga_ward_boundary";
        params["CQL_FILTER"] = `VILLAGE_CO IN (${locationFilters.taluka.code})`;
      } else {
        params["typeName"] =
          "crop_distribution:India_Sub_District_Boundary2019";
        params["CQL_FILTER"] = `Subdt_LGD IN (${locationFilters.taluka.code})`;
      }

      params["selectValue"] = locationFilters.taluka.code;
    } else if (
      locationFilters.khasra == null &&
      locationFilters.village !== null
    ) {
      params["typeName"] =
        "crop_distribution:2023_india_" +
        locationFilters.state.label.split(" ").join("_").toLowerCase() +
        "_village_boundary";
      params["CQL_FILTER"] = `Agbr_co IN ('${locationFilters.village.code}')`;
      params["selectValue"] = "0" + locationFilters.village.code;
      if (featureType === "cadastral-data" || featureType === "crop-history") {
        removeLegends();
        getCadastralData();
      }
    } else {
      params["typeName"] =
        "crop_distribution:india_" +
        locationFilters.state.label.split(" ").join("").toLowerCase() +
        "_" +
        locationFilters.city.label.split(" ").join("").toLowerCase() +
        "_" +
        locationFilters.taluka.label.split(" ").join("").toLowerCase() +
        "_cadestral";
      params[
        "CQL_FILTER"
      ] = `Agbr_co IN ('${locationFilters.village.code}') AND KHASRA IN ('${locationFilters.khasra.label}')`;
      params["selectValue"] = locationFilters.village.code;
    }

    const res = await getData("geohost", "ows", params, null);


    let formattedLatLong=(res?.data["features"][0]["geometry"]["coordinates"][0][0])

    let lulcAreaParam={
      "country": locationFilters?.country?.label,
      "state": locationFilters?.state?.label,
      "feature": "LULC",
      "latlong": formattedLatLong
    }

    const lulcAreaResp = await postData(
      "indexhost",
      "lulcArea",
      lulcAreaParam,
      null
    );

    const lulcAreaLegendObject = {
      1: { name: "Crop Land Area", color: "#8FCE00" },
      2: { name: "Waste Land Area", color: "#FFF2CC" },
      3: { name: "Forest Area", color: "#B7E1CD" },
      4: { name: "Plantation Area", color: "#38761D" },
      5: { name: "Built up Area", color: "#F44336" },
      6: { name: "Water Bodies Area", color: "#006ACC" },
      7: { name: "Other area", color: "#E5C9D7" },
    };

    const tempLulcAreaLegend = lulcAreaResp?.data?.data
      ?.filter((item) => item?.raster_id !== 0) // Filter out raster_id 0
      .map((item) => ({
        name: lulcAreaLegendObject[item.raster_id]?.name || "Unknown Area",
        color: lulcAreaLegendObject[item.raster_id]?.color || "#000000", // Default color if not found
        area: item.area,
      }));

    setLulcAreaList(tempLulcAreaLegend);
    if (res.data.features.length > 0) {
      setOwsLayerData(res.data);
      addFeatureLayer(res.data);
      setPricipalLoader(false);
      setOwsState(false);
      return new Promise((resolve, reject) => {
        resolve(res.data);
      });
    } else {
      setMapLoader(false);
      setPricipalLoader(false);
      setOwsState(false);
      warningMessage("No data Avalaibale for the selected Region.");
    }
  };

  const addFeatureLayer = (layerData) => {
    if (layerData.features.length) {
      let layerobj = { ...layerData };
      let featureLyr = [];
      layerobj.features = [];
      for (let i = 0; i < layerData.features.length; i++) {
        layerobj.features = [];
        layerobj.features.push(layerData.features[i]);
        let featureLayer = loadFeatureLayer(layerData).addTo(mapRef.current);
        featureLyr.push(featureLayer);
      }

      function getB() {
        let result = featureLyr[0].getBounds();
        for (let i = 1; i < featureLyr.length; i++) {
          result.extend(featureLyr[i].getBounds());
        }
        setScreenshotBounds(result);
        return result;
      }

      mapRef.current.fitBounds(getB());
    } else {
    }
  };

  const validateWmsProps = (layerName) => {
    setMapLoader(true);
    let wmsObj = {};

    if (locationFilters.city === null) {
      wmsObj = {
        ...wmsLayerProps,
        layers: layerName,
        styles: "filter",
      };
    } else if (locationFilters.taluka === null) {
      wmsObj = {
        ...wmsLayerProps,
        layers: layerName,
        styles: "clip_image",
      };
    } else if (
      locationFilters.village === null &&
      locationFilters.taluka !== null
    ) {
      wmsObj = {
        ...wmsLayerProps,
        layers: layerName,
        styles: "clip_image",
      };
    } else if (
      locationFilters.khasra == null &&
      locationFilters.village !== null
    ) {
      wmsObj = {
        ...wmsLayerProps,
        layers: layerName,
        styles: "clip_image",
      };
    } else if (locationFilters.khasra !== null) {
      wmsObj = {
        ...wmsLayerProps,
        layers: layerName,
        styles: "clip_image",
      };
    }
    setWmsLayerProps(wmsObj);
    setMapLoader(false);
  };

  const initiateOws = async (seasonObj) => {
    let layer = "";

    if (featureType !== "crop-data") {
      if (cropLegendRef.current instanceof L.Control) {
        mapRef.current.removeControl(cropLegendRef.current);
      }
    }

    if (featureType === "crop-data") {
      if (locationFilters.country.code == "2") {
        layer =
          "crop_distribution:" +
          seasonObj?.season_name +
          "_" +
          locationFilters.country.label.split(" ").join("").toLowerCase() +
          "_" +
          locationFilters.state.label.split(" ").join("").toLowerCase();
      } else {
        if (seasonObj?.season_id == 11) {
          layer =
            "crop_distribution:" +
            seasonObj?.season_name +
            "_" +
            locationFilters.state.label.split(" ").join("").toLowerCase();
        } else {
          layer =
            "crop_distribution:" +
            seasonObj?.season_name +
            "_" +
            locationFilters.state.label.split(" ").join("").toLowerCase();
          +"_" + locationFilters.city.label.split(" ").join("").toLowerCase();
        }
      }

      validateWmsProps(layer);
    } else if (featureType === "soil-data") {
      layer = `soilproperties:${locationFilters.country.label
        .split(" ")
        .join("")
        .toLowerCase()}_${selectedSoilNutrient}`;
      getSoilDataLegendsVal();
      validateWmsProps(layer);
    } else if (featureType === "lulc") {
      layer = `LULC:LULC_${locationFilters.state?.label
        .split(" ")
        .join("")
        .toLowerCase()}`;
      validateWmsProps(layer);
    }

    let areaDataParamsObj = {};

    areaDataParamsObj["state"] =
      locationFilters.state !== null ? locationFilters.state.code : "";
    areaDataParamsObj["vill"] =
      locationFilters.village !== null ? locationFilters.village.code : "";
    areaDataParamsObj["tal"] =
      locationFilters.taluka !== null ? locationFilters.taluka.code : "";
    areaDataParamsObj["dist"] =
      locationFilters.city !== null ? locationFilters.city.code : "";
    areaDataParamsObj["session_id"] = seasonObj?.season_id;
    setAreaDataParams(areaDataParamsObj);

    const res2 = await getOwsLayerData();

    if (featureType == "crop-data") {
      setMapLoader(false);
      // getAreaData(selectedSeason?.season_name);
    } else {
      setMapLoader(false);
    }
  };

  // getSeasons brings the all season list.
  const getSeasons = async () => {
    mapRef.current.eachLayer(function (layer) {
      if (layer instanceof L.GeoJSON) mapRef.current.removeLayer(layer);
    });
    setPricipalLoader(true);

    let params = {};
    if (locationFilters.country.code == 2) {
      params["layer"] = locationFilters.state.label
        .split(" ")
        .join("")
        .toLowerCase();
    } else {
      if (locationFilters.city === null) {
        params["layer"] = locationFilters.state.label
          .split(" ")
          .join("")
          .toLowerCase();
      } else if (locationFilters.taluka === null) {
        params["layer"] = locationFilters.state.label
          .split(" ")
          .join("")
          .toLowerCase();
      } else if (locationFilters.village === null) {
        params["layer"] = locationFilters.state.label
          .split(" ")
          .join("")
          .toLowerCase();
      } else {
        params["layer"] = locationFilters.state.label
          .split(" ")
          .join("")
          .toLowerCase();
      }
    }
    
    const res = await postData("indexhost", "seasons", params, null);

    if (res.data.data?.length) {
      setSeasonsList(res.data);
      if(selectedSeason === ""){
        setSelectedSeason(
          res.data.data?.find(
            (item) =>
              item.season_id ==
              Math.max(...res.data.data?.map((o) => o.season_id))
          )
        );
        initiateOws(
          res.data.data?.find(
            (item) =>
              item.season_id ==
              Math.max(...res.data.data?.map((o) => o.season_id))
          )
        );
      }
      else{
        initiateOws(selectedSeason)
        
      }
     
      
    } else if( res.data.data?.length === undefined && featureType === "soil-data") {
      initiateOws(
        null
      );
    }
    else {
      setPricipalLoader(false);
      setMapLoader(false);
      warningMessage(res.data.message);
    }
  };

  const getWmsLayerData = async (params) => {
    setMapLoader(true);
    if (rasterLayer) {
      if (mapRef.current.hasLayer(rasterLayer)) {
        mapRef.current.removeLayer(rasterLayer);
      }
    }
    let wmsPane = mapRef.current.createPane("wmsPane");
    wmsPane.style.zIndex = 250;

    let obj = { ...params, crs: L.CRS.EPSG4326, pane: wmsPane };

    const headers = [
      {
        header: "content-type",
        value: "application/x-www-form-urlencoded",
      },
    ];
    const res = wmsHeaderExtend(
      appconfig.domain[appconfig.environment].geohost + "geoserver/wms",
      obj,
      headers,
      null,
      setMapLoader
    );

    setRasterLayer(res);
    setMapLoader(false);

    res.addTo(mapRef.current);
  };

  const getPolyLatLngs = (object) => {
    let latlngs = [],
      polyCoords = [];

    latlngs = object.features[0].geometry.coordinates[0][0];
    latlngs.forEach(function (obj) {
      polyCoords.push(obj.join(" "));
    });

    return polyCoords.toString();
  };

  const findSoilStyle = (str) => {
    switch (str) {
      case "bulkdensity":
        return "soilproperties_bulkdensity";
      case "cationexchangecapacity":
        return "soilproperties_cationexchangecapacity";
      case "claypercent":
        return "soilproperties_claypercent";
      case "coarsefragment":
        return "soilproperties_coarsefragment";
      case "organiccarbondensity":
        return "soilproperties_organiccarbondensity";
      case "organiccarbonstock":
        return "soilproperties_organiccarbonstock";
      case "phwater":
        return "soilproperties_phwater";
      case "sandpercent":
        return "soilproperties_sandpercent";
      case "siltpercent":
        return "soilproperties_siltpercent";
      case "soilorganiccarbon":
        return "soilproperties_soilorganiccarbon";
      default:
        return "";
    }
  };

  useEffect(() => {
    let chartObj = { ...pieChartOptions };
    chartObj.series[0].data[0].y = areaDetail.goodArea;
    chartObj.series[0].data[1].y = areaDetail.moderateArea;
    chartObj.series[0].data[2].y = areaDetail.poorArea;

    setPieChartOptions(chartObj);
  }, [areaDetail]);

  useEffect(() => {
    if (Object.keys(farmerStatistics).length) {
      let chartObj = { ...statChartOptions };
      chartObj.series[0].data[0].y = farmerStatistics.kcc[0].count;
      chartObj.series[0].data[1].y = farmerStatistics.pmk[0].count;
      chartObj.series[0].data[2].y = farmerStatistics.am[0].count;
      chartObj.series[0].data[3].y = farmerStatistics.shc[0].count;
      chartObj.series[0].data[4].y = farmerStatistics.pmfby[0].count;
      setStatChartOptions(chartObj);
    }
  }, [farmerStatistics]);

  // capitalize first letter if string in lowercase
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // capitalize first letter if string in uppercase

  const manageUpperCaseString = (str) => {
    let lower = str.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  };

  // addLulcLegend add the legend of LULC when the selected feature type is LULC

  const addLulcLegend = () => {
    removeLegends();
    let lulcData = [
      { name: "Crop Land Area", color: "#8FCE00" },
      { name: "Waste Land Area", color: "#FFF2CC" },
      { name: "Forest Area", color: "#B7E1CD" },
      { name: "Plantation Area", color: "#38761D" },
      { name: "Built up Area", color: "#F44336" },
      { name: "Water Bodies Area", color: "#006ACC" },
      { name: "Other area", color: "#E5C9D7" },
    ];

    if (lulcLegendRef.current instanceof L.Control) {
      mapRef.current.removeControl(lulcLegendRef.current);
    }
    if (soilLegendRef.current instanceof L.Control) {
      mapRef.current.removeControl(soilLegendRef.current);
    }
    if (analyticsLegndRef.current instanceof L.Control) {
      mapRef.current.removeControl(analyticsLegndRef.current);
    }

    lulcLegendRef.current = L.control({ position: "bottomleft" });

    lulcLegendRef.current.onAdd = function (map) {
      let div = L.DomUtil.create("div", "");

      for (let i = 0; i < lulcData.length; i++) {
        div.innerHTML +=
          '<div class="legend-data lulcInfo lulcLegend" style="font-size:10px;display: flex;flex-direction: row;align-items: center;">' +
          `<i class="lulcIcon" style="background: ${lulcData[i].color}"></i> ` +
          "<span>" +
          lulcData[i].name +
          "</span>" +
          "</div>";
      }

      return div;
    };

    lulcLegendRef.current.addTo(mapRef.current);
  };

  const setCommodity = () => {
    let total_area = 0;
    if (featureType == "crop-data" && acerageVal.length) {
      let str = "";
      let arr = [];

      acerageVal.forEach(function (obj, index) {
        if (selectedCommodity.indexOf(obj.fk_com_id) > -1) {
          str += "op" + obj.fk_com_id + ":1;";
          arr.push(obj.name);
          total_area += obj.value;
        } else {
          str += "op" + obj.fk_com_id + ":0;";
        }
      });

      setSelectedCommoditiesList(arr);

      let wmsObj = {
        ...wmsLayerProps,
        env: str,
      };
      if (locationFilters.taluka === null) {
        if (locationFilters.country.code == "2") {
          wmsObj["styles"] = "clip_image";
          wmsObj["env"] =
            str + "poly:MULTIPOLYGON(((" + getPolyLatLngs(owsLayerData) + ")))";
          wmsObj["layers"] =
            "crop_distribution:" +
            selectedSeason.season_name +
            "_" +
            locationFilters.country.label.split(" ").join("").toLowerCase() +
            "_" +
            locationFilters.state.label.split(" ").join("").toLowerCase();
        } else {
          if (selectedSeason.season_id == 11) {
            wmsObj["styles"] = "clip_image";
            wmsObj["env"] =
              str +
              "poly:MULTIPOLYGON(((" +
              getPolyLatLngs(owsLayerData) +
              ")))";
            wmsObj["layers"] =
              "crop_distribution:" +
              selectedSeason.season_name +
              "_" +
              locationFilters.state.label.split(" ").join("").toLowerCase();
          } else {
            // wmsObj["styles"] = "filter";
            // wmsObj["env"] = str;
            wmsObj["styles"] = "clip_image";
            wmsObj["env"] =
              str +
              "poly:MULTIPOLYGON(((" +
              getPolyLatLngs(owsLayerData) +
              ")))";
            wmsObj["layers"] =
              "crop_distribution:" +
              selectedSeason.season_name +
              "_" +
              locationFilters.state.label.split(" ").join("").toLowerCase();
          }
        }
      } else if (
        locationFilters.village === null &&
        locationFilters.taluka !== null
      ) {
        if (locationFilters.country.code == "2") {
          wmsObj["styles"] = "clip_image";

          wmsObj["env"] =
            str + "poly:MULTIPOLYGON(((" + getPolyLatLngs(owsLayerData) + ")))";
          wmsObj["layers"] =
            "crop_distribution:" +
            selectedSeason.season_name +
            "_" +
            locationFilters.country.label.split(" ").join("").toLowerCase() +
            "_" +
            locationFilters.state.label.split(" ").join("").toLowerCase();
        } else {
          wmsObj["styles"] = "clip_image";

          wmsObj["env"] =
            str + "poly:MULTIPOLYGON(((" + getPolyLatLngs(owsLayerData) + ")))";
          wmsObj["layers"] =
            "crop_distribution:" +
            selectedSeason.season_name +
            "_" +
            locationFilters.state.label.split(" ").join("").toLowerCase();
        }
      } else {
        wmsObj["styles"] = "clip_image";
        wmsObj["layers"] =
          "crop_distribution:" +
          selectedSeason.season_name +
          "_" +
          locationFilters.state.label.split(" ").join("").toLowerCase();
        wmsObj["env"] =
          str + "poly:MULTIPOLYGON(((" + getPolyLatLngs(owsLayerData) + ")))";
      }

      if (featureType === "crop-data") {
        removeLegends();
        cropLegendRef.current = L.control({ position: "bottomleft" });

        cropLegendRef.current.onAdd = function (map) {
          let div = L.DomUtil.create("div", "legend");

          acerageVal.forEach(function (obj) {
            if (selectedCommodity.indexOf(obj.fk_com_id) > -1) {
              div.innerHTML +=
                '<div style="display:flex;align-items:center;margin-bottom:4px;"><div style="width:15px;height:15px;margin-right:8px;background:' +
                legendColor[obj.fk_com_id] +
                '"></div> ' +
                obj.name +
                "</div>";
            }
          });

          return div;
        };
        cropLegendRef.current.addTo(mapRef.current);
      } else if (featureType === "lulc") {
        removeLegends();
        let lulcData = [
          { name: "Crop Land Area", color: "#8FCE00" },
          { name: "Waste Land Area", color: "#FFF2CC" },
          { name: "Forest Area", color: "#B7E1CD" },
          { name: "Plantation Area", color: "#38761D" },
          { name: "Built up Area", color: "#F44336" },
          { name: "Water Bodies Area", color: "#006ACC" },
          { name: "Other area", color: "#E5C9D7" },
        ];

        if (lulcLegendRef.current instanceof L.Control) {
          mapRef.current.removeControl(lulcLegendRef.current);
        }
        if (soilLegendRef.current instanceof L.Control) {
          mapRef.current.removeControl(soilLegendRef.current);
        }
        if (analyticsLegndRef.current instanceof L.Control) {
          mapRef.current.removeControl(analyticsLegndRef.current);
        }

        lulcLegendRef.current = L.control({ position: "bottomleft" });

        lulcLegendRef.current.onAdd = function (map) {
          let div = L.DomUtil.create("div", "");

          for (let i = 0; i < lulcData.length; i++) {
            div.innerHTML +=
              '<div class="legend-data lulcInfo lulcLegend" style="font-size:10px;display: flex;flex-direction: row;align-items: center;">' +
              `<i class="lulcIcon" style="background: ${lulcData[i].color}"></i> ` +
              "<span>" +
              lulcData[i].name +
              "</span>" +
              "</div>";
          }

          return div;
        };

        lulcLegendRef.current.addTo(mapRef.current);
      }

      getWmsLayerData(wmsObj);
      setWmsLayerProps(wmsObj);
    } else {
      let wmsObj = {
        ...wmsLayerProps,
      };
      if (locationFilters.taluka == null) {
        if (featureType === "lulc") {
          if (locationFilters.country.code == "2") {
            wmsObj["styles"] = "lulc";
            wmsObj["layers"] = `LULC:LULC_${capitalizeFirstLetter(
              locationFilters.state.label.split(" ").join("").toLowerCase()
            )}`;
            wmsObj["env"] =
              "poly:MULTIPOLYGON(((" + getPolyLatLngs(owsLayerData) + ")))";
          } else {
            wmsObj["styles"] = "lulc";
            wmsObj["layers"] = `LULC:LULC_${capitalizeFirstLetter(
              locationFilters.state.label.split(" ").join("").toLowerCase()
            )}`;
            wmsObj["env"] =
            "poly:MULTIPOLYGON(((" + getPolyLatLngs(owsLayerData) + ")))";
          }
          addLulcLegend();
          getWmsLayerData(wmsObj);
        } else if (featureType === "soil-data") {
          if (locationFilters.country.code == "2") {
            wmsObj["layers"] = `soilproperties:${locationFilters.country.label
              .split(" ")
              .join("")
              .toLowerCase()}_${locationFilters.state.label
              .split(" ")
              .join("")
              .toLowerCase()}_${selectedSoilNutrient}`;
            wmsObj["styles"] = findSoilStyle(selectedSoilNutrient);
          } else {
            wmsObj["layers"] = `soilproperties:${locationFilters.country.label
              .split(" ")
              .join("")
              .toLowerCase()}_${selectedSoilNutrient}`;
            wmsObj["styles"] = findSoilStyle(selectedSoilNutrient);
            wmsObj["env"] =
              "poly:MULTIPOLYGON(((" + getPolyLatLngs(owsLayerData) + ")))";
            getWmsLayerData(wmsObj);
            // getSoilDataLegendsVal()
          }

          wmsObj["env"] =
            "poly:MULTIPOLYGON(((" + getPolyLatLngs(owsLayerData) + ")))";
        }
      } else if (
        locationFilters.village == null &&
        locationFilters.taluka != null
      ) {
        if (featureType === "soil-data") {
          wmsObj["styles"] = findSoilStyle(selectedSoilNutrient);
          if (locationFilters.country.code == "2") {
            wmsObj["layers"] = `soilproperties:${locationFilters.country.label
              .split(" ")
              .join("")
              .toLowerCase()}_${locationFilters.state.label
              .split(" ")
              .join("")
              .toLowerCase()}_${selectedSoilNutrient}`;
            // getWmsLayerData(wmsObj);
          } else {
            wmsObj["layers"] = `soilproperties:${locationFilters.country.label
              .split(" ")
              .join("")
              .toLowerCase()}_${selectedSoilNutrient}`;
          }

          wmsObj["env"] =
            "poly:MULTIPOLYGON(((" + getPolyLatLngs(owsLayerData) + ")))";
          getWmsLayerData(wmsObj);
        } else if (featureType === "lulc") {
          wmsObj["styles"] = "lulc";
          if (locationFilters.country.code == "2") {
            wmsObj["layers"] = `LULC:LULC_${capitalizeFirstLetter(
              locationFilters.state.label.split(" ").join("").toLowerCase()
            )}`;
          } else {
            wmsObj["layers"] = `LULC:LULC_${capitalizeFirstLetter(
              locationFilters.state.label.split(" ").join("").toLowerCase()
            )}`;
            wmsObj["env"] =
              "poly:MULTIPOLYGON(((" + getPolyLatLngs(owsLayerData) + ")))";
          }
          addLulcLegend();
          getWmsLayerData(wmsObj);
        }
      } else {
        if (featureType === "lulc") {
          wmsObj["styles"] = "lulc";
          wmsObj["layers"] = `LULC:LULC_${capitalizeFirstLetter(
            locationFilters.state.label.split(" ").join("").toLowerCase()
          )}`;
          wmsObj["env"] =
            "poly:MULTIPOLYGON(((" + getPolyLatLngs(owsLayerData) + ")))";
          addLulcLegend();
          getWmsLayerData(wmsObj);
        } else if (featureType === "soil-data") {
          if (locationFilters.country.code == "2") {
            wmsObj["layers"] = `soilproperties:${locationFilters.country.label
              .split(" ")
              .join("")
              .toLowerCase()}_${locationFilters.state.label
              .split(" ")
              .join("")
              .toLowerCase()}_${selectedSoilNutrient}`;
          } else {
            wmsObj["layers"] = `soilproperties:${locationFilters.country.label
              .split(" ")
              .join("")
              .toLowerCase()}_${selectedSoilNutrient}`;
          }
          wmsObj["styles"] = findSoilStyle(selectedSoilNutrient);

          wmsObj["env"] =
            "poly:MULTIPOLYGON(((" + getPolyLatLngs(owsLayerData) + ")))";
          getWmsLayerData(wmsObj);
        }
      }
      // getWmsLayerData(wmsObj);
      setWmsLayerProps(wmsObj);
    }
  };

  useEffect(() => {
    if (
      featureType == "crop-data" ||
      featureType == "soil-data" ||
      featureType == "lulc"
    ) {
      setCommodity();
    }
  }, [JSON.stringify(selectedCommodity)]);

  useEffect(() => {
    if (
      featureType == "crop-data" ||
      featureType == "soil-data" ||
      featureType == "lulc"
    ) {
      setCommodity();
    }
  }, [JSON.stringify(owsLayerData)]);
  const [areaData, setAreaData] = useState({});

  // getAreaData calls CROPHEALTH ans set data for PIE CHART
  const getAreaData = async (season) => {
    setCropHealthLoader(true);
    let params;
    if (locationFilters.country.code == "2") {
      params = {
        country: locationFilters.country?.label,
        state: locationFilters.state?.label,
        district: locationFilters.city?.label,
        required_seasons: season !== "" ? season : "latest",
        latlong: getIndexLatLngs(owsLayerData),
      };
    } else {
      params = {
        country: locationFilters.country?.label,
        state: locationFilters.state?.label,
        district: locationFilters.city?.label,
        required_seasons: season !== "" ? season : "latest",
        latlong: getIndexLatLngs(owsLayerData),
      };
    }
    const res = await postData("indexhost", "cropHealth", params, null);
    if (res.data?.data == null) {
      setCropHealthLoader(false);
      setAreaData(res.data);
    } else {
      setCropHealthLoader(false);
      setAreaData(res.data);
      setAreaDetail({
        ...areaDetail,
        goodArea: res.data.data?.good,
        moderateArea: res.data.data?.average,
        poorArea: res.data.data?.poor,
      });
    }
    setCropHealthLoader(false);
  };

  const drawSquare = (e, cent, properties, sideInMeters) => {
    let center = [e.latlng.lat, e.latlng.lng];
    let circle = L.circle(center, sideInMeters / 2).addTo(mapRef.current);
    let bounds = circle.getBounds();
    mapRef.current.removeLayer(circle); //hide circle
    let rect = L.rectangle(bounds, properties).addTo(mapRef.current);

    let latLngs = rect.getLatLngs()[0];
    let indexCoords = [];
    latLngs.forEach((obj) => {
      let ele = "(" + obj.lng + "," + obj.lat + ")";
      indexCoords.push(ele);
    });

    return "[" + indexCoords + "]";
  };

  const getFeaturedIndexData = (e) => {
    var center = [e.latlng.lat, e.latlng.lng];
    let sideInMeters = 250;

    let obj = {
      latlongpolygon: drawSquare(
        e,
        center,
        { color: "transparent", weight: 1 },
        sideInMeters
      ),
    };

    if (featureType == "farmer-advisory") {
      if (farmAdvisoryRef.current == "soil-texture") {
        soiltext(
          locationFilters.state.label.split(" ").join("").toLowerCase(),
          locationFilters.city.label.split(" ").join("").toLowerCase(),
          obj.latlongpolygon
        );
      } else {
        npks(
          locationFilters.state.label.split(" ").join("").toLowerCase(),
          locationFilters.city.label.split(" ").join("").toLowerCase(),
          obj.latlongpolygon
        );
      }
    }
  };

  // getFarmerScoreCard_Farms GENERATE SCORECARD FOR GEOTAGGED FARMS
  const getFarmerScoreCard_Farms = async (
    arg,
    state,
    district,
    village_code,
    khasra,
    landarea,
    latlngs
  ) => {
    setIsScoreCardOpen(true);
    setScorecardLoader({
      ...scorecardLoader,
      basicDetail: true,
      farmLandDetail: true,
      riskProfileDetail: true,
    });
    let data = {
      data: arg,
      state: state,
      district: district,
      vcode: village_code,
      khasraNo: khasra,
      farmarea: landarea,
      latlongpolygon: getIndexLatLngs_Farms(latlngs),
    };
    let res = await postData("score", "scorecard_farms", data, null);

    if (arg == 1) {
      setFarmersScore({});
      setFarmersScore(res.data.data.basic_details);
      setScorecardLoader({ ...scorecardLoader, basicDetail: false });
    } else if (arg == 2) {
      setFarmLandScore({});
      setFarmLandScore(res.data.data);
      setScorecardLoader({ ...scorecardLoader, farmLandDetail: false });
    } else if (arg == 3) {
      setProfitLossScore({});
      setProfitLossScore(res.data.data);
      setScorecardLoader({ ...scorecardLoader, riskProfileDetail: false });
    } else if (arg == 4) {
      setRiskProfileScore({});
      setRiskProfileScore(res.data.data);
    } else {
      setWeatherTrend({});
      setWeatherTrend(res.data.data);
    }
    // by id
    if (arg == 1) {
      if (res.data.data.basic_details?.length > 0) {
        setFarmerId(res.data.data.basic_details[0].ref_id);
        setSelectedFarmer(res.data.data.basic_details[0]);
      }
    }
    setFarmerLoaderIcon(false);
    setMapLoader(false);
  };

  // KhasraScreenShort CAPTURE SCREENSHORT OF THE KHASRA BOUNDARY FOR FARMER SCORECARD

  const [streetMapImg, setstreetMapImg] = useState({});
  const KhasraScreenShort = async (lats) => {
    let params = {
      latlong:
        featureType == "farms"
          ? getIndexLatLngs_Farms(lats)
          : getIndexLatLngs(owsLayerDataRef.current),
      style: "open-street-map,satellite",
    };
    const res = await postData("indexhost", "screencapture", params, null);

    if (res.data.data) {
      setstreetMapImg(res.data.data);
    }
  };

  const getFarmerScoreCard = async (arg) => {
    setIsScoreCardOpen(true);
    setScorecardLoader({
      ...scorecardLoader,
      basicDetail: true,
      farmLandDetail: true,
      riskProfileDetail: true,
    });
    let data = {
      khasraNo: locationFilters.khasra.label,
      vcode: locationFilters.village.code,
      state: locationFilters.state.label.toUpperCase(),
      district: locationFilters.city.label,
      data: arg,
      latlongpolygon: getIndexLatLngs(owsLayerDataRef.current),
    };
    let res = await postData("score", "scorecard", data, null);

    if (arg == 1) {
      setFarmersScore({});
      setFarmersScore(res.data.data.basic_details);
      setScorecardLoader({ ...scorecardLoader, basicDetail: false });
    } else if (arg == 2) {
      setFarmLandScore({});
      setFarmLandScore(res.data.data);
      setScorecardLoader({ ...scorecardLoader, farmLandDetail: false });
    } else if (arg == 3) {
      setProfitLossScore({});
      setProfitLossScore(res.data.data);
      setScorecardLoader({ ...scorecardLoader, riskProfileDetail: false });
    } else if (arg == 4) {
      setRiskProfileScore({});
      setRiskProfileScore(res.data.data);
    } else {
      setWeatherTrend({});
      setWeatherTrend(res.data.data);
    }

    if (arg == 1) {
      if (res.data.data.basic_details?.length > 0) {
        setFarmerId(res.data.data.basic_details[0].ref_id);
        setSelectedFarmer(res.data.data.basic_details[0]);
      }
    }

    setFarmerLoaderIcon(false);
    setMapLoader(false);
  };

  // getFarmerRecords GENERATE A REPORT ON CLICK WHEN FEATURE TYPE IS cadastral-data...-----------------------
  const getFarmerRecords = async (khasra_num, village_co) => {
    const res = await axios({
      method: "GET",
      url: `${
        appconfig.domain[appconfig.environment].agribhumi
      }admin/webp/get.php?action=farmerDetails&khasra_no=${khasra_num}&village_code=${village_co}`,
    });
    const res2 = await axios({
      method: "GET",
      url: `${
        appconfig.domain[appconfig.environment].agribhumi
      }admin/webp/get.php?action=farmerRevenueDetails&khasra_no=${khasra_num}&village_code=${village_co}`,
    });

    let obj = {
      ...farmerRecords,
      recordsEng: res.data.data,
      recordsHindi: res2.data.data,
    };
    setFarmerRecords(obj);
    setFarmerRecordsDialog(true);
  };

  const loadFeatureLayer = (geojson) => {
    return L.geoJSON(geojson, {
      style: function () {
        return {
          fillColor: "transparent",
          weight: 3,
          opacity: 1,
          color: "#001840",
          fillOpacity: 1,
        };
      },
      pointToLayer: function (feature, latlng) {},
      onEachFeature: function (feature, layer) {
        if (locationFilters.khasra !== null) {
          layer
            .bindTooltip(layer.feature.properties.KHASRA, {
              className: "label",
              permanent: true,
              direction: "center",
            })
            .openTooltip();
        }

        layer.on({
          click: function (e) {
            if (featureType == "cadastral-data") {
              getFarmerRecords(
                layer.feature.properties.KHASRA,
                layer.feature.properties.VILLAGE_CO
              );
            }
            if (featureType == "crop-history") {
              getCropHistory(e.target?.feature?.geometry?.coordinates?.[0][0]);
            }
            if (
              featureType == "farmer-scorecard" &&
              locationFilters.khasra !== null
            ) {
              if (locationFilters.country.code == "2") {
                getFarmerScoreCard_Farms(2, farmArea);
                getFarmerScoreCard_Farms(3, farmArea);
              } else {
                getFarmerScoreCard(1);
                getFarmerScoreCard(2);
                getFarmerScoreCard(3);
                getFarmerScoreCard(4);
                getFarmerScoreCard(5);
                KhasraScreenShort();
              }
            }
            if (
              featureType == "farmer-advisory" &&
              advisoryModalRef.current == true
            ) {
              getFeaturedIndexData(e);
              setIsModalOpen(true);
            }
            if (featureType == "farm-report") {
              getFarmerReportData(
                e.target?.feature?.geometry?.coordinates?.[0][0]
              );
            }
            if (featureType == "analytics") {
              if (selectedviRef.current == "NDVI") {
                // setCropTransModal(true);
              }
              var center = [e.latlng.lat, e.latlng.lng];

              let sideInMeters = 20;
              if (
                startdateRef.current != "" &&
                enddateRef.current != "" &&
                selectedviRef.current != ""
                // healthIndexDataRef.current != []
              ) {
                getViLatlongData(
                  drawSquare(
                    e,
                    center,
                    { color: "transparent", weight: 1 },
                    sideInMeters
                  )
                );
              }
            }
          },
          mouseover: function (e) {},
          mouseout: function (e) {},
        });
      },
    });
  };

  // getFarmerReportData_Farms GENERATE FARM REPORT FOR GEOTAGGED FARMS...-----------------------------
  const getFarmerReportData_Farms = async (state, dist, latlngs) => {
    if (farmerReportData != {} && farmerReportData.error !== false) {
      setFarmerReportDialog(true);

      if (latlngs.length) {
        let params = {
          country: locationFilters.country.label,
          state: state.split(" ").join("").toLowerCase(),
          district: dist.split(" ").join("").toLowerCase(),
          required_seasons: "all",
          latlong: getIndexLatLngs_Farms(latlngs),
        };
        const res = await postData("indexhost", "crophistry", params, null);
        setFarmReportCropHistory(res.data?.data);
      }
    }
    let npkparam = {
      state: state,
      district: dist,
      latlongpolygon: getIndexLatLngs_Farms(latlngs),
    };
    const npkres = await postData("indexhost", "npk", npkparam, null);
    setFarmNpk(npkres.data != null ? npkres.data.data : npkres.data);

    if (npkres.data != null) {
      let params = {
        n: npkres.data.data.nitrogen,
        p: npkres.data.data.phosphorus,
        k: npkres.data.data.potassium,
      };
      const recomndCrop = await postData(
        "indexhost",
        "recomndCrop",
        params,
        null
      );
      setFarmReportRecomndCrop(recomndCrop.data);
    }
    let paramsforsand = {
      state: state.split(" ").join("").toLowerCase(),
      latlongpolygon: getIndexLatLngs_Farms(latlngs),
    };

    const recomndCrop = await postData(
      "indexhost",
      "getClaySand",
      paramsforsand,
      null
    );

    if (recomndCrop.data.data) {
      let params = {
        sand: getClaySand.data.data.sand,
        clay: getClaySand.data.data.clay,
        silt: getClaySand.data.data.silt,
      };
      const soiltextdata = await postData(
        "indexhost",
        "getSoilText",
        params,
        null
      );
      setFarmReportsoilText(soiltextdata.data);
    }
    let cropParams = {
      country: locationFilters.country.label,
      state: locationFilters.state.label,
      district: locationFilters.taluka.label,
      required_seasons: "2023_kharif",
      latlong: getIndexLatLngs_Farms(latlngs),
    };
    const farmCropHealth = await postData(
      "indexhost",
      "cropHealth",
      cropParams,
      null
    );
    setFarmCropHealth(farmCropHealth.data.data);
  };

  // getFarmerReportData GENERATE FARM REPORT....-------------------------------------------->>>>>>>
  const getFarmerReportData = async (layer) => {
    setMapLoader(true);
    let formData = {};
    if (featureType != "farms") {
      formData = {
        khasraNo: locationFilters.khasra.label.toString(),
        vcode: locationFilters.village.code.toString(),
        seasons_id: selectedSeason.season_id,
        state: locationFilters.state.label.split(" ").join("").toLowerCase(),
        district: locationFilters.city.label.split(" ").join("").toLowerCase(),
        latlongpolygon: getIndexLatLngs(owsLayerDataRef.current),
      };
      const res = await postData("agribhumi2", "farmerReport", formData, null);
      setFarmerReportData(res.data?.data);
    }
    setMapLoader(false);
    if (farmerReportData != {} && farmerReportData.error !== false) {
      setMapLoader(false);
      setFarmerReportDialog(true);

      let params = {
        country: locationFilters.country.label,
        state: locationFilters.state.label,
        district: locationFilters.city.label,
        required_seasons: "all",
        latlong: getIndexLatLngsCropHistory(layer),
      };
      const res = await postData("indexhost", "crophistry", params, null);

      setFarmReportCropHistory(res.data?.data);

      let npkparam = {
        latlongpolygon: getIndexLatLngs(owsLayerDataRef.current),
        state: locationFilters.state.label.split(" ").join("").toLowerCase(),
        district: locationFilters.city.label.toLowerCase(),
      };
      const npkres = await postData("indexhost", "npk", npkparam, null);
      setFarmNpk(npkres.data != null ? npkres.data.data : npkres.data);

      if (npkres.data != null) {
        let params = {
          n: npkres.data.data.nitrogen,
          p: npkres.data.data.phosphorus,
          k: npkres.data.data.potassium,
        };
        const recomndCrop = await postData(
          "indexhost",
          "recomndCrop",
          params,
          null
        );
        setFarmReportRecomndCrop(recomndCrop.data);
      }

      let paramsforsand = {
        state: locationFilters.state.label.split(" ").join("").toLowerCase(),
        latlongpolygon: getIndexLatLngs(owsLayerDataRef.current),
      };
      const recomndCrop = await postData(
        "indexhost",
        "getClaySand",
        paramsforsand,
        null
      );
      if (recomndCrop.data.data) {
        let params = {
          sand: recomndCrop.data.data.sand,
          clay: recomndCrop.data.data.clay,
          silt: recomndCrop.data.data.silt,
        };
        const soiltextdata = await postData(
          "indexhost",
          "getSoilText",
          params,
          null
        );
        setFarmReportsoilText(soiltextdata.data);
      }

      let cropParams = {
        country: locationFilters.country.label,
        state: locationFilters.state.label,
        district: locationFilters.taluka.label,
        required_seasons: "2023_kharif",
        latlong: getIndexLatLngs(owsLayerDataRef.current),
      };
      const farmCropHealth = await postData(
        "indexhost",
        "cropHealth",
        cropParams,
        null
      );
      setFarmCropHealth(farmCropHealth.data.data);
    } else {
      warningMessage("No data Avalaibale for the selected Region.");
    }
  };

  // getCropHistory GENERATE A REPORT WHEN CLICK ON LAYER AND FEATURE IS CROP-HISTORY...----------->>>>>
  const getCropHistory = async (layer) => {
    setCropHistoryLoader(true);
    let params = {
      country: locationFilters.country.label,
      state: locationFilters.state.label,
      district: locationFilters.city.label,
      required_seasons: "all",
      latlong: getIndexLatLngsCropHistory(layer),
    };

    const res = await postData("indexhost", "crophistry", params, null);

    setCropHistory(res.data?.data);
    setCropHistoryLoader(false);
    setCropHistoryDialog(true);
  };

  useEffect(() => {
    if (
      featureType != "analytics" &&
      Object.keys(owsLayerData).length &&
      rasterLayer
    ) {
      if (mapRef?.current?.hasLayer(rasterLayer)) {
        mapRef.current.removeLayer(rasterLayer);
      }
    }
  }, [featureType]);

  // handleSearch RUNS WHEN WE CLICK ON SEARCH BUTTON...---------------------->>>>>>>>>>>>>>>>>
  const handleSearch = () => {
    // setMapLoader(true);
    if(featureType !== "analytics"){
      setLeftOutlinedButton(true)
    }
    
    dispatch(getWeatherDetails(locationFilters));
    if (
      featureType != "crop-data" &&
      Object.keys(owsLayerData).length &&
      rasterLayer
    ) {
      if (mapRef?.current?.hasLayer(rasterLayer)) {
        mapRef.current.removeLayer(rasterLayer);
      }
    }

    setShowViGraph(false);
    if (featureType == "farmer-advisory" || featureType == "analytics") {
      setSearchState(true);
    }

    if (locationFilters.taluka !== null && locationFilters.village !== null) {
      getFarms(locationFilters.village?.code);
    } else if (
      locationFilters.taluka !== null &&
      locationFilters.village == null
    ) {
      getFarms(locationFilters.taluka?.code);
    } else if (
      locationFilters.taluka !== null &&
      locationFilters.village !== null &&
      locationFilters.khasra !== null
    ) {
      getFarms(locationFilters.khasra?.code);
    }

    for (let i = 0; i < markerLayer.length; i++) {
      if (mapRef.current.hasLayer(markerLayer[i])) {
        mapRef.current.removeLayer(markerLayer[i]);
      }
    }
    if (workspaceLegendRef.current instanceof L.Control) {
      mapRef.current.removeControl(workspaceLegendRef.current);
    }
    if (cropLegendRef.current instanceof L.Control) {
      mapRef.current.removeControl(cropLegendRef.current);
    }
    setModalMenu(true);
    getSeasons();
  };

  useEffect(() => {
    if (locationFilters.city !== null) {
      initiateOws(seasonsList[0]);
    }
  }, [selectedSoilNutrient]);

  useEffect(() => {
    if (selectedSeason != "" && featureType == "crop-data") {
    }
  }, [selectedSeason]);

  const getIndexLatLngs = (object) => {
    let latlngs = [],
      indexCoords = [];
    latlngs = object?.features[0]?.geometry?.coordinates[0][0];
    latlngs?.forEach(function (obj) {
      let ele = "(" + obj[0] + "," + obj[1] + ")";
      indexCoords.push(ele);
    });
    return "[" + indexCoords + "]";
  };
  const getIndexLatLngsCropHistory = (object) => {
    let latlngs = [],
      indexCoords = [];
    latlngs = object;
    latlngs?.forEach(function (obj) {
      let ele = "(" + obj[0] + "," + obj[1] + ")";
      indexCoords.push(ele);
    });
    return "[" + indexCoords + "]";
  };

  const getIndexLatLngs_Farms = (latlngs) => {
    let indexCoords = [];

    latlngs.forEach(function (obj) {
      let ele = "(" + obj["longitude"] + "," + obj["latitude"] + ")";
      indexCoords.push(ele);
    });
    return "[" + indexCoords + "]";
  };

  const CropHistory = async (season) => {
    setMapLoader(true);
    setAcerageValLoader(true);
    let data = {};
    if (locationFilters.country.code == "2") {
      data = {
        country: locationFilters.country.label,
        state: locationFilters.state.label,
        district: locationFilters.city.label,
        required_seasons: season !== "" ? season : "latest",
        latlong: getIndexLatLngs(owsLayerData),
      };
    } else {
      data = {
        country: locationFilters.country?.label,
        state: locationFilters.state?.label,
        district: locationFilters.city?.label,
        required_seasons: season,
        latlong: getIndexLatLngs(owsLayerData),
      };
    }

    try {
      let res = await postData("indexhost", "crophistry", data, null);
      if (res.data.data == null) {
        setMapLoader(false);
        removeLegends();
        warningMessage(res.data.message);
      } else {
        setMapLoader(false);
        setAcerageVal(res.data.data[Object.keys(res.data.data)[0]]);
        let commArr = [];
        let commList = [];

        res.data.data[Object.keys(res.data.data)[0]].forEach((item) => {
          commArr.push(item.fk_com_id);
          commList.push(item.name);
        });

        validateWmsProps(
          selectedSeason.season_name +
            "_" +
            locationFilters.state.label.split(" ").join("").toLowerCase()
        );

        setAcerageValLoader(false);
        setSelectedCommodity(commArr);
        setSelectedCommoditiesList(commList);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // setMapLoader(false);
    }
  };
  const [yeildLoadder, setYeildLoadder] = useState(false);
  const cropYield = async (season) => {
    setYeildLoadder(true);
    let data = {};
    if (locationFilters.country.code == "2") {
      data = {
        country: locationFilters.country.label,
        state: locationFilters.state.label,
        district: locationFilters.city.label,
        required_seasons: season !== "" ? season : "latest",
        latlong: getIndexLatLngs(owsLayerData),
      };
    } else {
      data = {
        country: locationFilters.country?.label,
        state: locationFilters.state?.label,
        district: locationFilters.city?.label,
        required_seasons: season,
        latlong: getIndexLatLngs(owsLayerData),
      };
    }
    try {
      let yeildData = await postData("indexhost", "cropYield", data, null);
      console.log("yeildData--------------------", yeildData);

      if (yeildData.data.data !== null) {
        let crop = [];

        yeildData.data.data[Object.keys(yeildData.data.data)[0]].forEach(
          (item) => {
            console.log("item----------", item);

            crop.push(item);
          }
        );
        setYeildInfo(crop);
      } else {
        setYeildInfo(yeildData.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setYeildLoadder(false); // Deactivate loader regardless of success or failure
    }
    console.log("crop----------", crop);
  };

  useEffect(() => {
    let totalArea = 0;
    acerageVal.forEach(function (obj, index) {
      if (selectedCommodity.indexOf(obj.fk_com_id) > -1) {
        totalArea += obj.value;
      }
    });

    setAcerageValDetail({
      ...acerageValDetail,
      totalArea: totalArea,
    });
  }, [selectedCommodity]);

  useEffect(() => {
    if (Object.keys(owsLayerData).length && featureType == "crop-data") {
      if(selectedSeason){
        CropHistory(selectedSeason?.season_name);
        cropYield(selectedSeason?.season_name);
        getAreaData(selectedSeason?.season_name);
      }
      else{
        CropHistory("latest");
        cropYield("latest");
        getAreaData("latest");
      }
     
      // getCropHistoryYieldAreaData()
    }
  }, [JSON.stringify(owsLayerData)]);

  const getViHistogram = async (layerName) => {
    setMapLoader(true);

    let wmsObj = { ...wmsLayerProps };

    let data = {
      latlongpolygon: getIndexLatLngs(owsLayerDataRef.current),
      layer_name: layerName,
      district: locationFilters.city.label,
    };

    let res = await postData("indexhost", "vihistogram", data, null);

    if (!res.data.error) {
      wmsObj["layers"] = res.data.data.layer;
      wmsObj["styles"] = selectedVI;
      wmsObj["env"] =
        "poly:MULTIPOLYGON(((" +
        getPolyLatLngs(owsLayerDataRef.current) +
        ")))";

      getWmsLayerData(wmsObj);
    } else {
      warningMessage(res.data.message);
    }

    setMapLoader(false);
  };

  const getLayerNames = async (viObj) => {
    setMapLoader(true);
    let params = {};
    let wmsObj = { ...wmsLayerProps };
    if (locationFilters.country.code == "2") {
      params = {
        date: viObj?.date,
        state: locationFilters.country.label.split(" ").join("").toLowerCase(),
        city: locationFilters.state.label.split(" ").join("").toLowerCase(),
        resourceType: selectedviRef.current,
      };
    } else {
      if (
        locationFilters.state.label == "Guna" ||
        locationFilters.state.label == "Kota" ||
        locationFilters.state.label == "Mathura"
      ) {
      } else {
      }
      params = {
        date: viObj?.date,
        state: locationFilters.state.label.split(" ").join("").toLowerCase(),
        city: locationFilters.city.label,
        resourceType: selectedviRef.current,
      };
    }
    const res = await getData("enterprise", "layersName", params, null);
    if (!res.data.error) {
      wmsObj["layers"] = res.data.data.layer;
      wmsObj["styles"] = selectedVI;
      wmsObj["env"] =
        "poly:MULTIPOLYGON(((" +
        getPolyLatLngs(owsLayerDataRef.current) +
        ")))";

      getWmsLayerData(wmsObj);
    } else {
      warningMessage(res.data.message);
    }
    setMapLoader(false);
  };

  // getViLatlongData GENERATE A STATICTICS CHART ON ANALYTICS FEATURE........------------->>>>>>>>>>>

  const scrollRightGraphRef = useRef(null);

  const scrollHealthIndexHorizontally = (scrollOffset) => {
    scrollRightGraphRef.current.scrollLeft += scrollOffset;
  };
  const getViLatlongData = async (latlng) => {
    setMapLoader(true);
    let data = {};
    if (locationFilters.country.code == "2") {
      data = {
        latlongpolygon: latlng == null ? getIndexLatLngs(owsLayerData) : latlng,
        start_date: startdateRef.current,
        end_date: enddateRef.current,
        index: selectedviRef.current,
        layer: `${locationFilters.country.label
          .split(" ")
          .join("")}_${locationFilters.state.label
          .split(" ")
          .join("_")
          .toUpperCase()}`,
      };
    } else {
      data = {
        latlongpolygon: latlng == null ? getIndexLatLngs(owsLayerData) : latlng,
        start_date: startdateRef.current,
        end_date: enddateRef.current,
        index: selectedviRef.current,
        layer: `${locationFilters.country.label
          .split(" ")
          .join("")}_${locationFilters.state.label
          .split(" ")
          .join("_")}_${locationFilters.city.label.split(" ").join("")}`,
      };
    }

    let graphObj = { ...viChartOptions };
    let graphObj2 = { ...viChartOptions2 };

    if (selectedVI == "LAI") {
      graphObj2.yAxis.max = 4;
    } else {
      graphObj2.yAxis.max = 100;
    }

    let res = await postData("indexhost", "vitallatlongs", data, null);
    // setHealthIndexData(res.data.data);

    if (!res.data.error && res.data.data.length) {
      setHealthIndexData(res.data.data);

      setViLatlongData(res.data.data);
      getLayerNames(res.data.data[0]);
      graphObj.series[0].data = [];
      graphObj2.series[0].data = [];
      graphObj2.series[1].data = [];

      res.data.data.forEach((item) => {
        let arr = [];
        let arrExpected = [];
        arr.push(new Date(item.date).getTime());
        arrExpected.push(new Date(item.date).getTime());
        if (selectedVI == "LAI" || selectedVI == "SM") {
          arr.push(item.mean);
          arrExpected.push(item.expectedMean);
        } else {
          arr.push(item.mean * 100);
          arrExpected.push(item.expectedMean * 100);
        }

        graphObj.series[0].data.push(arr);
        graphObj2.series[0].data.push(arr);
        graphObj2.series[1].data.push(arrExpected);
      });
      setViChartOptions(graphObj);
      setViChartOptions2(graphObj2);
      setShowFloatButton(false);

      api.open({
        key: "updatable",
        message: "",
        description: (
          <div>
            {/* {selectedviRef.current == "NDVI" && (
              <div className="healthIndex">
                <p className="healthIndexHead">Health Index</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LeftCircleOutlined
                    onClick={() => scrollHealthIndexHorizontally(-90)}
                    style={{
                      color: "#121212",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  />
                  <div
                    ref={scrollRightGraphRef}
                    style={{
                      display: "flex",
                      height: "10%",
                      overflow: "scroll",
                    }}
                  >
                    {res.data.data?.length &&
                      res.data.data?.map((item) => {
                        return (
                          <div
                            style={{
                              width: "10%",
                              paddingRight: "4px",
                              width: "-webkit-fill-available",
                              borderRight: "1px solid #121212",
                              borderBottom: "1px solid #121212",
                            }}
                          >
                            <div
                              style={{
                                borderBottom: "1px solid black",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "15px",
                                  width: "max-content",
                                  paddingRight: "4px",
                                  paddingLeft: "4px",
                                }}
                              >
                                {" "}
                                {moment(item?.date).format("MMM Do, YYYY")}
                              </p>
                            </div>
                            <p
                              style={{
                                fontSize: "15px",
                              }}
                            >
                              {parseFloat(item?.mean * 100).toFixed(2)}
                             
                            </p>
                          </div>
                        );
                      })}
                  </div>
                  <RightCircleOutlined
                    onClick={() => scrollHealthIndexHorizontally(90)}
                    style={{
                      color: "#121212",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )} */}

            <div style={{ textAlign: "center" }}>
              <p style={{ fontWeight: 600, fontSize: "16px" }}>
                {DropDownDataAnyaltics[selectedVI]}
              </p>
            </div>
            <HighchartsReact highcharts={Highcharts} options={graphObj2} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: "#7BB4EC",
                  }}
                ></div>
                <p style={{ marginLeft: "5px", marginBottom: "0px" }}>Actual</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: "#434348",
                  }}
                ></div>
                <p style={{ marginLeft: "5px", marginBottom: "0px" }}>Ideal</p>
              </div>
            </div>
          </div>
        ),
        duration: 0,
        placement: "bottomRight",
        bottom: 30,

        onClose: () => {
          setShowFloatButton(true);
        },
      });
      setShowViGraph(true);
    } else {
      setShowFloatButton(true);

      setMapLoader(false);
      warningMessage(res.data.message);
    }
    setMapLoader(false);
  };

  useEffect(() => {
    if (selectedVI !== "Crop Classification") {
      getViLatlongData(null);
    }
  }, [selectedVI]);

  const scrollRightRef = useRef(null);

  const scrollHorizontally = (scrollOffset) => {
    scrollRightRef.current.scrollLeft += scrollOffset;
  };

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  // TO CLOSE THE FARMER ADVISORY MODAL
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsCropModelOpen(false);
    setSand("");
    setClay("");
    setSilt("");
    setPotassium("");
    setPhosphorus("");
    setNitrogen("");
  };

  // closeTransModal FOR CLOSE Crop Transition Modal for analytics

  const closeTransModal = () => {
    setCropTransModal(false);
  };
  const openTransModal = () => {
    setCropTransModal(true);
  };
  // npkAdvisory TO GET NITROGEN,phosphous,potasium VALUES FOR FARMER ADVISORY
  const npkAdvisory = async (nitrgen, phosphous, potasium) => {
    setRecomndLoader(true);
    let data = {
      n: parseInt(nitrgen),
      p: parseInt(phosphous),
      k: parseInt(potasium),
    };

    let res = await postData("indexhost", "npkadvisry", data, null);
    setSelectedCrop(res.data);
    setRecomndLoader(false);
  };

  // soilTextreAdvisory TO GET SOIL,CLY,SLT VALUES FOR FARMER ADVISORY

  const soilTextreAdvisory = async (soil, cly, slt) => {
    setRecomndLoader(true);
    let data = {
      sand: parseInt(soil),
      clay: parseInt(cly),
      silt: parseInt(slt),
    };
    const res = await postData("indexhost", "soiltextadvisory", data, null);

    setSoilTexture(res.data.data.recommended_crops);
    setRecomndLoader(false);
  };

  // fertilizerCrops TO GET FERTOLIZED CROPS FOR FARMER ADVISORY

  const fertilizerCrops = async (getseason) => {
    let res = await getData("agribhumi2", "fertilizercrop", null, null);
    setFertilizerRecomn(res.data.data);
  };

  // fertilizerRecomndCrops TO GET RECOMENDED CROPS AFTER TAKING THE VALUES OF nitrgen, phosphous, potasium AND SELECTED CROP FOR FARMER ADVISORY
  const fertilizerRecomndCrops = async (nitrgen, phosphous, potasium, crp) => {
    setRecomndLoader(true);
    let formData = {
      n: parseInt(nitrgen),
      p: parseInt(phosphous),
      k: parseInt(potasium),
      crop: crp,
    };
    const res = await postData(
      "indexhost",
      "fertilizerrecomendation",
      formData,
      null
    );

    if (Object.keys(res.data.data).length > 0) {
      setFertiliedrecommenedCrop(res.data.data);

      let values = [];
      for (var key in res.data.data) {
        if (res.data.data.hasOwnProperty(key)) {
          values.push(res.data.data[key]);
          setFertilizedValues(values);
        }
      }
    } else {
      warningMessage("No data Avalaibale for the selected Region.");
    }
    setRecomndLoader(false);
  };
  const showCropModal = () => {
    setIsCropModelOpen(true);
    if (featureType == "farmer-advisory" && farmAdvisory == "crop-selection") {
      npkAdvisory(nitrogen, phosphorus, potassium);
    } else if (
      featureType == "farmer-advisory" &&
      farmAdvisory == "soil-texture"
    ) {
      soilTextreAdvisory(sand, clay, silt);
    } else {
      fertilizerRecomndCrops(nitrogen, phosphorus, potassium, seasons);
    }
  };

  const selectAdvisory = (val) => {
    setFarmAdvisory(val);

    if (val == "fertilizer") {
      fertilizerCrops(val);
    }
  };

  const goBack = () => {
    setIsCropModelOpen(false);
  };
  const getNpkValues = () => {
    setAdvisoryModal(true);
    setIsModalOpen(false);
  };

  // soiltext FUNCTION GIVES THE VALUES OF SAND,CLAY AND SILT FOR FARMER ADVISORY
  const soiltext = async (stat, dist, polygn) => {
    setMapLoader(true);
    let formData = {};
    if (locationFilters.country.code == "2") {
      formData = {
        country: "nigeria",
        latlongpolygon: polygn,
      };
    } else {
      formData = {
        latlongpolygon: polygn,
      };
    }

    const res = await postData("indexhost", "getClaySand", formData, null);
    setSand(parseFloat(res.data.data?.sand).toFixed(0));
    setClay(parseFloat(res.data.data?.clay).toFixed(0));
    setSilt(parseFloat(res.data.data?.silt).toFixed(0));
    setMapLoader(false);
  };

  // NPKS FUNCTION  GIVES THE VALUES OF NITROGEN,PHOSPHORUS AND POTASSIUM FOR FARMER ADVISORY

  const npks = async (stat, dist, polygn) => {
    setMapLoader(true);
    let formData = { layer: "" };
    if (locationFilters.country.code == "2") {
      formData = {
        layer: `${locationFilters.country.label
          .split(" ")
          .join("")
          .toLowerCase()}_${locationFilters.state.label
          .split(" ")
          .join("")
          .toLowerCase()}`,
        latlongpolygon: polygn,
      };
    } else {
      formData = {
        layer: `${locationFilters.country.label
          .split(" ")
          .join("")
          .toLowerCase()}_${locationFilters.state.label
          .split(" ")
          .join("")
          // .toLowerCase()}_${locationFilters.city.label
          // .split(" ")
          // .join("")
          .toLowerCase()}`,
        latlongpolygon: polygn,
      };
    }

    const res = await postData("indexhost", "npksval", formData, null);
    // setNitrogen(res.data.data?.nitrogen);
    // setPhosphorus(res.data.data?.phosphorus);
    // setPotassium(res.data.data?.potassium);
    // addSoilLegend(res.data?.data);
    // setMapLoader(false);
    if (res.data.data !== null) {
      setNitrogen(res.data.data?.nitrogen);
      setPhosphorus(res.data.data?.phosphorus);
      setPotassium(res.data.data?.potassium);
      addSoilLegend(res.data?.data);
      setMapLoader(false);
    } else {
      setMapLoader(false);
      warningMessage(res.data.message);
    }
  };

  // addSoilLegend FUNCTION CREATE THE LEGEND OF SOIL DATA

  const getSoilDataLegendsVal = async () => {
    removeLegends();
    let data = {};

    let res = await getData("agribhumi2", "soildatalegend", data, null);
    addSoilLegend(res.data.data);
  };

  const addSoilLegend = useCallback(
    (arr) => {
      if (arr?.length > 0) {
        let activeLegend = arr.find(
          (item) => item.type_name == "india_" + selectedSoilNutrient
        );

        setSoilLegend(activeLegend);
        soilLegendRef.current = L.control({ position: "bottomleft" });

        soilLegendRef.current.onAdd = function (map) {
          let div = L.DomUtil.create("div", "");
          div.innerHTML +=
            `<div style='padding: 10px 20px; background-color: black;'>` +
            `<span style='color:#ffffff; padding-bottom: 42%; font-size: larger;'>` +
            `${activeLegend.unit}` +
            `</span>` +
            `<div style='display:flex; padding-top:15%; padding-bottom:11%;'>` +
            `<div>` +
            `<img src='${aws_s3_url}/legend-bulkdensity.png' style='height: 170px; width:20px'/>` +
            `</div>` +
            `<div style='display: flex;flex-direction: column;margin-left:10px; color:white;justify-content: space-around;font-size: small;'>` +
            `<span>` +
            `${activeLegend.value_1} ` +
            `</span>` +
            `<span>` +
            `${activeLegend.value_2} ` +
            `</span>` +
            `<span>` +
            `${activeLegend.value_3} ` +
            `</span>` +
            `<span>` +
            `${activeLegend.value_4} ` +
            `</span>` +
            `<span>` +
            `${activeLegend.value_5} ` +
            `</span>` +
            `<span>` +
            `${activeLegend.value_6} ` +
            `</span>` +
            `<span>` +
            `${activeLegend.value_7} ` +
            `</span>` +
            `<span>` +
            `${activeLegend.value_8} ` +
            `</span>` +
            `</div>` +
            `</div>` +
            `</div>`;

          return div;
        };
        soilLegendRef.current.addTo(mapRef.current);
      }
    },
    [selectedSoilNutrient]
  );
  useEffect(() => {
    removeLegends();
  }, [featureType]);
  // getAnalyticsLegend FUNCTION CRREATE Analytics Legend

  const getAnalyticsLegend = () => {
    if (
      featureType == "analytics" &&
      selectedVI == "NDVI" &&
      startDate !== "" &&
      endDate !== ""
    ) {
      analyticsLegndRef.current = L.control({ position: "bottomleft" });
      analyticsLegndRef.current.onAdd = function () {
        let div = L.DomUtil.create("div", "");
        div.innerHTML +=
          `<div style='padding: 10px 20px; background-color: black; display:flex;' >` +
          `<div  style='display:flex;' >` +
          `<img src='${aws_s3_url}/ndvi.png' style='height: 170px; width:20px; margin-right: 13px;' />` +
          `<div  style='display:flex; flex-direction:column; color:white; justify-content: space-between ' >` +
          `<span style='font-weight: 600; text-transform: capitalize;' >` +
          "high" +
          `</span>` +
          `<span style='font-weight: 600; text-transform: capitalize;' >` +
          "low" +
          `</span>` +
          `</div>` +
          `</div>` +
          `</div>`;
        return div;
      };
      analyticsLegndRef.current.addTo(mapRef.current);
    } else if (
      featureType == "analytics" &&
      selectedVI == "SM" &&
      startDate !== "" &&
      endDate !== ""
    ) {
      analyticsLegndRef.current = L.control({ position: "bottomleft" });
      analyticsLegndRef.current.onAdd = function () {
        let div = L.DomUtil.create("div", "");
        div.innerHTML +=
          `<div style='padding: 10px 20px; background-color: black; display:flex;' >` +
          `<div  style='display:flex;' >` +
          `<img src=${aws_s3_url}/sm.png style='height: 170px; width:20px; margin-right: 13px;' />` +
          `<div  style='display:flex; flex-direction:column; color:white; justify-content: space-between ' >` +
          `<span style='font-weight: 600; text-transform: capitalize;' >` +
          "high" +
          `</span>` +
          `<span style='font-weight: 600; text-transform: capitalize;' >` +
          "low" +
          `</span>` +
          `</div>` +
          `</div>` +
          `</div>`;
        return div;
      };
      analyticsLegndRef.current.addTo(mapRef.current);
    } else if (
      featureType == "analytics" &&
      selectedVI == "LAI" &&
      startDate !== "" &&
      endDate !== ""
    ) {
      analyticsLegndRef.current = L.control({ position: "bottomleft" });
      analyticsLegndRef.current.onAdd = function () {
        let div = L.DomUtil.create("div", "");
        div.innerHTML +=
          `<div style='padding: 10px 20px; background-color: black; display:flex;' >` +
          `<div  style='display:flex;' >` +
          `<img src='${aws_s3_url}/lai.png' style='height: 170px; width:20px; margin-right: 13px;' />` +
          `<div  style='display:flex; flex-direction:column; color:white; justify-content: space-between ' >` +
          `<span style='font-weight: 600; text-transform: capitalize;' >` +
          "high" +
          `</span>` +
          `<span style='font-weight: 600; text-transform: capitalize;' >` +
          "low" +
          `</span>` +
          `</div>` +
          `</div>` +
          `</div>`;
        return div;
      };
      analyticsLegndRef.current.addTo(mapRef.current);
    } else if (
      featureType == "analytics" &&
      selectedVI == "NDWI" &&
      startDate !== "" &&
      endDate !== ""
    ) {
      analyticsLegndRef.current = L.control({ position: "bottomleft" });
      analyticsLegndRef.current.onAdd = function () {
        let div = L.DomUtil.create("div", "");
        div.innerHTML +=
          `<div style='padding: 10px 20px; background-color: black; display:flex;' >` +
          `<div  style='display:flex;' >` +
          `<img src=${aws_s3_url}/ndwi.png style='height: 170px; width:20px; margin-right: 13px;' />` +
          `<div  style='display:flex; flex-direction:column; color:white; justify-content: space-between ' >` +
          `<span style='font-weight: 600; text-transform: capitalize;' >` +
          "high" +
          `</span>` +
          `<span style='font-weight: 600; text-transform: capitalize;' >` +
          "low" +
          `</span>` +
          `</div>` +
          `</div>` +
          `</div>`;
        return div;
      };
      analyticsLegndRef.current.addTo(mapRef.current);
    }
  };
  useEffect(() => {
    removeLegends();
    getAnalyticsLegend();
  }, [selectedVI, startDate, endDate]);

  // removeLegends THIS FUNCTION REMOVES ALL THE LEGENDS WHEN IT CALLS
  const removeLegends = () => {
    if (analyticsLegndRef.current instanceof L.Control) {
      mapRef.current.removeControl(analyticsLegndRef.current);
    }
    if (soilLegendRef.current instanceof L.Control) {
      mapRef.current.removeControl(soilLegendRef.current);
    }
    if (cropLegendRef.current instanceof L.Control) {
      mapRef.current.removeControl(cropLegendRef.current);
    }
    if (lulcLegendRef.current instanceof L.Control) {
      mapRef.current.removeControl(lulcLegendRef.current);
    }
  };

  const handleScoreCardOk = () => {
    setIsScoreCardOpen(false);
  };
  const handleScoreCardCancel = () => {
    setIsScoreCardOpen(false);
    setFarmersScore({});
    setFarmLandScore({});
    setProfitLossScore({});
  };

  const [selectedFarmer, setSelectedFarmer] = useState([]);
  const findFarmerId = () => {
    setSelectedFarmer(
      farmersScore.basic_details?.find((item) => item.ref_id == farmerId)
    );
  };
  useEffect(() => {
    findFarmerId();
  }, [farmerId]);

  const [farmCount, setFarmCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [fetchingFarms, setFetchingFarms] = useState(false);
  const [farmerDetails, setFarmerDetails] = useState([]);
  const userToken = localStorage.getItem("token");
  const [scoreAndReport, setScoreAndReport] = useState({
    district: "",
    state: "",
    lallong: [],
  });

  //  WHEN WE SELECTED FARMS IN FEATURE getAllFarms FETCH THE GEOTAGGED FARMS IN RIGHT SIDE Drawer.......------->>
  const getAllFarms = async (page, farmName, mobileNum) => {
    setMapLoader(true);
    let apikey = apiurls.find((item) => item.apikey == "geotaggedfarms");
    setFetchingFarms(true);
    const res = await axios({
      method: "GET",
      url: `${appconfig.domain[appconfig.environment].geotaggedfarms}${
        apikey.paths.geotaggedfarms
      }`,
      headers: {
        "Client-Name": "IOS_REACT_NATIVE_APP",
        "Client-Token": userToken,
        "X-localization": "en",
      },
      params: {
        customer_type: "web",
        offset: page,
        limit: 20,
        search: farmName,
        mobile: mobileNum,
        sort: "created_at",
        sort_order: "DESC",
      },
    });

    if (farmName == "" && mobileNum == "") {
      setAllFarms(res.data.data);
      setFarmsPaginataion(Math.ceil(res.data.recordsTotal / 20));
    } else if (farmName !== "") {
      setAllFarms(res.data.data);
      setFarmsPaginataion(Math.ceil(res.data.recordsTotal / 20));
    } else {
      setAllFarms(res.data.data);
    }

    setFarmCount(res.data);
    setFetchingFarms(false);
    setMapLoader(false);
  };

  useEffect(() => {
    if (featureType == "farms") {
      getAllFarms(0, "", "");
    }
  }, [featureType]);

  // searchForKhasra SEARCH A GEOTAGGED FARM BY ENTERING THE FARM NAME.......--------->>>>>>>>
  const searchForKhasra = (value) => {
    // getAllFarms(0, farmName, farmMobileNum);
    getAllFarms(0, value, farmMobileNum);
  };

  // searchForFarmMobile SEARCH A GEOTAGGED FARM BY ENTERING THE MOBILE NUMBER.......--------->>>>>>>>
  const searchForFarmMobile = (value) => {
    getAllFarms(0, farmName, farmMobileNum);
  };

  const [farmChecked, setFarmChecked] = useState(false);

  const drawAllFarmPolygon2 = (data) => {
    let farmLatlongs = [];
    if (rasterLayer && mapRef.current.hasLayer(rasterLayer)) {
      mapRef.current.removeLayer(rasterLayer);
    }
    for (let i = 0; i < markerLayer.length; i++) {
      if (mapRef.current.hasLayer(markerLayer[i])) {
        mapRef.current.removeLayer(markerLayer[i]);
      }
    }

    var latlng = [];
    var farmLatlng = [];
    allFarms?.forEach((item, index) => {
      let arr = [];
      let arr2 = [];

      if (item.geo_fencing_details.length > 0) {
        item.geo_fencing_details.forEach((item2, index) => {
          let arr3 = [];
          arr3.push(item2.latitude);

          arr3.push(item2.longitude);
          arr2.push(item2.longitude);
          arr2.push(item2.latitude);

          arr.push(arr3);
        });
      }

      if (item.geo_fencing_details.length > 0) {
        latlng.push(arr);
        farmLatlng.push(arr2);
      }
    });

    farmLatlongs = farmLatlng;
    var polygon = L.polygon(latlng, {
      fillColor: "transparent",
      weight: 3,
      opacity: 1,
      color: "#001840", //Outline color
      fillOpacity: 1,
    });
    polygon.addTo(mapRef.current);
    mapRef.current.fitBounds(polygon.getBounds());
  };

  useEffect(() => {
    if (
      featureType == "farmer-advisory" ||
      featureType == "farmer-scorecard" ||
      featureType == "farm-report"
    ) {
      removeLegends();
    }
  }, [featureType]);

  // navbar dropdown styles before/after farmer Advisory
  const dropDownStyle = {
    marginLeft: "10px !important",
    width: "145px !important",
  };
  const dropDownStyle2 = {
    marginLeft: "10px !important",
    width: "126px !important",
  };

  useEffect(() => {
    removeLegends();
  }, [selectedSoilNutrient]);

  const getCropHistoryYieldAreaData = (data) => {
    CropHistory(data);
    cropYield(data);
    getAreaData(data);
  };

  const clearSoilDataLegend = () => {
    if (lulcLegendRef.current instanceof L.Control) {
      mapRef.current.removeControl(lulcLegendRef.current);
    }
  };

  return (
    <Layout>
      <Content>
        {contextHolder}
        {mapLoader && (
          <div className="back-drop">
            <img src={`${aws_s3_url}/loader2.gif`} width="100px" />
            <div className="typewriter">
              <p className="back-drop-text">Loading Data....</p>
            </div>
          </div>
        )}

        <div className="mapHeader">
          {siderFlag && (
            <MenuOutlined
              onClick={() => siderFlagController(!siderFlag)}
              className="trigger"
              style={{
                color: "#ffffff",
                display:'flex',
                alignItems:"center"
              }}
            />
          )}

          <LocationHeader
            locationFiltersList={locationFiltersList}
            setLocationFiltersList={setLocationFiltersList}
            locationFilters={locationFilters}
            setLocationFilters={setLocationFilters}
            featureType={featureType}
            setFeatureType={setFeatureType}
            farmName={farmName}
            setFarmName={setFarmName}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            farmMobileNum={farmMobileNum}
            setFarmMobileNum={setFarmMobileNum}
            searchDisable={searchDisable}
            setSearchDisable={setSearchDisable}
            fetchingFarms={fetchingFarms}
            setFetchingFarms={setFetchingFarms}
            farmsPaginataion={farmsPaginataion}
            setFarmsPaginataion={setFarmsPaginataion}
            allFarms={allFarms}
            setAllFarms={setAllFarms}
            farmCount={farmCount}
            setFarmCount={setFarmCount}
            farmsCount={farmsCount}
            setFarmsCount={setFarmsCount}
            mapLoader={mapLoader}
            setMapLoader={setMapLoader}
            selectedSeason={selectedSeason}
            setSelectedSeason={setSelectedSeason}
            selectedSoilNutrient={selectedSoilNutrient}
            setSelectedSoilNutrient={setSelectedSoilNutrient}
            dropdownLoader={dropdownLoader}
            setDropdownLoader={setDropdownLoader}
            seasonsList={seasonsList}
            setSeasonsList={setSeasonsList}
            handleSearch={handleSearch}
            resetFilters={resetFilters}
            getCropHistoryYieldAreaData={getCropHistoryYieldAreaData}
            clearSoilDataLegend={clearSoilDataLegend}
          />

          {modalMenu != false &&
            featureType == "farmer-advisory" &&
            searchState == true && (
              <Button
                onClick={showModal}
                style={{
                  width: "11%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "11px",
                }}
              >
                <img
                  src={`${aws_s3_url}/smartfarming.jpg`}
                  style={{ width: "-webkit-fill-available", height: "inherit" }}
                  className={"advisryImg"}
                />
                Farmer Advisory
              </Button>
            )}

          <div
          style={{
            display:"flex",
            justifyContent:'flex-end',
            width:"-webkit-fill-available"

          }}
          >
            {collapsed && leftOutlinedButton==true &&  (
              <DoubleLeftOutlined
                className="trigger"
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  color: "#ffffff",
                  display:"flex",
                  alignItems:"center"
                }}
              />
            )}
          </div>
        </div>

        <div
          id="map"
          style={{
            height:
              searchState == true &&
              (featureType == "farmer-scorecard" || featureType == "analytics")
                ? "calc(100vh - 157px)"
                : "calc(100vh - 112px)",
          }}
        ></div>

        {searchState == true &&
        (featureType == "farmer-scorecard" || featureType == "analytics") ? (
          <div className="mapFooter">
            {featureType == "farmer-scorecard" &&
              mapLoader == false &&
              locationFilters.khasra !== null && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "-webkit-fill-available",
                    background: "#ffffff",
                  }}
                >
                  <div>
                    <Typography style={{ cursor: "pointer" }}>
                      Please click on map to view/download Farmer scorecard
                    </Typography>
                  </div>
                </div>
              )}
            {featureType == "analytics" && searchState == true && (
              <>
                <div>
                  {notifContextHolder}
                  {showFloatButton && showViGraph == true && (
                    <FloatButton
                      style={{ zIndex: 9999 }}
                      icon={<BarChartOutlined />}
                      onClick={() => {
                        setShowFloatButton(false);
                        api.open({
                          key: "updatable",
                          message: "",
                          description: (
                            <div>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={viChartOptions2}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "12px",
                                      height: "12px",
                                      backgroundColor: "#7BB4EC",
                                    }}
                                  ></div>
                                  <p
                                    style={{
                                      marginLeft: "5px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    Actual
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "12px",
                                      height: "12px",
                                      backgroundColor: "#434348",
                                    }}
                                  ></div>
                                  <p
                                    style={{
                                      marginLeft: "5px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    Ideal
                                  </p>
                                </div>
                              </div>
                            </div>
                          ),
                          duration: 0,
                          placement: "bottomRight",

                          onClose: () => {
                            setShowFloatButton(true);
                          },
                        });
                      }}
                    />
                  )}
                  ;
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{}}>
                    <RangePicker
                      className="dateSelector"
                      size="small"
                      onChange={(date, datestring) => {
                        setStartDate(datestring[0] + "-1");
                        setEndDate(
                          datestring[1] +
                            "-" +
                            moment(datestring[1]).daysInMonth()
                        );
                      }}
                      picker="month"
                    />
                    <Select
                      allowClear
                      size="small"
                      className="dropdown"
                      placeholder="Select Index"
                      onChange={(val) => {
                        setSelectedVI(val);
                      }}
                    >
                      <Option value={"NDVI"}>
                        <Tooltip
                          title="NDVI (Normalized Difference Vegetation Index) is a
                vegetative index, which is calculated according to the way
                a plant reflects and absorbs solar radiation at different
                wavelengths. The index allows for identification of
                problem areas of the field at different stages of plant
                growth for timely response. Pay attention to the areas
                where NDVI values ​​differ considerably. For example, the
                areas of a field that have an extremely low NDVI rate may
                indicate problems with pests or plant diseases; and the
                areas with an abnormally high NDVI signalize the
                occurrence of weeds."
                          placement="right"
                        >
                          Crop Health
                        </Tooltip>
                      </Option>

                      {/* <Option value={"LAI"}>
                        <Tooltip
                          title="Crop growth refers to the process by which crops develop and mature, from planting to harvest."
                          placement="right"
                        >
                          Crop Growth
                        </Tooltip>
                      </Option> */}
                      <Option value={"NDWI"}>
                        <Tooltip
                          title="Represents the moisture in plants"
                          placement="right"
                        >
                          Moisture Stress
                        </Tooltip>
                      </Option>
                      <Option value={"SM"}>
                        {" "}
                        <Tooltip
                          title="Represents the moisture in soil"
                          placement="right"
                        >
                          Soil Moisture
                        </Tooltip>{" "}
                      </Option>
                    </Select>
                    <Button
                      className="dateSelector"
                      icon={<SearchOutlined />}
                      size="small"
                      onClick={() => getViLatlongData(null)}
                    />
                  </div>
                  {viLatlongData.length && (
                    // {viLatlongDataRef.current.length && (
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                        // marginRight: "2%",
                      }}
                    >
                      <LeftCircleOutlined
                        onClick={() => scrollHorizontally(-90)}
                        style={{
                          color: "#ffffff",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                      />

                      <div
                        ref={scrollRightRef}
                        className="dateScrollCont"
                        style={{
                          overflowX: "scroll",
                          whiteSpace: "nowrap",
                          scrollBehavior: "smooth",
                          padding: "0px",
                          // marginTop: "3%",
                        }}
                      >
                        {viLatlongData.map((item, index) => (
                          // {viLatlongDataRef.current.map((item, index) => (
                          <span
                            className="dateElementBox"
                            key={index}
                            onClick={() => {
                              setSelectedViDate(index);
                              getLayerNames(item);
                            }}
                          >
                            <span
                              style={{
                                color:
                                  selectedViDate == index ? "aqua" : "#ffffff",
                              }}
                            >
                              {moment(item.date).format("MMM Do, YYYY")}
                            </span>
                          </span>
                        ))}
                      </div>
                      <RightCircleOutlined
                        onClick={() => scrollHorizontally(90)}
                        style={{
                          color: "#ffffff",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          <div style={{ display: "none" }} />
        )}

        <CropHistoryModal
          cropHistoryDialog={cropHistoryDialog}
          setCropHistoryDialog={setCropHistoryDialog}
          cropHistory={cropHistory}
          cropHistoryLoader={cropHistoryLoader}
          locationFilters={locationFilters}
        />

        {farmerReportDialog && (
          <FarmerReportDialog
            farmerReportDialog={farmerReportDialog}
            setFarmerReportDialog={setFarmerReportDialog}
            data={farmerReportData}
            cropHis={farmReportCropHistory}
            recomndCrop={farmReportRecomndCrop}
            soilTxtData={farmReportsoilText}
            npk={farmNpk}
            health={farmCropHealth}
            featureType={featureType}
            farmersScore={farmersScore}
            farmerDetails={farmerDetails}
            setMapLoader={setMapLoader}
          />
        )}
      </Content>
        
      {featureType &&  
      <Drawer
      warning={warningMessage}
      collapsed={collapsed}
      updatedColapsed={setCollapsed}
      allFarms={allFarms}
      scorecard={setScoreAndReport}
      featureType={featureType}
      mapRef={mapRef}
      selectedSoilNutrient={selectedSoilNutrient}
      L={L}
      selectedCommodity={selectedCommodity}
      setSelectedCommodity={setSelectedCommodity}
      setDeletedCommodity={setDeletedCommodity}
      acerageVal={acerageVal}
      yeildInfo={yeildInfo}
      acerageValLoader={acerageValLoader}
      yeildLoader={yeildLoadder}
      acerageValDetail={acerageValDetail}
      selectedCommoditiesList={selectedCommoditiesList}
      cropHealthLoader={cropHealthLoader}
      areadata={areaData}
      pieChartOptions={pieChartOptions}
      areadetails={areaDetail}
      farmerStatistics2={farmerStatistics2}
      farmCount={farmCount}
      rasterLayer={rasterLayer}
      getFarmerScoreCard_Farms={getFarmerScoreCard_Farms}
      farmerDetails={farmerDetails}
      setFarmerDetails={setFarmerDetails}
      setFarmersScore={setFarmersScore}
      farmersScore={farmersScore}
      getFarmerReportData_Farms={getFarmerReportData_Farms}
      getLatLong={getIndexLatLngsCropHistory}
      searchForFarmMobile={searchForFarmMobile}
      searchForKhasra={searchForKhasra}
      setFarmMobileNum={setFarmMobileNum}
      setFarmName={setFarmName}
      KhasraScreenShort={KhasraScreenShort}
      lulcAreaList={lulcAreaList}
    />
      
      }

      {/* <CropTransition
        handleOk={openTransModal}
        handleCancel={closeTransModal}
        isModalOpen={CropTransModal}
        transitionData={viLatlongData}
      /> */}

      <AdvisoryModal
        isModalOpen={isModalOpen}
        loader={recomndLoader}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isCropModelOpen={isCropModelOpen}
        selectAdvisory={selectAdvisory}
        farmAdvisory={farmAdvisory}
        setSeasons={setSeasons}
        fertilizerRecomn={fertilizerRecomn}
        getNpkValues={getNpkValues}
        setNitrogen={setNitrogen}
        setPhosphorus={setPhosphorus}
        setPotassium={setPotassium}
        setSilt={setSilt}
        setSand={setSand}
        setClay={setClay}
        selectedCrop={selectedCrop}
        soilTexture={soilTexture}
        fertiliedrecommenedCrop={fertiliedrecommenedCrop}
        fertilizedValues={fertilizedValues}
        nitrogen={nitrogen}
        phosphorus={phosphorus}
        potassium={potassium}
        showCropModal={showCropModal}
        goBack={goBack}
        seasons={seasons}
        silt={silt}
        sand={sand}
        clay={clay}
      />

      {farmerLoaderIcon !== true && (
        <Modal
          open={isScoreCardOpen}
          onOk={handleScoreCardOk}
          onCancel={handleScoreCardCancel}
          footer={null}
          centered="true"
          width={"90%"}
          bodyStyle={{
            backgroundColor: "#ffffff",
          }}
        >
          <FarmerScoreCardv3
            basicDetails={farmersScore}
            cropHistory={farmLandScore}
            profitLoss={profitLossScore}
            riskProfile={riskProfileScore}
            weatherTrend={weatherTrend}
            sampleScreenshot={sampleScreenshot}
            featureType={featureType}
            googgleImages={streetMapImg}
            setMapLoader={setMapLoader}
            KhasraScreenShort={KhasraScreenShort}
          />
        </Modal>
      )}
      <Modal
        title=""
        open={farmerRecordsDialog}
        footer={null}
        width={750}
        centered
        onCancel={() => setFarmerRecordsDialog(false)}
        maskClosable={true}
        closeIcon={<CloseOutlined style={{ color: "#121212" }} />}
      >
        <div className="agrlogo">
          <img style={{ width: "14%" }} src={`${aws_s3_url}/logo.svg`} />
          <img
            style={{ width: "14%" }}
            src={`${aws_s3_url}/AgribazaarLogo.svg`}
          />
        </div>
        <div style={{ marginTop: "25px", textAlign: "center" }}>
          <div style={{ background: "#6f9300" }}>
            <p
              style={{
                color: "#FFFFFF",
                fontSize: "x-large",
                fontWeight: "600",
              }}
            >
              Farmer Records
            </p>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "49%" }}>
              <div>
                <p className="seasonName">Basic Details</p>
              </div>
              {farmerRecords.recordsEng.length ? (
                farmerRecords.recordsEng.map((item, index) => (
                  <div
                    style={{
                      border: "1px solid #d9d9d9",
                      padding: "2px 8px",
                      background: "rgb(249, 255, 194)",
                      borderRadius: "3px",
                      marginBottom: "18px",
                    }}
                  >
                    {Object.keys(item).map((key, ind) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingBottom: "2px",
                          // borderBottom: "1px solid #d9d9d9",
                        }}
                      >
                        <div
                          style={{
                            color: "#121212",
                            textTransform: "capitalize",
                            fontWeight: 400,
                            fontSize: "12px",
                          }}
                        >
                          {key} :
                        </div>
                        <div
                          style={{
                            color: "#666666",
                            fontSize: "12px",
                            fontWeight: 700,
                          }}
                        >
                          {item[key] ? item[key] : "N/A"}
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <div
                  style={{
                    border: "1px solid #d9d9d9",
                    padding: "2px 8px",
                    background: "rgb(249, 255, 194)",
                    borderRadius: "3px",
                    marginBottom: "18px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "2px",
                      color: "#121212",
                    }}
                  >
                    No info available
                  </div>
                </div>
              )}
            </div>
            <div style={{ width: "49%" }}>
              <div>
                <p className="seasonName">Revenue Details</p>
              </div>
              {farmerRecords.recordsHindi.length ? (
                farmerRecords.recordsHindi.map((item, index) => (
                  <div
                    style={{
                      border: "1px solid #d9d9d9",
                      padding: "2px 8px",
                      background: "rgb(249, 255, 194)",
                      borderRadius: "3px",
                      marginBottom: "18px",
                    }}
                  >
                    {Object.keys(item).map((key, ind) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingBottom: "2px",
                          // borderBottom: "1px solid #d9d9d9",
                        }}
                      >
                        <div
                          style={{
                            color: "#121212",
                            textTransform: "capitalize",
                            fontWeight: 700,
                            fontSize: "14px",
                            width: "40%",
                            // marginRight: "14%",
                            textAlign: "initial",
                          }}
                        >
                          {key} :{" "}
                        </div>
                        <div
                          style={{
                            color: "#666666",
                            fontSize: "12px",
                            fontWeight: 400,
                            width: "60%",
                            textAlign: "right",
                          }}
                        >
                          {item[key] ? item[key] : "N/A"}
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <div
                  style={{
                    border: "1px solid #d9d9d9",
                    padding: "2px 8px",
                    background: "rgb(249, 255, 194)",
                    borderRadius: "3px",
                    marginBottom: "18px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "2px",
                      color: "#121212",
                    }}
                  >
                    No info available
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Discover;
