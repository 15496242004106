import { PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Input, Select, Typography, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getString } from "../../resources/utils";
import Countdown from "../Timer";
import "./Signup.css";

const { Option } = Select;

const Signup = () => {
  const userTypeList = [
    { id: "1", name: "Farmer" },
    { id: "2", name: "Seed Companies" },
    { id: "3", name: "Input Companies" },
    { id: "4", name: "Contract farming" },
    { id: "5", name: "Trade bodies" },
    { id: "6", name: "Government" },
    { id: "7", name: "Insurance" },
    { id: "8", name: "Finance" },
    { id: "9", name: "Team Agribazaar" },
    { id: "10", name: "Others" },
  ];
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [enterOtp, setEnterOtp] = useState(false);
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    pass: "",
  });

  const language = useSelector((state) => state.Language.language);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [userType, setUserType] = useState(1);

  const sendOtp = async () => {
    let otppaths = apiurls.find((item) => item.apikey == "agribazaar2");

    const res = await axios({
      method: "POST",
      headers: {
        Authorization:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ik5FV0RDfFh8WHwzMTh8WHxYfHxYfFh8MTAwODczfFh8WHw1fFh8WHx8WHxYfGlzQWJjfFh8WHxhZG1pbnxYfFh8MjAyMi0wNS0xOSAxNDoxNToxMyI.blxod_PDzXarHH_ZoU7zEDFxPsDWXKYQUtBLZei-CHI",
      },
      url: `${appconfig.domain.UAT.agribazaar2}${otppaths.paths.sendotp}`,
      data: {
        username: formData.username,
        country_code: 91,
        send: 1,
        isWeb: true,
      },
    });

    if (res.data.type == "success" || !res.error) {
      setEnterOtp(true);
      message.success("OTP sent successfuly.");
    }
  };

  const handleOtpSubmit = async () => {
    let formObj = {
      username: formData.username,
      country_code: 91,
      otp: otp,
      verify: 1,
      isWeb: true,
      ur: 1,
    };
    let otppaths = apiurls.find((item) => item.apikey == "agribazaar2");

    const res = await axios({
      method: "POST",
      headers: {
        Authorization:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ik5FV0RDfFh8WHwzMTh8WHxYfHxYfFh8MTAwODczfFh8WHw1fFh8WHx8WHxYfGlzQWJjfFh8WHxhZG1pbnxYfFh8MjAyMi0wNS0xOSAxNDoxNToxMyI.blxod_PDzXarHH_ZoU7zEDFxPsDWXKYQUtBLZei-CHI",
      },
      url: `${appconfig.domain.UAT.agribazaar2}${otppaths.paths.sendotp}`,
      data: {
        ...formObj,
      },
    });
    if (!res.data.error) {
      let path = apiurls.find((item) => item.apikey == "agribazaar2");

      const response = await axios({
        method: "POST",
        headers: {
          Authorization:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ik5FV0RDfFh8WHwzMTh8WHxYfHxYfFh8MTAwODczfFh8WHw1fFh8WHx8WHxYfGlzQWJjfFh8WHxhZG1pbnxYfFh8MjAyMi0wNS0xOSAxNDoxNToxMyI.blxod_PDzXarHH_ZoU7zEDFxPsDWXKYQUtBLZei-CHI",
        },
        url: `${appconfig.domain.UAT.agribazaar2}${path.paths.submitotp}`,
        data: {
          mobile_number: formData.username,
          name: formData.fname,
          user_type: userType,
        },
      });

      message.success("Successfully registered. Please login to continue.");

      navigate("/login");
    } else {
      message.error("Please enter valid OTP.");
    }
  };

  const handleInputChange = (e, type) => {
    let obj = { ...formData };
    obj[type] = e.target.value;
    setFormData(obj);
  };

  const handleOtpChange = (val) => {
    setOtp(val);
  };

  return (
    <div className={"mainCont"}>
      {!enterOtp ? (
        <div className={"loginCard"}>
          <Typography className={"cardTitle"}>
            {getString("SIGN_UP", language)}
          </Typography>

          <div className="inputCont">
            <div>
              <label style={{ color: "#ffffff", fontWeight: "600" }}>
                {getString("NAME", language)}
              </label>
              <Input
                placeholder={getString("FIRST_NAME", language)}
                className="textInput"
                prefix={<UserOutlined className="site-form-item-icon" />}
                onChange={(e) => handleInputChange(e, "fname")}
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <label style={{ color: "#ffffff", fontWeight: "600" }}>
              {getString("MOBILE_NO", language)}
            </label>
            <Input
              placeholder={getString("MOBILE_NO", language)}
              className="textInput"
              prefix={<PhoneOutlined className="site-form-item-icon" />}
              onChange={(e) => handleInputChange(e, "username")}
              allowClear
              maxLength={10}
              inputMode="numeric" // Shows numeric keypad on mobile devices
              pattern="[0-9]*" // Ensures only numbers are allowed
              onInput={(e) => {
                e.target.value = e.target.value.replace(/\D/g, ""); // Filters out non-digit characters
              }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ color: "#ffffff", fontWeight: "600" }}>
                {getString("USER_TYPE", language)}
              </label>
              <Select
                defaultValue="1"
                style={{ marginTop: "10px" }}
                onChange={(val) => {
                  setUserType(val);
                }}
              >
                {userTypeList.map((item, index) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <div
            className={"loginButton"}
            onClick={() => {
              sendOtp();
            }}
          >
            {getString("SUBMIT", language)}
          </div>
          <Typography className={"helperText"}>
            {language === "english"
              ? "Facing trouble? Call us on +91-9090397777"
              : "परेशानी का सामना करना पड़ रहा है? हमें +91-9090397777 . पर कॉल करें"}{" "}
            <br />
            {language === "english"
              ? "By signing in I agree with"
              : "साइन इन करके मैं सहमत हूं"}{" "}
            <span style={{ cursor: "pointer" }}>Agribazaar Terms of Use</span>
          </Typography>
        </div>
      ) : (
        <div className={"loginCard"}>
          <div>
            <Typography className={"cardTitle"}>
              {getString("LOGIN", language)}
            </Typography>
            <Typography className={"enterNum2"}>
              {language === "hindi" && formData.username}{" "}
              {getString("VERIFY_YOUR_NUMBER", language)}
              {language === "english" && formData.username}
              <span style={{ marginLeft: "12%" }}>
                {getString("NOT_YOURS", language)}
                <span
                  className={"changeNumber"}
                  onClick={() => setEnterOtp(false)}
                >
                  {getString("CHANGE_NUMBER", language)}
                </span>
              </span>
            </Typography>
            <Typography
              style={{
                marginBottom: "10px",
                fontWeight: "500",
                color: "#ffffff",
              }}
            >
              OTP
            </Typography>
            <OtpInput
              value={otp}
              onChange={handleOtpChange}
              numInputs={4}
              isInputNum={true}
              separator={<span style={{ color: "#424242" }}>--</span>}
              inputStyle={{
                width: "50px",
                height: "30px",
                borderRadius: 4,
                border: "solid 1px #cbcbcb",
                backgroundColor: "#fff",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#ffffff",
                marginTop: "10px",
              }}
            >
              <span style={{ marginRight: "3px" }}>Not received?</span>
              {seconds != 0 && (
                <span style={{ margin: "0px 3px" }}>Retry in</span>
              )}

              <Countdown
                minutes={minutes}
                setMinutes={setMinutes}
                seconds={seconds}
                setSeconds={setSeconds}
              />
              {seconds == 0 && (
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: "600",
                  }}
                  onClick={() => sendOtp()}
                >
                  Resend OTP<span class="bottom-link-line"></span>
                </span>
              )}
            </div>
            <Button
              className={"loginButton"}
              onClick={() => handleOtpSubmit()}
              disabled={otp.length < 4 ? true : false}
            >
              {getString("SUBMIT", language)}
            </Button>
            <Typography className={"helperText"}>
              {language === "english"
                ? "Facing trouble? Call us on +91-9090397777"
                : "परेशानी का सामना करना पड़ रहा है? हमें +91-9090397777 . पर कॉल करें"}{" "}
              <br />
              {language === "english"
                ? "By signing in I agree with"
                : "साइन इन करके मैं सहमत हूं"}{" "}
              <span style={{ cursor: "pointer" }}>Agribazaar Terms of Use</span>
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
