import { CloudDownloadOutlined } from "@ant-design/icons";
import { Tooltip, Typography, message, Checkbox } from "antd";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import { encode } from "js-base64";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { postData } from "../../resources/utils";
import "./FarmerScoreCard.css";
import { apiurls } from "../../resources/APIurls";
import { appconfig } from "../../resources/config";

highchartsMore(Highcharts);
solidGauge(Highcharts);

const FarmerScoreCardv3 = ({
  basicDetails,
  cropHistory,
  profitLoss,
  riskProfile,
  weatherTrend,
  sampleScreenshot,
  featureType,
  googgleImages,
  setMapLoader,
  KhasraScreenShort,
}) => {
  let apikey_s3 = apiurls.find((item) => item.apikey == "aws3");
  const aws_s3_url = `${appconfig.domain[appconfig.environment].aws3}${
    apikey_s3.paths.images
  }`;
  const [messageApi, contextHolder] = message.useMessage();
  const [score, setScore] = useState(0);
  const [basicDetailScore, setBasicDetailScore] = useState(0);
  const [landDetailScore, setLandDetailScore] = useState(0);
  const [farmlandDetailScore, setFarmlandDetailScore] = useState(0);
  const [yieldScore, setYieldScore] = useState(0);
  const [profitLossDetailScore, setProfitLossDetailScore] = useState(0);
  const [riskDetailScore, setRiskDetailScore] = useState(0);
  const [totalCreditScore, setTotalCreditScore] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [scoreBreakup, setScoreBreakup] = useState({
    basicDetails: {
      name: 0,
      address: 0,
      khasra: 0,
      numberOfFarmlands: 0,
      totalFarmlandArea: 0,
      validation: 0,
    },
    cropHistory: {
      cropGrowingFactor: 0,
      yieldFactor: 0,
    },
    profitLoss: {
      cropHealth: 0,
      soilHealth: 0,
      farmIncome: 0,
    },
    riskProfile: {
      weatherFloodRisk: 0,
      cropSuitability: 0,
      cropExpertise: 0,
      farmManagement: 0,
    },
  });
  const [creditChartOptions, setCreditChartOptions] = useState({
    chart: {
      type: "gauge",
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: 180,
      width: 300,
    },

    title: {
      text: "Your Credit Score is",
    },
    credits: {
      enabled: false,
    },
    pane: {
      startAngle: -90,
      endAngle: 89.9,
      background: null,
      center: ["50%", "75%"],
      size: "90%",
    },

    // the value axis
    yAxis: {
      min: 0,
      max: 100,
      tickPositions: [0, 30, 60, 80, 100],
      tickPixelInterval: 72,
      tickPosition: "inside",
      tickColor: "#FFFFFF",
      tickLength: 20,
      tickWidth: 2,
      minorTickInterval: null,
      labels: {
        distance: 20,
        style: {
          fontSize: "14px",
        },
      },
      plotBands: [
        {
          from: 0,
          to: 30,
          color: "#DF5353",
          thickness: 20,
        },
        {
          from: 30,
          to: 60,
          color: "#DDDF0D",
          thickness: 20,
        },
        {
          from: 60,
          to: 80,
          color: "#55BF3B",
          thickness: 20,
        },
        {
          from: 80,
          to: 100,
          color: "#3b8529",
          thickness: 20,
        },
      ],
    },

    series: [
      {
        name: "Score",
        data: [score],
        tooltip: {
          valueSuffix: "",
        },
        dataLabels: {
          format: "{y}",
          borderWidth: 0,
          color: "#333333",
          style: {
            fontSize: "24px",
          },
        },
        dial: {
          radius: "50%",
          backgroundColor: "gray",
          baseWidth: 12,
          baseLength: "0%",
          rearLength: "0%",
        },
        pivot: {
          backgroundColor: "gray",
          radius: 6,
        },
      },
    ],
  });

  const [cashflowChartOptions, setCashflowChartOptions] = useState({
    chart: {
      type: "column",
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      width: 900,
    },

    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    pane: {
      startAngle: -90,
      endAngle: 89.9,
      background: null,
      center: ["50%", "75%"],
      size: "90%",
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: true,
    },
    yAxis: {
      min: 0,
    },
    plotOptions: {
      column: {
        colorByPoint: true,
      },
    },
    colors: [
      "#B0E896",
      "#5C87E9",
      "#B0E896",
      "#5C87E9",
      "#B0E896",
      "#5C87E9",
      "#B0E896",
      "#5C87E9",
      "#B0E896",
      "#5C87E9",
      "#B0E896",
      "#5C87E9",
    ],
    series: [
      {
        name: "Month",
        data: [0, 0, 102410, 0, 0, 10600, 29950, 0, 0, 118800, 13061, 34700],
      },
    ],
  });

  const [weatherTrendChartOptions, setWeatherTrendChartOptions] = useState({
    chart: {
      plotBackgroundColor: "#ffffff",
      backgroundColor: "#ffffff",
      plotBorderWidth: 0,
      plotShadow: false,
      width: 900,
    },
    credits: {
      enabled: false,
    },
    legend: {
      // enabled: false,
    },
    title: {
      text: "",
    },
    yAxis: [
      {
        lineWidth: 1,
        //gridLineColor: "transparent",
        title: {
          text: "Rainfall (mm)",
        },
      },
      {
        lineWidth: 1,
        //gridLineColor: "transparent",
        opposite: true,
        title: {
          text: "Temperature (°C)",
        },
      },
    ],
    xAxis: {
      // type: "datetime",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: true,
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      series: {
        fillOpacity: 0.2,
      },
    },
    series: [
      {
        type: "column",
        name: "Rainfall",
        data: [6, 40, 10, 20, 5, 50, 30, 40, 35, 21, 38, 45],
        color: "#B0E896",
      },
      {
        type: "spline",
        name: "Temperature",
        data: [12, 15, 16, 18, 45, 32, 57, 23, 26, 16, 17, 12],
        color: "#5C87E9",
        yAxis: 1,
      },
    ],
  });

  const getMajorCropSown = (arr) => {
    if (arr != null) {
      let majorCropArea = Math.max(...arr.map((o) => o.value));
      return arr.find((item) => item.value == majorCropArea).name;
    } else {
      return "--";
    }
  };

  const getMajorYield = (arr) => {
    if (arr != null) {
      let majorCropArea = Math.max(...arr.map((o) => o.cropArea));
      return arr.find((item) => item.cropArea == majorCropArea);
    } else {
      return "--";
    }
  };

  const getTotalSowing = (arr) => {
    if (arr != null) {
      let total_sowing = 0;
      let majorCropArea = Math.max(...arr.map((o) => o.value));

      arr.forEach((item) => {
        total_sowing += item.value;
      });
      // let major_crop_percentage = (majorCropArea / total_sowing) * 100;
      return majorCropArea.toFixed(2);
      // return major_crop_percentage.toFixed(2);
    } else {
      return "--";
    }
  };

  const getTotalSowingPercentage = (arr) => {
    if (arr != null) {
      let total_sowing = 0;
      let majorCropArea = Math.max(...arr.map((o) => o.value));
      arr.forEach((item) => {
        total_sowing += item.value;
      });
      let major_crop_percentage = (majorCropArea / total_sowing) * 100;
      return major_crop_percentage.toFixed(2);
    } else {
      return "--";
    }
  };

  const handleYieldBgColor = (yieldObj) => {
    let avg = (
      (getMajorYield(yieldObj).maxFarmLevelCropYield +
        getMajorYield(yieldObj).minFarmLevelCropYield) /
      2
    ).toFixed(2);
    let majorYieldObj = getMajorYield(yieldObj);
    if (
      majorYieldObj.maxFarmLevelCropYield <=
      majorYieldObj.minDistrictLevelCropYield
    ) {
      return "#F97979";
    } else if (
      majorYieldObj.minFarmLevelCropYield >=
      majorYieldObj.minDistrictLevelCropYield
    ) {
      return "#B0E896";
    } else {
      return "#F9FFC2";
    }
  };

  const updateCashFlowChart = () => {
    let graphObj = { ...cashflowChartOptions };
    let objKeys = Object.keys(profitLoss.profit_loss_estimates[0]);
    let objKey_rabi = objKeys[0].includes("rabi") ? objKeys[0] : objKeys[1];
    let objKey_kharif = objKeys[0].includes("karif") ? objKeys[0] : objKeys[1];
    graphObj.series[0].data[2] =
      profitLoss.profit_loss_estimates[0][
        objKey_rabi
      ]?.estimated_value_of_produce;
    graphObj.series[0].data[5] =
      profitLoss.profit_loss_estimates[0][objKey_kharif]
        ?.estimated_cost_of_production -
      profitLoss.profit_loss_estimates[0][objKey_kharif]
        ?.estimated_cost_of_inputs_required;
    graphObj.series[0].data[6] =
      profitLoss.profit_loss_estimates[0][
        objKey_kharif
      ]?.estimated_cost_of_inputs_required;
    graphObj.series[0].data[9] =
      profitLoss.profit_loss_estimates[0][
        objKey_kharif
      ]?.estimated_value_of_produce;
    graphObj.series[0].data[10] =
      profitLoss.profit_loss_estimates[0][objKey_rabi]
        ?.estimated_cost_of_production -
      profitLoss.profit_loss_estimates[0][objKey_rabi]
        ?.estimated_cost_of_inputs_required;
    graphObj.series[0].data[11] =
      profitLoss.profit_loss_estimates[0][
        objKey_rabi
      ]?.estimated_cost_of_inputs_required;
    setCashflowChartOptions(graphObj);
  };

  const updateWeatherTrendChart = () => {
    let graphObj = { ...weatherTrendChartOptions };
    graphObj.series[0].data = [];
    graphObj.series[0].data.push(weatherTrend.rainfall[0][1]);
    graphObj.series[0].data.push(weatherTrend.rainfall[0][2]);
    graphObj.series[0].data.push(weatherTrend.rainfall[0][3]);
    graphObj.series[0].data.push(weatherTrend.rainfall[0][4]);
    graphObj.series[0].data.push(weatherTrend.rainfall[0][5]);
    graphObj.series[0].data.push(weatherTrend.rainfall[0][6]);
    graphObj.series[0].data.push(weatherTrend.rainfall[0][7]);
    graphObj.series[0].data.push(weatherTrend.rainfall[0][8]);
    graphObj.series[0].data.push(weatherTrend.rainfall[0][9]);
    graphObj.series[0].data.push(weatherTrend.rainfall[0][10]);
    graphObj.series[0].data.push(weatherTrend.rainfall[0][11]);
    graphObj.series[0].data.push(weatherTrend.rainfall[0][12]);
    graphObj.series[1].data = [];
    graphObj.series[1].data.push(weatherTrend.temp[0][1]);
    graphObj.series[1].data.push(weatherTrend.temp[0][2]);
    graphObj.series[1].data.push(weatherTrend.temp[0][3]);
    graphObj.series[1].data.push(weatherTrend.temp[0][4]);
    graphObj.series[1].data.push(weatherTrend.temp[0][5]);
    graphObj.series[1].data.push(weatherTrend.temp[0][6]);
    graphObj.series[1].data.push(weatherTrend.temp[0][7]);
    graphObj.series[1].data.push(weatherTrend.temp[0][8]);
    graphObj.series[1].data.push(weatherTrend.temp[0][9]);
    graphObj.series[1].data.push(weatherTrend.temp[0][10]);
    graphObj.series[1].data.push(weatherTrend.temp[0][11]);
    graphObj.series[1].data.push(weatherTrend.temp[0][12]);
    setWeatherTrendChartOptions(graphObj);
  };

  const caclCreditScore = (data, key, dividend, sectionScore) => {
    let score_increaser = (sectionScore / dividend);
    let updatedScore = 0;
    let breakup = { ...scoreBreakup };
    // if (key == "basic_details" && featureType == "farms") {
    //   if (
    //     data.basic_details?.farmer_name != null &&
    //     data.basic_details?.farmer_name != ""
    //   ) {
    //     updatedScore += score_increaser;
    //   }
    //   if (
    //     data.basic_details?.state_name != null &&
    //     data.basic_details?.state_name != ""
    //   ) {
    //     updatedScore += score_increaser;
    //   }
    //   if (
    //     data.basic_details?.district_name != null &&
    //     data.basic_details?.district_name != ""
    //   ) {
    //     updatedScore += score_increaser;
    //   }
    //   if (
    //     data.basic_details?.email != null &&
    //     data.basic_details?.email != ""
    //   ) {
    //     updatedScore += score_increaser;
    //   }
    //   if (
    //     data.basic_details?.mobile != null &&
    //     data.basic_details?.mobile != ""
    //   ) {
    //     updatedScore += score_increaser;
    //   }
    //   if (
    //     data.basic_details?.total_farms != null &&
    //     data.basic_details?.total_farms != ""
    //   ) {
    //     updatedScore += score_increaser;
    //   }
    //   if (
    //     data.basic_details?.total_farm_area != null &&
    //     data.basic_details?.total_farm_area != ""
    //   ) {
    //     updatedScore += score_increaser;
    //   }
    // }
    // if (key == "basic_details" && featureType != "farms") 
    if (key == "basic_details") 
      {
      breakup.basicDetails.name = 0;
      breakup.basicDetails.address = 0;
      breakup.basicDetails.khasra = 0;
      breakup.basicDetails.numberOfFarmlands = 0;
      breakup.basicDetails.totalFarmlandArea = 0;
      breakup.basicDetails.validation = 0;
      if (data[0]?.farmer_name != null && data[0]?.farmer_name != "") {
        updatedScore += score_increaser;
        breakup.basicDetails.name = 5;
      }
      if (data[0]?.village_name != null && data[0]?.village_name != "") {
        updatedScore += 5 / 4;
        breakup.basicDetails.address += 5 / 4;
      }
      if (data[0]?.district_name != null && data[0]?.district_name != "") {
        updatedScore += 5 / 4;
        breakup.basicDetails.address += 5 / 4;
      }
      if (
        data[0]?.subdistrict_name != null &&
        data[0]?.subdistrict_name != ""
      ) {
        updatedScore += 5 / 4;
        breakup.basicDetails.address += 5 / 4;
      }
      if (data[0]?.state_name != null && data[0]?.state_name != "") {
        updatedScore += 5 / 4;
        breakup.basicDetails.address += 5 / 4;
      }
      if (data[0]?.number_of_farmlands != null) {
        updatedScore += score_increaser;
        breakup.basicDetails.numberOfFarmlands = 5;
      }
      if (data[0]?.total_farmland_area != null) {
        updatedScore += score_increaser;
        breakup.basicDetails.totalFarmlandArea = 5;
      }
      if (data[0]?.khasra != null && data[0]?.khasra!=="") {
        updatedScore += score_increaser;
        breakup.basicDetails.khasra = 5;
      }
      updatedScore += 5;
      breakup.basicDetails.validation = 5;
      setBasicDetailScore(updatedScore);
      setScoreBreakup(breakup);
    }

    if (key == "crop_history") {
      breakup.cropHistory.cropGrowingFactor = 0;

      for (let item in data) {
        if (
          getMajorCropSown(data[item].crop_history) != "--" &&
          getMajorCropSown(data[item].crop_history) != null
        ) {
          updatedScore += score_increaser;
          breakup.cropHistory.cropGrowingFactor += score_increaser;
        }
        setFarmlandDetailScore(updatedScore);
        setScoreBreakup(breakup);
      }
    }
    if (key == "crop_yield") {
      scoreBreakup.cropHistory.yieldFactor =0
      for (let item in data) {
        if (
          getMajorYield(data[item].crop_yield) != "--" &&
          getMajorYield(data[item].crop_yield) != null
        ) {
          let yieldObj = getMajorYield(data[item].crop_yield);

          if (
            yieldObj.maxFarmLevelCropYield <= yieldObj.minDistrictLevelCropYield
          ) {
            scoreBreakup.cropHistory.yieldFactor += 1.0;
            updatedScore += 1.0;
          } else if (
            yieldObj.minFarmLevelCropYield >= yieldObj.minDistrictLevelCropYield
          ) {
            scoreBreakup.cropHistory.yieldFactor += 5.0;
            updatedScore += 5.0;
          } else {
            scoreBreakup.cropHistory.yieldFactor += 3.0;
            updatedScore += 3.0;
          }
        } else {
          scoreBreakup.cropHistory.yieldFactor -= 2;
          updatedScore -= 2;
        }

        setYieldScore(updatedScore);
        setScoreBreakup(breakup);
      }
    }
    if (key == "profit_loss_estimates") {
      let plObj = data.profit_loss_estimates[0];
      breakup.profitLoss.cropHealth = 0;
      breakup.profitLoss.soilHealth =0 ;
      breakup.profitLoss.farmIncome = 0;
      for (let item in plObj) {
        if (plObj[item]?.crop_health != null) {
          if (plObj[item]?.crop_health == "Good") {
            updatedScore += 4.5;
            breakup.profitLoss.cropHealth += 4.5;
          } else if (plObj[item]?.crop_health == "Poor") {
            updatedScore += 1;
            breakup.profitLoss.cropHealth += 1;
          } else if (plObj[item]?.crop_health == "Average"){
            updatedScore += 3;
            breakup.profitLoss.cropHealth += 3;
          }
          else{
            updatedScore += 3;
            breakup.profitLoss.cropHealth += 3;
          }
        }
        if (plObj[item]?.soil_health != null) {
          if (plObj[item]?.soil_health == "Good") {
            updatedScore += 3;
            breakup.profitLoss.soilHealth += 3;
          } else if (plObj[item]?.soil_health == "Poor") {
            updatedScore += 1;
            breakup.profitLoss.soilHealth += 1;
          } else if(plObj[item]?.soil_health == "Average") {
            updatedScore += 2;
            breakup.profitLoss.soilHealth += 2;
          }
          else{
            updatedScore += 0;
            breakup.profitLoss.soilHealth += 0;
          }
        }
        if (
          plObj[item]?.estimated_cost_of_production != null &&
          plObj[item]?.expected_gross_margins != null
        ) {
          let profit =
            (plObj[item].expected_gross_margins -
              plObj[item].estimated_cost_of_production) /
            plObj[item].estimated_cost_of_production;
          if (profit > 30) {
            updatedScore += 9;
            breakup.profitLoss.farmIncome += 9;
          } else if (profit > 10 && profit <= 30) {
            updatedScore += 6;
            breakup.profitLoss.farmIncome += 6;
          } else if (profit > 0 && profit <= 10) {
            updatedScore += 3;
            breakup.profitLoss.farmIncome += 3;
          }
          else{
            updatedScore += 0;
            breakup.profitLoss.farmIncome += 0;
          }
          
        }
      }
      data.profit_loss_estimates.forEach((item) => {
        // if (item.crop_health != null) {
        //   if (item.crop_health == "Good") {
        //     updatedScore += 4.5;
        //   } else if (item.crop_health == "Poor") {
        //     updatedScore += 1;
        //   } else {
        //     updatedScore += 3;
        //   }
        // }
        // if (item.soil_health != null) {
        //   if (item.soil_health == "Good") {
        //     updatedScore += 3;
        //   } else if (item.soil_health == "Poor") {
        //     updatedScore += 1;
        //   } else {
        //     updatedScore += 2;
        //   }
        // }
        // if (
        //   item.estimated_cost_of_production != null &&
        //   item.expected_gross_margins != null
        // ) {
        //   let profit =
        //     (item.expected_gross_margins - item.estimated_cost_of_production) /
        //     item.estimated_cost_of_production;
        //   if (profit > 30) {
        //     updatedScore += 9;
        //   } else if (profit > 10 && profit < 30) {
        //     updatedScore += 6;
        //   } else {
        //     updatedScore += 3;
        //   }
        // }

        setProfitLossDetailScore(updatedScore);
        setScoreBreakup(breakup);
      });
    }
    if (key == "risk_profile") {
      breakup.riskProfile.cropSuitability = 0;
      breakup.riskProfile.cropExpertise = 0;
      breakup.riskProfile.farmManagement = 0;
      breakup.riskProfile.weatherFloodRisk = 0;
      data?.risk_profile.forEach((item) => {
        if (item?.cropSuitability == "Suitable") {
          updatedScore += 4 / data.risk_profile.length;
          breakup.riskProfile.cropSuitability = 4 / data.risk_profile.length;
        }

        if (item?.currentCropExperties == "High") {
          updatedScore += 4 / data.risk_profile.length;
          breakup.riskProfile.cropExpertise = 4 / data.risk_profile.length;
        } else if (item?.currentCropExperties == "Low") {
          updatedScore += 1 / data.risk_profile.length;
          breakup.riskProfile.cropExpertise = 1 / data.risk_profile.length;
        } else if(item?.currentCropExperties == "Medium") {
          updatedScore += 3 / data.risk_profile.length;
          breakup.riskProfile.cropExpertise = 3 / data.risk_profile.length;
        }
        else{
          updatedScore += 0 / data.risk_profile.length;
          breakup.riskProfile.cropExpertise = 0 / data.risk_profile.length;
        }

        if (item?.farmManagement == "Good") {
          updatedScore += 4 / data.risk_profile.length;
          breakup.riskProfile.farmManagement = 4 / data.risk_profile.length;
        } else if (item?.farmManagement == "Poor") {
          updatedScore += 1 / data.risk_profile.length;
          breakup.riskProfile.farmManagement = 1 / data.risk_profile.length;
        } else if(item?.farmManagement == "Average"){
          updatedScore += 3 / data.risk_profile.length;
          breakup.riskProfile.farmManagement = 3 / data.risk_profile.length;
        }
        else{
          updatedScore += 0 / data.risk_profile.length;
          breakup.riskProfile.farmManagement = 0 / data.risk_profile.length;
        }

        if (item?.weatherRisk == "High") {
          updatedScore += 0.5 / data.risk_profile.length;
          breakup.riskProfile.weatherFloodRisk += 0.5 / data.risk_profile.length;
        } else if (item?.weatherRisk == "Low") {
          updatedScore += 2 / data.risk_profile.length;
          breakup.riskProfile.weatherFloodRisk += 2 / data.risk_profile.length;
        } else if (item?.weatherRisk == "Medium") {
          updatedScore += 1.5 / data.risk_profile.length;
          breakup.riskProfile.weatherFloodRisk += 1.5 / data.risk_profile.length;
        }
        else{
          updatedScore += 0 / data.risk_profile.length;
          breakup.riskProfile.weatherFloodRisk += 0 / data.risk_profile.length;
        }

        if (item?.FloodRisk == "High") {
          updatedScore += 0.5 / data.risk_profile.length;
          breakup.riskProfile.weatherFloodRisk += 0.5 / data.risk_profile.length;
        } else if (item?.FloodRisk == "Low") {
          updatedScore += 2 / data.risk_profile.length;
          breakup.riskProfile.weatherFloodRisk += 2 / data.risk_profile.length;
        } else if (item?.FloodRisk == "Medium"){
          updatedScore += 1.5 / data.risk_profile.length;
          breakup.riskProfile.weatherFloodRisk += 1.5 / data.risk_profile.length;
        }
        else{
          updatedScore += 0 / data.risk_profile.length;
          breakup.riskProfile.weatherFloodRisk += 0 / data.risk_profile.length;
        }
        setScoreBreakup(breakup);
        setRiskDetailScore(updatedScore);
      });
    }

    // setScore(updatedScore);
    // let chartObj = { ...creditChartOptions };

    // chartObj.series[0].data[0] = updatedScore;
    // chartObj.title.text = `Your Credit Score is ${updatedScore}`;
    // setCreditChartOptions(chartObj);
  };

  useEffect(() => {
    let chartObj = { ...creditChartOptions };
    let updatedScore = Math.round(
      basicDetailScore +
        landDetailScore +
        farmlandDetailScore +
        profitLossDetailScore +
        riskDetailScore +
        yieldScore
    );
    setTotalCreditScore(updatedScore);

    chartObj.series[0].data[0] = updatedScore;
    chartObj.title.text = "";
    setCreditChartOptions(chartObj);
  }, [
    basicDetailScore,
    farmlandDetailScore,
    profitLossDetailScore,
    riskDetailScore,
    yieldScore,
  ]);

  useEffect(() => {
    if (basicDetails && Object.keys(basicDetails).length) {
      caclCreditScore(basicDetails, "basic_details", 6, 30);
      // if (featureType !== "farms") {
      //   caclCreditScore(
      //     farmersScore,
      //     "land_details",
      //     3 * farmersScore.land_details.length
      //   );
      // }
    }
  }, [basicDetails]);

  useEffect(() => {
    if (cropHistory && Object.keys(cropHistory).length>0) {
      caclCreditScore(cropHistory, "crop_history", 3, 15);
      caclCreditScore(cropHistory, "crop_yield", 3, 15);
    }
  }, [cropHistory]);

  useEffect(() => {
    if (riskProfile && Object.keys(riskProfile).length) {
      caclCreditScore(riskProfile, "risk_profile", 4, 14);
    }
  }, [riskProfile]);

  useEffect(() => {
    if (profitLoss && Object.keys(profitLoss).length) {
      updateCashFlowChart();
      caclCreditScore(profitLoss, "profit_loss_estimates", 4, 24);
      let profit = 0;
      Object.entries(profitLoss.profit_loss_estimates[0]).forEach(
        (item) => (profit += item[1]?.expected_gross_margins)
      );
      setTotalProfit(profit);
    }
  }, [profitLoss]);

  useEffect(() => {
    if (Object.keys(weatherTrend)?.length) {
      updateWeatherTrendChart();
    }
  }, [weatherTrend]);

  const loadingMessage = () => {
    messageApi.open({
      type: "loading",
      content: "Your file is being ready, Please wait..",
      duration: 1.5,
    });
  };

  // GENERATE A MESSAGE WHEN REPORT IS READY TO DOWNLOAD (FARMER SCORE CARD)

  const successMessage = () => {
    messageApi.open({
      type: "success",
      content: "Your file is ready to download.",
      duration: 2,
    });
  };

  const callPdfService = async (data) => {
    let res = await postData("indexhost", "getPdf", data, null);

    const linkSource = `data:application/pdf;base64,${res.data.data}`;
    const downloadLink = document.createElement("a");
    const fileName = `${basicDetails[0].farmer_name}_${basicDetails[0].village_name}_${basicDetails[0].subdistrict_name}_${basicDetails[0].district_name}_${basicDetails[0].state_name}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    messageApi.destroy();
    successMessage();
    setMapLoader(false);
    downloadLink.click();
  };

  const downloadPdf = async () => {
    loadingMessage();
    setMapLoader(true);
    let target = document.getElementById("divToPrint");
    var wrap = document.createElement("div");
    wrap.appendChild(target.cloneNode(true));

    let startHtml = `<html> <head> <link rel='preconnect' href="https://fonts.googleapis.com" /> <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin /> <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700&display=swap" rel="stylesheet" /> <style> div { font-family: poppins; } p { font-family: poppins; } span { font-family: poppins; } </style> </head> <body>`;
    let endHtml = `</body></html>`;
    let str = startHtml + wrap.innerHTML.replace(/\n|\t/g, " ") + endHtml;
    let data = {
      html: encode(str),
    };

    callPdfService(data);
  };

  const formatSeasonString = (str) => {
    let str_arr = str.split("_");

    if (str_arr.length > 2) {
      return (
        str_arr[0] +
        "_" +
        str_arr[1].substring(2) +
        " " +
        str_arr[2].charAt(0).toUpperCase() +
        str_arr[2].slice(1)
      );
    } else {
      return (
        str_arr[0] +
        "_" +
        str_arr[1].charAt(0).toUpperCase() +
        str_arr[1].slice(1)
      );
    }
  };

  // download farmer scorecard
  // const printDocument = () => {
  //   const input = document.getElementById("divToPrint");
  //   html2canvas(input, { scale: 2 }).then((canvas) => {
  //     let itr = 0;
  //     var imgData = canvas.toDataURL("image/png");
  //     var imgWidth = 210;
  //     var pageHeight = 250;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width + 190;
  //     var heightLeft = imgHeight;
  //     var doc = new jsPDF("p", "px");
  //     var position = 0;

  //     doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //     var today = new Date();

  //     var newdate = moment(today).format("lll");

  //     while (heightLeft >= 0) {
  //       position = itr == 0 ? -290 : itr == 1 ? -520 : -780;

  //       doc.addPage();
  //       doc.addImage(imgData, "PNG", 10, position, imgWidth - 17, imgHeight);
  //       heightLeft -= pageHeight;
  //       itr += 1;
  //     }
  //     doc.save(`${"_farm_report"}.pdf`);
  //     successMessage();
  //   });
  // };

  const printDocument = () => {
    // setPrintLoader(true);
    loadingMessage();
    const input = document.getElementById("divToPrint");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      let wid = 500;
      let hgt = 655;
      var imgData = canvas.toDataURL("image/png");
      var imgHeight = (canvas.height * wid) / canvas.width;
      var heightLeft = imgHeight;
      var margin = [10, 20, 30, 10];
      var doc = new jsPDF("p", "mm", [500, 655]);
      var position = 0;
      doc.addImage(imgData, "PNG", 0, position, wid, imgHeight);
      heightLeft -= hgt;
      var today = new Date();

      var newdate = moment(today).format("lll");

      doc.setFontSize(8);
      doc.text(newdate, 3, 5);
      doc.setFontSize(6);
      // doc.text("Disclaimer", 10, 820);
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, wid, imgHeight);
        heightLeft -= hgt;
      }

      // doc.save(
      //   `${
      //     farmerId != ""
      //       ? selectedFarmer.farmer_name.split(" ").join("_") + "_farm_report"
      //       : data.farmerdetails1[0].farmer_name.split(" ").join("_") +
      //         "_farm_report"
      //   }.pdf`
      // );
      doc.save(`${"_farm_report"}.pdf`);

      successMessage();
    });
  };
  const [revenue, setRevenue] = useState(false);
  const validateRevenue = async (e) => {
    let data = {
      data: 6,
      is_validated: 1,
      id: basicDetails.id ? basicDetails.id : basicDetails[0].id,
    };

    let res = await postData("score", "validation", data, null);
    if (e.target.checked == true) {
      setRevenue(true);
    }
  };

  return (
    <Fragment>
      <div>
        {contextHolder}
        <Tooltip title="Download" placement="right">
          <CloudDownloadOutlined
            onClick={downloadPdf}
            //onClick={printDocument}
            style={{
              color: "#212121",
              fontSize: "25px",
              cursor: "pointer",
            }}
          />
        </Tooltip>
      </div>
      <div style={{ marginTop: "25px" }} id="divToPrint">
        <div
          style={{
            backgroundImage: `url(https://agribhumi.s3.ap-south-1.amazonaws.com/scorecard_bg_1.svg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "637px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <img
            src={"https://agribhumi.s3.ap-south-1.amazonaws.com/logo.svg"}
            width="100px"
          />
          {basicDetails&& Object.keys(basicDetails).length ? (
            <div>
              <div>
                <div
                  style={{
                    borderTop: "1px solid #ffffff",
                    borderBottom: "1px solid #ffffff",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      borderRight: "1px solid #ffffff",
                      width: "50%",
                      padding: "10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontWeight: 600, fontSize: "18px" }}>
                          Name of the Farmer :{" "}
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px" }}>
                          {basicDetails?.farmer_name
                            ? basicDetails.farmer_name
                            : basicDetails[0]?.farmer_name
                            ? basicDetails[0]?.farmer_name
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontWeight: 600, fontSize: "18px" }}>
                          Village :{" "}
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px" }}>
                          {basicDetails?.village_name
                            ? basicDetails.village_name
                            : basicDetails[0]?.village_name
                            ? basicDetails[0]?.village_name
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontWeight: 600, fontSize: "18px" }}>
                          Tehsil/Block :{" "}
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px" }}>
                          {basicDetails?.subdistrict_name
                            ? basicDetails.subdistrict_name
                            : basicDetails[0]?.subdistrict_name
                            ? basicDetails[0]?.subdistrict_name
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      padding: "10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontWeight: 600, fontSize: "18px" }}>
                          District :{" "}
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px" }}>
                          {basicDetails?.district_name
                            ? basicDetails.district_name
                            : basicDetails[0]?.district_name
                            ? basicDetails[0]?.district_name
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontWeight: 600, fontSize: "18px" }}>
                          State :{" "}
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px" }}>
                          {basicDetails?.state_name
                            ? basicDetails.state_name
                            : basicDetails[0]?.state_name
                            ? basicDetails[0]?.state_name
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontWeight: 600, fontSize: "18px" }}>
                          Farm/Khasra Number :{" "}
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px" }}>
                          {basicDetails?.khasra
                            ? basicDetails.khasra
                            : basicDetails[0]?.khasra
                            ? basicDetails[0]?.khasra
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #ffffff",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      borderRight: "1px solid #ffffff",
                      width: "50%",
                      padding: "10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontWeight: 600, fontSize: "18px" }}>
                          Number of Farmlands :{" "}
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px" }}>
                          {basicDetails?.number_of_farmlands
                            ? basicDetails.number_of_farmlands
                            : basicDetails[0]?.number_of_farmlands
                            ? basicDetails[0]?.number_of_farmlands
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontWeight: 600, fontSize: "18px" }}>
                          Total Farmland Area :
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px" }}>
                          {basicDetails?.total_farmland_area
                            ? parseFloat(
                                basicDetails.total_farmland_area
                              ).toFixed(3)
                            : basicDetails[0]?.total_farmland_area
                            ? parseFloat(
                                basicDetails[0]?.total_farmland_area
                              ).toFixed(3)
                            : "N/A"}{" "}
                          HA
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontWeight: 600, fontSize: "18px" }}>
                          Selected Farmland Area :
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px" }}>
                          {basicDetails?.selected_farmland_area
                            ? parseFloat(
                                basicDetails.selected_farmland_area
                              ).toFixed(3)
                            : basicDetails[0]?.selected_farmland_area
                            ? parseFloat(
                                basicDetails[0]?.selected_farmland_area
                              ).toFixed(3)
                            : "N/A"}{" "}
                          HA
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      padding: "10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontWeight: 600, fontSize: "18px" }}>
                          Farmer Details Validation :{" "}
                        </p>
                      </div>

                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px" }}>
                          {/* {featureType !== "farmer-scorecard" &&
                          featureType !== "farms" &&
                          revenue !== false
                            ? basicDetails[0]?.is_validated > 0
                              ? "Validated Okay with Revenue Records"
                              : revenue
                            : "Not validated"} */}
                          {featureType !== "farmer-scorecard" &&
                          featureType !== "farms"
                            ? basicDetails[0]?.is_validated > 0
                              ? "Validated Okay with Revenue Records"
                              : "NOT VALIDATED"
                            : "Validated Okay with Revenue Records"}
                        </p>
                      </div>
                    </div>
                    {featureType !== "farmer-scorecard" &&
                      featureType !== "farms" &&
                      basicDetails[0]?.is_validated == 0 &&
                      revenue == "" && (
                        <div className="check">
                          <Typography
                            style={{ fontWeight: 600, fontSize: "18px" }}
                          >
                            Click To Validate
                          </Typography>
                          <Checkbox
                            style={{ marginLeft: "7px" }}
                            onChange={validateRevenue}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div style={{ marginLeft: "40px" }}>
                  <p
                    style={{
                      color: "#ffffff",
                      fontSize: "18px",
                      fontWeight: 700,
                    }}
                  >
                    Report <br /> Generation Date :
                  </p>
                  <p>{moment(new Date()).format("DD MMM, YYYY hh:mm:ss")}</p>
                  <hr color="#212121" />
                </div>
                <div style={{ marginLeft: "40px" }}>
                  <p
                    style={{
                      color: "#ffffff",
                      fontSize: "18px",
                      fontWeight: 700,
                    }}
                  >
                    Satellite <br /> Data Estimation Date :
                  </p>
                  <p>
                    {/* {basicDetails[0]?.satellite_data_estimation_date
                      ? basicDetails[0]?.satellite_data_estimation_date
                      : "No Data Found"} */}
                    {basicDetails?.satellite_data_estimation_date
                      ? basicDetails.satellite_data_estimation_date
                      : basicDetails[0]?.satellite_data_estimation_date
                      ? basicDetails[0]?.satellite_data_estimation_date
                      : "N/A"}
                  </p>
                  <hr color="#212121" />
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "530px",
              }}
            >
              <img src={`${aws_s3_url}/score-card-loader.gif`} width="60px" />
            </div>
          )}
        </div>

        <div
          style={{
            backgroundImage: `url(https://agribhumi.s3.ap-south-1.amazonaws.com/scorecard_bg_2.svg)`,
            // backgroundImage: `url(${farmer})`,
            backgroundSize: "cover",
            height: "590px",
            marginTop: "40px",
            pageBreakAfter: "always",
          }}
        >
          <div style={{ display: "flex" }}>
            <p style={{ color: "#585757", fontSize: "30px", fontWeight: 700 }}>
              Credit Score
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "67%",
              }}
            >
              <span style={{ marginTop: "12px", marginRight: "8px" }}>
              Farmer's Credit Score 
              </span>{" "}
              <span
                style={{
                  color: "#5DD835",
                  fontSize: "30px",
                  fontWeight: "700",
                }}
              >
                {totalCreditScore}/100
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "130px",
            }}
          >
            <div
              style={{
                width: "300px",
                border: "3px solid #263238",
                borderRadius: "5px",
                marginTop: "9%",
                marginLeft: "25%",
              }}
            >
              <div style={{ height: "30px", backgroundColor: "#263238" }}></div>
              <HighchartsReact
                highcharts={Highcharts}
                options={creditChartOptions}
              />
              <div
                style={{
                  backgroundColor: "#ffffff",
                  display: "flex",
                  alignItems: "center",
                  padding: "6px",
                  borderTop: "1px solid #424242",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "4px",
                      backgroundColor: "#DF5353",
                    }}
                  ></div>
                  Poor: 0-30
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "4px",
                      marginLeft: "4px",
                      backgroundColor: "#DDDF0D",
                    }}
                  ></div>
                  Average: 30-60
                </div>
              </div>
              <div
                style={{
                  paddingLeft: "6px",
                  backgroundColor: "#ffffff",
                  paddingBottom: "6px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "4px",

                      backgroundColor: "#55BF3B",
                    }}
                  ></div>
                  Good: 60-80
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "4px",
                      marginRight: "4px",
                      backgroundColor: "#3b8529",
                    }}
                  ></div>
                  Excellent: 80-100
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "25px" }}>
          <div className="agrlogo">
            <img style={{ width: "10%" }} src={`${aws_s3_url}/logo.svg`} />
            <img
              style={{ width: "10%" }}
              src={`${aws_s3_url}/AgribazaarLogo.svg`}
            />
          </div>
          <p className="section-heading">Farm Location</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ fontFamily: "Poppins" }}>
              Farm Coordinates: Lat, Long: (77.34352972, 24.81038363)
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div className="farm-img-box">
              {Object.keys(googgleImages).length ? (
                <img
                  src={`data:image/png;base64,${googgleImages["satellite"]}`}
                  width="100%"
                />
              ) : (
                <img src={`${aws_s3_url}/score-card-loader.gif`} width="60px" />
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#585757",
                    fontFamily: "Poppins",
                  }}
                >
                  Farm Khasra Boundary
                </p>
              </div>
            </div>
            <div className="farm-img-box">
              {Object.keys(googgleImages).length ? (
                <img
                  src={`data:image/png;base64,${googgleImages["open-street-map"]}`}
                  width="100%"
                />
              ) : (
                <img src={`${aws_s3_url}/score-card-loader.gif`} width="60px" />
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#585757",
                    fontFamily: "Poppins",
                  }}
                >
                  Farm location from nearest road
                </p>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px", pageBreakAfter: "always" }}>
          <p
            style={{
              color: "#585757",
              fontSize: "30px",
              fontWeight: 600,
              fontFamily: '"Poppins"',
            }}
          >
            Crop History
          </p>
          <p
            style={{
              color: "#585757",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            Farmland {cropHistory?.Khasra}
          </p>

          <div
            style={{
              border: "1px solid #e6e6e6",
              boxShadow: "0px 4px 12px 0px #b4b4b440",
            }}
          >
            <div style={{ display: "flex", borderBottom: "1px solid #e6e6e6" }}>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  width: "20%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  color: "#585757",
                }}
              >
                Season
              </div>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 700,
                  color: "#000000",
                  width: "20%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                Major Crop Sown
              </div>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 700,
                  color: "#000000",
                  width: "20%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                Total Sowing (Ha)
              </div>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 700,
                  color: "#000000",
                  width: "20%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                Avg. District level Yield (Qt/Ha)
              </div>
              <div
                style={{
                  fontWeight: 700,
                  color: "#000000",
                  width: "20%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                Avg.Yield at this farm (Qt/Ha)
              </div>
            </div>
            {cropHistory && Object.keys(cropHistory).length > 0 ? (
              Object.entries(cropHistory).map(
                (item, index) =>
                  item[0] != "Khasra" && (
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid #e6e6e6",
                      }}
                    >
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          fontWeight: 500,
                          color: "#000000",
                          width: "20%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                        }}
                      >
                        {formatSeasonString(item[0])}
                        {/* {item[0]} */}
                      </div>
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          width: "20%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                        }}
                      >
                        {getMajorCropSown(item[1].crop_history)}
                      </div>
                      {basicDetails.length > 0 ? (
                        <div
                          style={{
                            borderRight: "1px solid #e6e6e6",
                            // backgroundColor:
                            //   getTotalSowing(item[1].crop_history) > 70
                            //     ? "#B0E896"
                            //     : getTotalSowing(item[1].crop_history) > 50 &&
                            //       getTotalSowing(item[1].crop_history) < 70
                            //     ? "#F9FFC2"
                            //     : "#F97979",
                            backgroundColor:
                              getTotalSowingPercentage(item[1].crop_history) >
                              70
                                ? "#B0E896"
                                : getTotalSowingPercentage(
                                    item[1].crop_history
                                  ) > 50 &&
                                  getTotalSowingPercentage(
                                    item[1].crop_history
                                  ) < 70
                                ? "#F9FFC2"
                                : "#B0E896",
                            width: "20%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px",
                            color: "#585757",
                          }}
                        >
                          {getTotalSowing(item[1].crop_history)}
                        </div>
                      ) : (
                        "--"
                      )}
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          width: "20%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                        }}
                      >
                        {
                          getMajorYield(item[1].crop_yield)
                            .minDistrictLevelCropYield
                        }
                        -
                        {
                          getMajorYield(item[1].crop_yield)
                            .maxDistrictLevelCropYield
                        }{" "}
                      </div>
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          backgroundColor: handleYieldBgColor(
                            item[1].crop_yield
                          ),
                          width: "20%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                        }}
                      >
                        {item[1].crop_yield != null
                          ? (
                              (getMajorYield(item[1].crop_yield)
                                .maxFarmLevelCropYield +
                                getMajorYield(item[1].crop_yield)
                                  .minFarmLevelCropYield) /
                              2
                            ).toFixed(2)
                          : "--"}
                      </div>
                    </div>
                  )
              )
            ) : (
              // <div>Loading</div>
              <div
                style={{
                  background: "rgb(249, 121, 121)",
                  border: " 1px solid rgb(230, 230, 230)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "9px",
                }}
              >
                <Typography>Loading</Typography>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px 15px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "#B0E896",
                    border: "1px solid #27BA5C",
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                ></div>
                Sowing in more than 70% area or high yield
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "#F9FFC2",
                    border: "1px solid #E3AE10",
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                ></div>
                Sowing in 50- 70% area or average yield
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "#F97979",
                    border: "1px solid #ED1849",
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                ></div>
                Sowing in less than 50% area or low yield
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px" }}>
          <div className="agrlogo">
            <img style={{ width: "10%" }} src={`${aws_s3_url}/logo.svg`} />
            <img
              style={{ width: "10%" }}
              src={`${aws_s3_url}/AgribazaarLogo.svg`}
            />
          </div>
          <p
            style={{
              color: "#585757",
              fontSize: "30px",
              fontWeight: 600,
              fontFamily: '"Poppins"',
            }}
          >
            Yearly Profit & Loss Estimates
          </p>
          <div
            style={{
              border: "1px solid #e6e6e6",
              boxShadow: "0px 4px 12px 0px #b4b4b440",
            }}
          >
            <div style={{ display: "flex", borderBottom: "1px solid #e6e6e6" }}>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 700,
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  color: "#585757",
                  fontSize: "11px",
                }}
              >
                Farm No
              </div>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 700,
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  color: "#585757",
                  fontSize: "11px",
                }}
              >
                Season
              </div>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 700,
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  color: "#585757",
                  fontSize: "11px",
                }}
              >
                Major Crop Sown
              </div>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 700,
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  color: "#585757",
                  fontSize: "11px",
                }}
              >
                Crop Health
              </div>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 700,
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  color: "#585757",
                  fontSize: "11px",
                }}
              >
                Soil Health
              </div>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 700,
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  color: "#585757",
                  fontSize: "11px",
                }}
              >
                <p>Estimated Production at this farm (qt)</p>
              </div>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 700,
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  color: "#585757",
                  fontSize: "11px",
                }}
              >
                <p>Estimated value of Produce(INR)</p>
              </div>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 700,
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  color: "#585757",
                  fontSize: "11px",
                }}
              >
                <p>Estimated Cost of Production(INR)</p>
              </div>
              <div
                style={{
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 700,
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  color: "#585757",
                  fontSize: "11px",
                }}
              >
                <p>Estimated Cost of Inputs Required(INR)</p>
              </div>
              <div
                style={{
                  fontWeight: 700,
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  color: "#585757",
                  fontSize: "11px",
                }}
              >
                <p>Expected Gross Margin (INR)</p>
              </div>
            </div>

            {profitLoss && Object.keys(profitLoss).length ? (
              <>
                {Object.entries(profitLoss?.profit_loss_estimates[0]).map(
                  (item, index) => (
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid #e6e6e6",
                      }}
                    >
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          fontWeight: 700,
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                          fontSize: "11px",
                        }}
                      >
                        {item[1]?.khasra}
                      </div>
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          fontWeight: 700,
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                          fontSize: "11px",
                        }}
                      >
                        {formatSeasonString(item[0])}
                      </div>
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          fontWeight: 500,
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                          fontSize: "11px",
                        }}
                      >
                        {item[1]?.major_crop}
                      </div>
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          fontWeight: 500,
                          backgroundColor: "#B0E896",
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                          fontSize: "11px",
                        }}
                      >
                        {item[1]?.crop_health}
                      </div>
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          fontWeight: 500,
                          backgroundColor: "#F97979",
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                          fontSize: "11px",
                        }}
                      >
                        {item[1]?.soil_health}
                      </div>
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          fontWeight: 500,
                          //backgroundColor: "#F97979",
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                          fontSize: "11px",
                        }}
                      >
                        {item[1]?.estimated_production}
                      </div>
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          fontWeight: 500,
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                          fontSize: "11px",
                        }}
                      >
                        {item[1]?.estimated_value_of_produce}
                      </div>
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          fontWeight: 500,
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                          fontSize: "11px",
                        }}
                      >
                        {item[1]?.estimated_cost_of_production}
                      </div>
                      <div
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          fontWeight: 500,
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                          fontSize: "11px",
                        }}
                      >
                        {item[1]?.estimated_cost_of_inputs_required}
                      </div>
                      <div
                        style={{
                          fontWeight: 500,
                          backgroundColor: "#B0E896",
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: "#585757",
                          fontSize: "11px",
                        }}
                      >
                        {item[1]?.expected_gross_margins}
                      </div>
                    </div>
                  )
                )}
                <div
                  style={{
                    display: "flex",
                    borderBottom: "1px solid #e6e6e6",
                  }}
                >
                  <div style={{ width: "80%" }}></div>
                  <div
                    style={{
                      borderRight: "1px solid #e6e6e6",
                      fontWeight: 700,
                      width: "90%",
                      display: "flex",
                      justifyContent: "end",
                      width: "10%",

                      alignItems: "center",

                      padding: "10px",
                      color: "#585757",
                      fontSize: "11px",
                    }}
                  >
                    Yearly Farm Income
                  </div>
                  <div
                    style={{
                      borderRight: "1px solid #e6e6e6",
                      fontWeight: 700,
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                      color: "#585757",
                      fontSize: "11px",
                    }}
                  >
                    {parseFloat(totalProfit).toFixed(2)} RS/Ha
                    {/* {totalProfit} Rs/Ha */}
                  </div>
                </div>
              </>
            ) : (
              // <div>loading</div>
              <div
                style={{
                  background: "rgb(249, 121, 121)",
                  border: " 1px solid rgb(230, 230, 230)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "9px",
                }}
              >
                <Typography>Loading</Typography>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px 15px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "#B0E896",
                    border: "1px solid #27BA5C",
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                ></div>
                Good crop health, soil health, yield or income
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "#F9FFC2",
                    border: "1px solid #E3AE10",
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                ></div>
                Average crop health, soil health, yield or income
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "#F97979",
                    border: "1px solid #ED1849",
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                ></div>
                Good crop health, soil health, yield or income
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px", pageBreakAfter: "always" }}>
          <p
            style={{
              color: "#585757",
              fontSize: "30px",
              fontWeight: 600,
              fontFamily: '"Poppins"',
            }}
          >
            Farmer Cash Flow
          </p>
          <div
            style={{
              border: "1px solid #e6e6e6",
              boxShadow: "0px 4px 12px 0px #B4B4B440",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#9fc088",
                  width: "14px",
                  height: "14px",
                  marginLeft: "5px",
                  marginRight: "15px",
                }}
              ></div>
              <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                Income
              </Typography>
              <div
                style={{
                  backgroundColor: "#5C87E9",
                  width: "14px",
                  height: "14px",
                  marginRight: "15px",
                  marginLeft: "30px",
                }}
              ></div>
              <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                Expense
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "950px" }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={cashflowChartOptions}
                />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <p style={{ fontWeight: "bold" }}>
                This graph reflects the average cost of cultivation and average
                income from the farm for the last two seasons.
              </p>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px" }}>
          <div className="agrlogo">
            <img style={{ width: "10%" }} src={`${aws_s3_url}/logo.svg`} />
            <img
              style={{ width: "10%" }}
              src={`${aws_s3_url}/AgribazaarLogo.svg`}
            />
          </div>
          <p
            style={{
              color: "#585757",
              fontSize: "30px",
              fontWeight: 600,
              fontFamily: '"Poppins"',
            }}
          >
            Risk Profile
          </p>
          {riskProfile && Object.keys(riskProfile).length ? (
            <div
              style={{
                border: "1px solid #e6e6e6",
                boxShadow: "0px 4px 12px 0px #b4b4b440",
              }}
            >
              <div
                style={{ display: "flex", borderBottom: "1px solid #e6e6e6" }}
              >
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 700,
                    width: "10%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  Farm No.
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 700,
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  Weather Risk
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 700,
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  Flood Risk
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 700,
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  Crop Heath Risk
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 700,
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  Current Crop Suitability
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 700,
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  Current Crop Expertise
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 700,
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  Farm Management
                </div>
              </div>
              <div
                style={{ display: "flex", borderBottom: "1px solid #e6e6e6" }}
              >
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 700,
                    width: "10%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  {/* 11/1 */}
                  {riskProfile.khasra}
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 700,
                    backgroundColor:
                      riskProfile?.risk_profile[0]?.weatherRisk == "Low"
                        ? "#B0E896"
                        : riskProfile?.risk_profile[0]?.weatherRisk == "High"
                        ? "#F97979"
                        : "#F9FFC2",
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  {riskProfile?.risk_profile[0]?.weatherRisk
                    ? riskProfile?.risk_profile[0].weatherRisk
                    : "--"}
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 500,
                    backgroundColor:
                      riskProfile?.risk_profile[0]?.FloodRisk == "Low"
                        ? "#B0E896"
                        : riskProfile?.risk_profile[0]?.FloodRisk == "High"
                        ? "#F97979"
                        : "#F9FFC2",
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  {riskProfile?.risk_profile[0]?.FloodRisk
                    ? riskProfile?.risk_profile[0].FloodRisk
                    : "--"}
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 500,
                    backgroundColor:
                      riskProfile?.risk_profile[0]?.cropHealthRisk == "Low"
                        ? "#B0E896"
                        : riskProfile?.risk_profile[0]?.cropHealthRisk == "High"
                        ? "#F97979"
                        : "#F9FFC2",
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  {riskProfile?.risk_profile[0]?.cropHealthRisk
                    ? riskProfile?.risk_profile[0].cropHealthRisk
                    : "--"}
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 500,
                    backgroundColor:
                      riskProfile?.risk_profile[0]?.cropHealthRisk == "Suitable"
                        ? "#B0E896"
                        : "#F97979",
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  {riskProfile?.risk_profile[0]?.cropSuitability
                    ? riskProfile?.risk_profile[0].cropSuitability
                    : "--"}
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 500,
                    backgroundColor:
                      riskProfile?.risk_profile[0]?.currentCropExperties == "Low"
                        ? "#F97979"
                        : riskProfile?.risk_profile[0]?.currentCropExperties ==
                          "High"
                        ? "#B0E896"
                        : "#F9FFC2",
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  {riskProfile?.risk_profile[0]?.currentCropExperties
                    ? riskProfile?.risk_profile[0].currentCropExperties
                    : "--"}
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e6e6e6",
                    fontWeight: 500,
                    backgroundColor:
                      riskProfile?.risk_profile[0]?.currentCropExperties ==
                      "Good"
                        ? "#B0E896"
                        : riskProfile?.risk_profile[0]?.currentCropExperties ==
                          "Average"
                        ? "#F97979"
                        : "#F9FFC2",
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "#585757",
                    fontSize: "11px",
                  }}
                >
                  {riskProfile?.risk_profile[0]?.farmManagement
                    ? riskProfile?.risk_profile[0].farmManagement
                    : "--"}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px 15px",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      backgroundColor: "#B0E896",
                      border: "1px solid #27BA5C",
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  ></div>
                  Low weather risk/Low flood risk/Low crop health risk/High crop
                  suitability/High current crop expertise/Good Farm Management
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      backgroundColor: "#F9FFC2",
                      border: "1px solid #E3AE10",
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  ></div>
                  Moderate weather risk/Moderate flood risk/Medium crop health
                  risk/Medium crop suitability/Moderate current crop
                  expertise/Average Farm Management
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      backgroundColor: "#F97979",
                      border: "1px solid #ED1849",
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  ></div>
                  High weather risk/High flood risk/High crop health risk/Low
                  crop suitability/Low current crop expertise/Poor Farm
                  Management
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                background: "rgb(249, 121, 121)",
                border: " 1px solid rgb(230, 230, 230)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "9px",
              }}
            >
              <Typography>NO DATA FOUND</Typography>
            </div>
          )}
        </div>

        <div style={{ marginTop: "50px", pageBreakAfter: "always" }}>
          <p
            style={{
              color: "#585757",
              fontSize: "30px",
              fontWeight: 600,
              fontFamily: '"Poppins"',
            }}
          >
            Weather Trend
          </p>
          <div
            style={{
              border: "1px solid #e6e6e6",
              boxShadow: "0px 4px 12px 0px #B4B4B440",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "950px" }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={weatherTrendChartOptions}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px", pageBreakAfter: "always" }}>
          <div className="agrlogo">
            <img style={{ width: "10%" }} src={`${aws_s3_url}/logo.svg`} />
            <img
              style={{ width: "10%" }}
              src={`${aws_s3_url}/AgribazaarLogo.svg`}
            />
          </div>
          <p
            style={{
              color: "#585757",
              fontSize: "30px",
              fontWeight: 600,
              fontFamily: '"Poppins"',
            }}
          >
            Credit Score Translation Factors
          </p>
          <div style={{ border: "1px solid #e6e6e6" }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "25%",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                Points
              </div>
              <div
                style={{
                  width: "20%",
                  borderLeft: "1px solid #e6e6e6",
                  borderRight: "1px solid #e6e6e6",
                  fontSize: "16px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                Section
              </div>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #e6e6e6",
                  width: "55%",
                }}
              >
                Breakdown
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#F5FAF5",
                  width: "25%",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      "https://agribhumi.s3.ap-south-1.amazonaws.com/basic-details-icon.svg"
                    }
                    style={{ marginRight: "4px" }}
                  />
                  Basic Details
                </div>
              </div>
              <div
                style={{
                  width: "20%",
                  borderLeft: "1px solid #e6e6e6",
                  borderRight: "1px solid #e6e6e6",
                  fontSize: "16px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                {basicDetailScore}
              </div>
              <div
                style={{
                  width: "55%",
                  padding: "10px",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Farmer Name = {scoreBreakup?.basicDetails?.name}/5
                </span>
                <br />
                <span style={{ fontWeight: "bold" }}>
                  Address (Village, Tehsil, District, State) ={" "}
                  {scoreBreakup?.basicDetails?.address}/5
                </span>
                <br />
                <span style={{ fontWeight: "bold" }}>Khasra No. = {scoreBreakup?.basicDetails?.khasra}/5</span>
                <br />
                <span style={{ fontWeight: "bold" }}>
                  Number of farmland = {scoreBreakup?.basicDetails?.numberOfFarmlands}/5
                </span>
                <br />
                <span style={{ fontWeight: "bold" }}>
                  Total farmland area = {scoreBreakup?.basicDetails?.totalFarmlandArea}/5
                </span>
                <br />
                <span style={{ fontWeight: "bold" }}>Validation = 5/5</span>
                <div>
                  <hr />
                </div>
                <span style={{ fontWeight: "bold" }}>Total = {basicDetailScore}/30</span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#F5FAF5",
                  width: "25%",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      "https://agribhumi.s3.ap-south-1.amazonaws.com/farmland-icon.svg"
                    }
                    style={{ marginRight: "4px" }}
                  />
                  Farmland Details/ Crop History
                </div>
              </div>
              <div
                style={{
                  width: "20%",
                  borderLeft: "1px solid #e6e6e6",
                  borderRight: "1px solid #e6e6e6",
                  fontSize: "16px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                {yieldScore + farmlandDetailScore}
              </div>
              <div
                style={{
                  width: "55%",
                  padding: "10px",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Crop Growing Factor ={" "}
                  {scoreBreakup.cropHistory.cropGrowingFactor} /15
                  {" "}
                </span>
                (Based on No. of Season crop is grown in last 2 years)
                <div>
                  <hr />
                </div>
                <span style={{ fontWeight: "bold" }}>
                  Yield Factor = {scoreBreakup.cropHistory.yieldFactor} /15
                </span>{" "}
                ( Based on crop yield in past 3 crop seasons)
                <div>
                  <hr />
                </div>
                <span style={{ fontWeight: "bold" }}>Total = {parseInt(scoreBreakup.cropHistory.yieldFactor) + parseInt(scoreBreakup.cropHistory.cropGrowingFactor)}/30  </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#F5FAF5",
                  width: "25%",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      "https://agribhumi.s3.ap-south-1.amazonaws.com/p-and-l-icon.svg"
                    }
                    style={{ marginRight: "4px" }}
                  />
                  Profit & Loss Estimates
                </div>
              </div>
              <div
                style={{
                  width: "20%",
                  borderLeft: "1px solid #e6e6e6",
                  borderRight: "1px solid #e6e6e6",
                  fontSize: "16px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                {profitLossDetailScore}
              </div>
              <div
                style={{
                  width: "55%",
                  padding: "10px",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Crop health = {scoreBreakup.profitLoss.cropHealth}/9
                </span>{" "}
                ( Based on crop health estimated for past 2 crop seasons )
                <div>
                  <hr />
                </div>
                <span style={{ fontWeight: "bold" }}>
                  Soil Health = {scoreBreakup.profitLoss.soilHealth}/6
                </span>{" "}
                ( Based on soil health estimated for past 2 crop seasons )
                <div>
                  <hr />
                </div>
                <span style={{ fontWeight: "bold" }}>
                  Farm Income (max 9) = {scoreBreakup.profitLoss.farmIncome}/9
                </span>
                (Scored 9 if Profit is more than 30%, 6 if Profit is between
                10-30%, 3 if Profit is 0-10%)
                <div>
                  <hr />
                </div>
                <span style={{ fontWeight: "bold" }}>
                  Total = {profitLossDetailScore}/24
                </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#F5FAF5",
                  width: "25%",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      "https://agribhumi.s3.ap-south-1.amazonaws.com/risk-profile-icon.svg"
                    }
                    style={{ marginRight: "4px" }}
                  />
                  Risk Profile
                </div>
              </div>
              <div
                style={{
                  width: "20%",
                  borderLeft: "1px solid #e6e6e6",
                  borderRight: "1px solid #e6e6e6",
                  fontSize: "16px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                {riskDetailScore}
              </div>
              <div
                style={{
                  width: "55%",
                  padding: "10px",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Weather & Flood Risk ={" "}
                  {scoreBreakup.riskProfile.weatherFloodRisk}
                  /4
                </span>{" "}
                ( Best if weather is suitable and there is no chance of flood, worst if both are not suitable )
                <br />
                <span style={{ fontWeight: "bold" }}>
                  Crop Suitability = {scoreBreakup.riskProfile.cropSuitability}
                  /4
                </span>
                ( Based on soil properties, weather data and crop grown in past 2 seasons )
                <br />{" "}
                <span style={{ fontWeight: "bold" }}>
                  Crop Expertise = {scoreBreakup.riskProfile.cropExpertise}
                  /4{" "}
                </span>
                ( Based upon crop grown in past 2 years)
                <br />
                <span style={{ fontWeight: "bold" }}>
                  Farm management = {scoreBreakup.riskProfile.farmManagement}/4
                </span>{" "}
               ( Based upon crop performance in past 3 crop seasons )
                <div>
                  <hr />
                </div>
                <span style={{ fontWeight: "bold" }}>
                  Total = {riskDetailScore}/16
                </span>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px" }}>
          <div className="agrlogo">
            <img style={{ width: "10%" }} src={`${aws_s3_url}/logo.svg`} />
            <img
              style={{ width: "10%" }}
              src={`${aws_s3_url}/AgribazaarLogo.svg`}
            />
          </div>
          <p
            style={{
              color: "#585757",
              fontSize: "30px",
              fontWeight: 600,
              fontFamily: '"Poppins"',
            }}
          >
            Soil Health Index Table
          </p>
          <div
            style={{
              border: "1px solid #e6e6e6",
              borderRadius: "4px",
              boxShadow: "0px 4px 12px 0px #b4b4b440",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  fontWeight: 600,
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                Range
              </div>
              <div
                style={{
                  fontWeight: 600,
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                Nitrogen
              </div>
              <div
                style={{
                  fontWeight: 600,
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                Phosphorus
              </div>
              <div
                style={{
                  fontWeight: 600,
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                Potassium
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#B0E896",
                borderBottom: "1px solid #e6e6e6",
              }}
            >
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                Good
              </div>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                226+ kg/acre
              </div>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                23+ kg/acre
              </div>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                136+ kg/acre
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#F9FFC2",
                borderBottom: "1px solid #e6e6e6",
              }}
            >
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                Average
              </div>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                113-226 kg/acre
              </div>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                9-23 kg/acre
              </div>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                58-136 kg/acre
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#F97979",
                borderBottom: "1px solid #e6e6e6",
              }}
            >
              <div
                style={{
                  color: "#ffffff",
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                Poor
              </div>
              <div
                style={{
                  color: "#ffffff",
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                0-113 kg/acre
              </div>
              <div
                style={{
                  color: "#ffffff",
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                0-9 kg/acre
              </div>
              <div
                style={{
                  color: "#ffffff",
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  width: "25%",
                  fontSize: "18px",
                }}
              >
                0-58 kg/acre
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px" }}>
          <p
            style={{
              color: "#585757",
              fontSize: "30px",
              fontWeight: 600,
              fontFamily: '"Poppins"',
            }}
          >
            Crop Health Index Table
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                border: "1px solid #e6e6e6",
                borderRadius: "4px",
                boxShadow: "0px 4px 12px 0px #b4b4b440",
                width: "25%",
              }}
            >
              <div
                style={{
                  backgroundColor: "#B0E896",
                  borderBottom: "1px solid #e6e6e6",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                High
              </div>
              <div style={{ padding: "12px", fontSize: "15px" }}>
                Suitable Weather condition and good/healthy value of satellite
                indices
              </div>
            </div>
            <div
              style={{
                border: "1px solid #e6e6e6",
                borderRadius: "4px",
                boxShadow: "0px 4px 12px 0px #b4b4b440",
                width: "25%",
              }}
            >
              <div
                style={{
                  backgroundColor: "#F9FFC2",
                  borderBottom: "1px solid #e6e6e6",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                Medium
              </div>
              <div style={{ padding: "12px", fontSize: "15px" }}>
                Optimum Weather conditions and moderate value of satellite
                indices
              </div>
            </div>
            <div
              style={{
                border: "1px solid #e6e6e6",
                borderRadius: "4px",
                boxShadow: "0px 4px 12px 0px #b4b4b440",
                width: "25%",
              }}
            >
              <div
                style={{
                  backgroundColor: "#F97979",
                  borderBottom: "1px solid #e6e6e6",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "#ffffff",
                }}
              >
                Low
              </div>
              <div style={{ padding: "12px", fontSize: "15px" }}>
                Either weather conditions not suitable or negative indication on
                satellite indices
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px" }}>
          <div className="agrlogo">
            <img style={{ width: "10%" }} src={`${aws_s3_url}/logo.svg`} />
            <img
              style={{ width: "10%" }}
              src={`${aws_s3_url}/AgribazaarLogo.svg`}
            />
          </div>
          <p
            style={{
              color: "#585757",
              fontSize: "30px",
              fontWeight: 600,
              fontFamily: '"Poppins"',
            }}
          >
            Risk Profile Index Table
          </p>
          <div style={{ border: "1px solid #e6e6e6", borderRadius: "4px" }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 600,
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Index
              </div>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 600,
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Criteria
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 600,
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Weather Risk
              </div>
              <div
                style={{
                  padding: "12px",
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Calculated with comparison to ideal weather conditions for crop
                of interest
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 600,
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Flood Risk
              </div>
              <div
                style={{
                  padding: "12px",
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                ML based model to predict possibility of flood based on current
                and historic weather and satellite data
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 600,
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Crop Health Risk
              </div>
              <div
                style={{
                  padding: "12px",
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Inverse function of crop health measurement
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 600,
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Current Crop Suitability
              </div>
              <div
                style={{
                  padding: "12px",
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Calculated based on soil texture and nutrient availability
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 600,
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Current Crop Expertise
              </div>
              <div
                style={{
                  padding: "12px",
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Evaluated based on historic farm activities and district level
                estimations as available with Aqribazaar cloud data engine
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  padding: "12px",
                  borderRight: "1px solid #e6e6e6",
                  fontWeight: 600,
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Farm Management
              </div>
              <div
                style={{
                  padding: "12px",
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e6e6",
                  fontSize: "18px",
                }}
              >
                Estimated with satellite data and package of practices served as
                input on Agribazaar app
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FarmerScoreCardv3;
